<!-- <div class="game-popup" *ngIf="dialog === true && !gameText">
  <div class="dialog">
    <div class="head">
      <h2>Checklist before starting</h2>
      <div (click)="closeDialog(true)">
        <fa-icon [icon]="faTimes" size="xl"></fa-icon>
      </div>
    </div>


    <div class="buttons">
      <p (click)="step('next')" class="link">Skip games</p>
      <app-button (click)="closeDialog(false)" [class]="['primary']" [label]="'OK, I’m ready'"></app-button>
    </div>
  </div>
</div> -->

<div class="wrap-selection">
  <div *ngIf="!gameDone && gameUrl !== ''" class="selection">
    <app-header [text]="gameText" [rule]="rule" [languages]="languages.notDone"></app-header>
    <div class="checklist">
      <p class="label2">Before getting started make sure you have checked off the following:</p>
      <div><fa-icon [icon]="faCheck"></fa-icon>
        <p>The games can’t be played in incognito-mode.</p>
      </div>
      <div><fa-icon [icon]="faCheck"></fa-icon>
        <p>You can’t pause the games so you’d need minimum 30 min to complete the games. </p>
      </div>
      <div><fa-icon [icon]="faCheck"></fa-icon>
        <p>You are in a comfortable environment to complete the games undisturbed.</p>
      </div>
    </div>
    <div class="games">
      <p class="label2">The games</p>
      <div class="start-game">
        <div *ngFor="let game of games">
          <img [src]="game.img" />
          <div>
            <div class="time">
              <p class="caption">{{game.time}}</p>
            </div>
            <div>
              <h4 class="label1">{{game.title}}</h4>
              <p class="body2">{{game.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq">
      <p class="label2">Frequently asked questions about the games</p>
      <div>
        <div (click)="openFaq(1)">
          <div>
            <fa-icon [icon]="getIcon(1)"></fa-icon>
            <p class="body2">What is the purpose of these games?</p>
          </div>
          <div class="answer" *ngIf="faqOpen === 1">
            <p class="body1">The games will output both your strengths and your challenges. The primary purpose of the
              game-based
              assessment is to match you best possible to potential jobs.
            </p>
          </div>
        </div>
        <div (click)="openFaq(2)">
          <div>
            <fa-icon [icon]="getIcon(2)"></fa-icon>
            <p class="body2">Where will I be able to see my results?</p>
          </div>
          <div class="answer" *ngIf="faqOpen === 2">
            <p class="body1">The results will be available to your 7N agent for them to use in your recruitment
              interview as well as for
              matching purposes. When you become a 7N consultant, the results will be available to you under the profile
              menu.
            </p>
          </div>
        </div>
        <div (click)="openFaq(3)">
          <div>
            <fa-icon [icon]="getIcon(3)"></fa-icon>
            <p class="body2">Are these games scientific?</p>
          </div>
          <div class="answer" *ngIf="faqOpen === 3">
            <p class="body1">
              {{languages.refference.base}} <a class="link" target="_blank" href="https://equalture.com">
                {{languages.refference.cta}}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="gameDone" class="selection done">
    <div>
      <div class="agent">
        <img src="./assets/images/agent.png" />
      </div>
      <h1 class="title3">{{languages.done.headline}}</h1>
      <p class="body1">{{languages.done.description}}
      </p>
    </div>
  </div>
  <div class="wrap-button">
    <app-cta (callbackFunction)="step($event)" [buttons]="gameDone ? buttons: play" [isSafari]="isSafari"
      text="*The games will open in new tab">
    </app-cta>
    <app-button *ngIf="rule === 'optional' && !gameDone" (click)="step({title:'Skip', type:'next'})" [class]="['inverted']"
      label="Skip games for now"></app-button>
  </div>
</div>
<div *ngIf="selectedGame" class="playing-game">
  <!-- <p class="later link" (click)="home()">{{contextService.later}}</p> -->
  <div *ngIf="gameUrl !== ''" class="game">
    <div *ngIf="doneMessage" class="done-message">
      <h1>You are done</h1>
      <p>Thank you for playing the games</p>
    </div>
    <iframe *ngIf="!doneMessage" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" scrolling="no"
      [src]="gameUrl"> </iframe>
  </div>

  <div class="wrap-button">
    <app-cta [class]="{'disabled': ! doneMessage}" (callbackFunction)="finish($event)" [buttons]="nextBtn">
    </app-cta>
  </div>
</div>
