import { Component, EventEmitter, Input, Output } from '@angular/core';
import { card } from 'src/ts/interfaces/my-profile.interface';

@Component({
  selector: 'app-activity-cards',
  templateUrl: './activity-cards.component.html',
  styleUrls: ['./activity-cards.component.scss'],
})
export class ActivityCardsComponent {
  @Input() cards: card[];
  @Output() callbackFunction = new EventEmitter<any>();
  getDate() {
    const today = new Date();
    const formatOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: '2-digit',
    };
    return (
      today.toLocaleDateString('en-US', formatOptions) +
      this.nth(today.getDate())
    );
  }
  nth(d: number) {
    const dString = String(d);
    const last = +dString.slice(-2);
    if (last > 3 && last < 21) return 'th';
    switch (last % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  click(value: string) {
    this.callbackFunction.emit(value);
  }
  filterDisabledCard(cards: card[]) {
    return cards.filter((x) => !x.disable);
  }
}
