<div class="design-system">
  <div>
    <h1 class="heading">Foundations</h1>
    <div>
      <div>
        <div class="colors">
          <p class="title2">
            Default
          </p>
          <div>
            <div class="default background" title="Default background"></div>
            <div class="default background-hover" title="Default background hover"></div>
            <div class="default on-background" title="Default on background"></div>
            <div class="default stroke" title="Default stroke"></div>
          </div>
        </div>
        <div class="colors">
          <p class="title2">
            Primary
          </p>
          <div>
            <div class="primary background" title="primary background"></div>
            <div class="primary background-hover" title="primary background hover"></div>
            <div class="primary on-background" title="primary on background"></div>
            <div class="primary text" title="primary text"></div>
            <div class="primary stroke" title="primary stroke"></div>
            <div class="primary stroke-active" title="primary stroke active"></div>
          </div>
        </div>
        <div class="colors">
          <p class="title2">
            Secondary
          </p>
          <div>
            <div class="secondary background" title="secondary background"></div>
            <div class="secondary background-hover" title="secondary background hover"></div>
            <div class="secondary on-background" title="secondary on background"></div>
          </div>
        </div>
        <div class="colors">
          <p class="title2">
            Success
          </p>
          <div>
            <div class="success background" title="success background"></div>
            <div class="success on-background" title="success on background"></div>
            <div class="success text" title="success text"></div>
            <div class="success shape" title="success shape"></div>
          </div>
        </div>
        <div class="colors">
          <p class="title2">
            Error
          </p>
          <div>
            <div class="error background" title="error background"></div>
            <div class="error on-background" title="error on background"></div>
            <div class="error text" title="error text"></div>
            <div class="error shape" title="error shape"></div>
          </div>
        </div>
        <div class="colors">
          <p class="title2">
            Elevation
          </p>
          <div>
            <div class="elevation default" title="elevation default"></div>
            <div class="elevation primary" title="elevation primary"></div>
            <div class="elevation on-background" title="elevation on background"></div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h1 class="heading">Heading</h1>
        </div>
        <div>
          <p class="title1">Title1</p>
        </div>
        <div>
          <p class="title2">Title2</p>
        </div>
        <div>
          <p class="title3">Title3</p>
        </div>
        <div>
          <p class="subtitle1">Subtitle1</p>
        </div>
        <div>
          <p class="subtitle2">Subtitle2</p>
        </div>
        <div>
          <p class="subtitle3">Subtitle3</p>
        </div>
        <div>
          <p class="label1">label1</p>
        </div>
        <div>
          <p class="label2">label2</p>
        </div>
        <div>
          <p class="body1">Body1</p>
        </div>
        <div>
          <p class="body2">Body2</p>
        </div>
        <div>
          <p class="caption">Caption</p>
        </div>

      </div>
    </div>
  </div>

  <div>
    <h1 class="heading">Core components</h1>
    <div>
      <div>
        <div>
          <app-button [class]="['primary']" [label]="'Label'" [leadingIcon]="'plus'"></app-button>
          <app-button [class]="['primary','active']" [label]="'Label'" [leadingIcon]="'plus'"></app-button>
          <app-button [class]="['inverted']" [label]="'Label'" [leadingIcon]="'plus'"></app-button>
          <app-button [class]="['inverted','active']" [label]="'Label'" [leadingIcon]="'plus'"></app-button>
          <app-button [class]="['secondary']" [label]="'Label'" [leadingIcon]="'plus'"></app-button>
          <app-button [class]="['secondary','active']" [label]="'Label'" [leadingIcon]="'plus'"></app-button>
          <app-button [class]="['primary']" [label]="'Label'" [trailingIcon]="'plus'"></app-button>
          <app-button [class]="['primary','active']" [label]="'Label'" [trailingIcon]="'plus'"></app-button>
          <app-button [class]="['inverted']" [label]="'Label'" [trailingIcon]="'plus'"></app-button>
          <app-button [class]="['inverted','active']" [label]="'Label'" [trailingIcon]="'plus'"></app-button>
          <app-button [class]="['secondary']" [label]="'Label'" [trailingIcon]="'plus'"></app-button>
          <app-button [class]="['secondary','active']" [label]="'Label'" [trailingIcon]="'plus'"></app-button>
        </div>
      </div>
      <div>
        <div>

          <app-month-year-select label="Select Date" *ngIf="list.type === 'available'" [value]="list.selected[0]"
            [type]="'start'" (callbackFunction)="selectedValue($event)"
            [placeholder]="'Available on'"></app-month-year-select>

          <app-dropdown [searchable]="true" trailingIcon="chevron-down" label="Dropdown"
            [selected]="{ displayName: 'name1' }"
            [options]="[{displayName: 'name1'},{displayName: 'name2'},{displayName: 'name3'}]"></app-dropdown>

          <app-input [label]="'Default'" [placeholder]="'Placeholder'"></app-input>

          <app-input [label]="'Populated'" [placeholder]="'Placeholder'" [value]="'Populated'"></app-input>

          <app-input [label]="'Disabled'" [placeholder]="'Placeholder'" [value]="'Placeholder'"
            [disabled]="true"></app-input>

          <app-input [options]="countrycode" [option]="countrycode[0]" [label]="'Combined'"
            [placeholder]="'Placeholder'" [value]="'Placeholder'" combined="leading"></app-input>

          <app-input [options]="countrycode" [option]="countrycode[0]" [label]="'Combined'"
            [placeholder]="'Placeholder'" [value]="'Placeholder'" combined="trailing"></app-input>

          <app-input [inputBox]="[1,2,3,4,-1]" [type]="'box'"></app-input>
        </div>
      </div>
      <div>
        <div>
          <i-tabler name="chevron-right"></i-tabler>
          <i-tabler name="chevron-down"></i-tabler>
          <i-tabler name="chevron-left"></i-tabler>
          <i-tabler name="chevron-up"></i-tabler>
          <i-tabler name="layout-sidebar-left-collapse"></i-tabler>
          <i-tabler name="layout-sidebar-left-expand"></i-tabler>
          <i-tabler name="inbox"></i-tabler>
          <i-tabler name="exchange"></i-tabler>
          <i-tabler name="plus"></i-tabler>
          <i-tabler name="X"></i-tabler>
          <i-tabler name="home"></i-tabler>
          <i-tabler name="briefcase"></i-tabler>
          <i-tabler name="filter"></i-tabler>
          <i-tabler name="user-circle"></i-tabler>
          <i-tabler name="check"></i-tabler>
          <i-tabler name="mood-smile"></i-tabler>
          <i-tabler name="external-link"></i-tabler>
          <i-tabler name="address-book"></i-tabler>
          <i-tabler name="topology-star-ring-3"></i-tabler>
          <i-tabler name="logout"></i-tabler>
          <i-tabler name="search"></i-tabler>
          <i-tabler name="info-circle-filled"></i-tabler>
          <i-tabler name="trash"></i-tabler>
          <i-tabler name="map-pin"></i-tabler>
          <i-tabler name="calendar"></i-tabler>
          <i-tabler name="brand-linkedin"></i-tabler>
          <i-tabler name="world"></i-tabler>
          <i-tabler name="brand-dribbble"></i-tabler>
          <i-tabler name="brand-github"></i-tabler>
          <i-tabler name="dots"></i-tabler>
          <i-tabler name="arrow-right"></i-tabler>
          <i-tabler name="clock-hour-3"></i-tabler>
          <i-tabler name="balloon"></i-tabler>
          <i-tabler name="credit-card"></i-tabler>
          <i-tabler name="copy"></i-tabler>
          <i-tabler name="send"></i-tabler>
          <i-tabler name="at"></i-tabler>
          <i-tabler name="phone"></i-tabler>
        </div>
      </div>

      <div>
        <div>
          <app-progress-bars
            [steps]="[{label:'step1', state: true},{label:'step2', state: true},{label:'step3', state: false}]"></app-progress-bars>
        </div>
        <app-steps [steps]="{label:'step1', state: true}"></app-steps>
        <app-steps [steps]="{label:'step1', state: false}"></app-steps>
      </div>

      <div>
        <app-navbar [number]="'1.'" [class]="['default']" [label]="'Label'" [leadingIcon]="'plus'"
          [trailingIcon]="'check'" [amount]="'18'"></app-navbar>
        <app-navbar [number]="'1.'" [class]="['active']" [label]="'Label'" [leadingIcon]="'plus'"
          [trailingIcon]="'check'" [amount]="'18'"></app-navbar>
        <app-navbar [number]="'1.'" [class]="['completed']" [label]="'Label'" [leadingIcon]="'plus'"
          [trailingIcon]="'check'" [amount]="'18'"></app-navbar>
        <app-navbar [class]="['primary','action']" [label]="'Label'" [trailingIcon]="'check'"></app-navbar>
      </div>
      <div>
        <app-slider minLabel="Not important" maxLabel="Very important" [value]="1"></app-slider>
        <app-slider minLabel="Not important" maxLabel="Very important" [value]="2"></app-slider>
        <app-slider minLabel="Not important" maxLabel="Very important" [value]="3"></app-slider>
      </div>

      <div>
        <div>
          <app-widgets type="agent"
            [agent]="{name: 'Bianca Larsen', email: 'bianca@7n.com', image:'assets/images/agent-demo.png'}"></app-widgets>
          <app-widgets type="profile-strength" [profile]="{percent:85}"></app-widgets>
        </div>
      </div>
      <div>
        <div>
          <app-select-items type="toggle"></app-select-items>
          <app-select-items type="toggle" [isChecked]="true"></app-select-items>
        </div>
        <div>
          <app-select-items type="radio" [isChecked]="false"></app-select-items>
          <app-select-items type="radio" [isChecked]="true"></app-select-items>
        </div>
        <div>
          <app-select-items type="checkbox" [isChecked]="false"></app-select-items>
          <app-select-items type="checkbox" [isChecked]="true"></app-select-items>
        </div>
      </div>
      <div>
        <div>
          <app-buttons *ngFor="let level of levelWork; let i = index" [single]="true"
            [selected]="selected.includes(level.id)" [callbackFunction]="callback" type="levelWork" [loop]="i"
            [data]="level">
          </app-buttons>
          <app-buttons *ngFor="let level of levelWork1; let i = index" [single]="false"
            [selected]="selected.includes(level.id)" [callbackFunction]="callback" type="levelWork" [loop]="i"
            [data]="level">
          </app-buttons>
        </div>
        <div>
          <app-buttons *ngFor="let level of industries; let i = index" [single]="true"
            [selected]="selected.includes(level.id)" [callbackFunction]="callback" type="industries" [loop]="i"
            [data]="level">
          </app-buttons>
          <app-buttons *ngFor="let level of industries1; let i = index" [single]="false"
            [selected]="selected.includes(level.id)" [callbackFunction]="callback" type="industries" [loop]="i"
            [data]="level">
          </app-buttons>
          <app-buttons *ngFor="let level of industries2; let i = index" [single]="true"
            [selected]="selected.includes(level.id)" [noIcon]="true" [callbackFunction]="callback" type="industries"
            [loop]="i" [data]="level">
          </app-buttons>
        </div>
      </div>
    </div>
    <div>

    </div>
  </div>
</div>
