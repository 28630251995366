<div class="home">

  <div class="top">
    <div>
      <h1 class="title3"><span>{{welcome}} </span> {{this.givinName}}</h1>
      <p *ngIf="message.status === 'Approved'" class="body1">You are a 7N consultant</p>
    </div>
    <app-widgets type="agent"
      [agent]="{name: languages.agent.name, email: languages.agent.email, image:languages.agent.imgUrl}"></app-widgets>
    <!-- <div *ngIf="contextService.myProfileData.genome.status !== 'Approved'">
      <app-logo [back]="false"></app-logo>
      <div>
        <p (click)="signout()" class="link">Sign Out - {{givinName}}</p>
      </div>
    </div> -->
  </div>
  <div class="middle">
    <div class="loading" *ngIf="loading">
      <!-- <fa-icon [spin]="true" [icon]="faSpinner"></fa-icon> -->
      <app-loading></app-loading>
    </div>



    <section *ngIf="!loading" [class]="{'approved':message.status === 'Approved'} ">
      <div class="module" *ngIf="message.status === 'Approved' && !module && !sevenNConsultant">
        <div (click)="closeModule()" class="close"><i-tabler name="X"></i-tabler></div>
        <div class="message">
          <h2 class="subtitle2">Welcome to the new freelance community in 7N</h2>
          <div>
            <p class="body2">You will be able to exchange ideas and challenges with other consultants in the community
              and, over time, take advantage of more benefits. Also, you will be able to update your profile, enabling
              us
              to match you even better with the most exciting projects. I am looking forward to seeing you in the
              community. If you have any questions or feedback - feel free to reach out.</p>
            <p class="body2">Best regars, <br />
              The 7N team
            </p>
          </div>
        </div>
      </div>
      <div class="module" *ngIf="message.status === 'Approved' && !module && sevenNConsultant">
        <div (click)="closeModule()" class="close"><i-tabler name="X"></i-tabler></div>
        <div class="message">
          <h2 class="subtitle2">Welcome to the new freelance community in 7N</h2>
          <div>
            <p class="body2">You will be able to exchange ideas and challenges with other consultants in the community
              and, over time, take advantage of more benefits. Also, you will be able to update your profile, enabling
              us
              to match you even better with the most exciting projects. I am looking forward to seeing you in the
              community. If you have any questions or feedback - feel free to reach out.</p>
            <p class="body2">Best regars, <br />
              Kim Rohde <br />
              VP, Consultant Relations
            </p>
            <img src="./assets/images/signature-kim.png" />
          </div>
        </div>
      </div>
      <div *ngIf="message.status !== 'Approved'" class="module">
        <app-header
          [languages]="{headline: 'Thank you for signing up!', description: 'This is the first step to becoming a 7N consultant. We are just as excited as you are. There are a few steps left to complete your registration. '}"></app-header>
        <div class="progress-steps">
          <ng-container *ngFor="let stat of status; let i = index">
            <div class="card" [class]="{'passed':stat.passed}">
              <div *ngIf="lastOfPassed(i)" class="youHere">You are here</div>
              <div class="top"><span class="step caption">STEP {{i+1}}</span><span
                  class="estimates caption">{{stat.estimate}}</span>
              </div>
              <div class="bottom">
                <div>
                  <h2 class="subtitle2">{{stat.title}}</h2>
                  <p class="body2">{{stat.description}}</p>
                </div>
                <div *ngIf="stat.state && i === 0">
                  <app-button (click)="goToRoute(false)" [class]="['primary']"
                    [label]="profileSubmitted ? 'View profile': 'Complete profile'"></app-button>
                </div>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>
    <section *ngIf="message.status === 'Approved'">
      <app-activity-cards (callbackFunction)="toogleDialog($event)" [cards]="cards"></app-activity-cards>
    </section>

    <section *ngIf="message.status !== 'Approved'">
      <div class="benifits" *ngFor="let ben of benifits">
        <div>
          <h1 class="subtitle2">{{ben.title}}</h1>
        </div>
        <div>
          <div *ngFor="let article of ben.articles">
            <!-- <div class="image">
              <img *ngIf="article.imgUrl" [src]="article.imgUrl" />
            </div> -->
            <div class="text">
              <p class="label2">{{article.headline}}</p>
              <p class="body2">{{article.description}}</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</div>
<app-dialog [languages]="languages" *ngIf="dialog === true" [type]="dialogType" [cardInfo]="cardInfo" [toEdit]="toEdit"
  (close)="toogleDialog()">
</app-dialog>
