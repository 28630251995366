import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  loginDisplay = false;
  title = this.contextService.title();
  languages: any;
  loading = true;
  constructor(
    private authenticationService: AuthenticationService,
    private platformService: PlatformService,
    private contextService: ContextService
  ) {
    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'successNoToken') {
        this.loading = false;
        this.languages = this.contextService.languages?.createConsultant;
      }
    });
  }
  signin() {
    this.authenticationService.signin();
  }
  signout() {
    this.authenticationService.signout();
  }
  ngOnInit(): void {
    if (localStorage.getItem('selectedProProfile')) {
      localStorage.removeItem('selectedProProfile');
    }
    if (localStorage.getItem('selectedCity')) {
      localStorage.removeItem('selectedCity');
    }
  }
}
