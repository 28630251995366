import { Component, OnInit } from '@angular/core';
import {
  jobHistory,
  ProProfile,
  ruleLength,
  SelectedIndustries,
  SelectedPro,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import {
  faGripVertical,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import { ContextService } from 'src/services/platform/context.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  industries = this.contextService.profileData.industries;
  selectedProProfile = this.contextService.selectedProProfile;
  selectedIndustries = this.contextService.selectedIndustries;
  faGripVertical = faGripVertical as IconProp;
  faChevronDown = faChevronDown as IconProp;

  ruleLength: ruleLength = {
    industries: 1,
  };
  languages = this.contextService.languages.experience;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {}
  swap(Array: any, Swap1: number, Swap2: number): any {
    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2];
    Array[Swap2] = temp;
    return Array;
  }
  allowDrop(e: DragEvent) {
    e.preventDefault();
  }
  rankDrop(e: DragEvent, dropIndex: number) {
    let dragIndex = e.dataTransfer?.getData('dragIndex');
    if (dragIndex) {
      this.swap(
        this.selectedIndustries.industries,
        JSON.parse(dragIndex),
        dropIndex
      );
      this.selectedIndustries.industries.map((element, i) => {
        element.selected!.rank = i;
      });
    }
  }
  rankDragStart(e: DragEvent, value: number) {
    e.dataTransfer?.setData('dragIndex', JSON.stringify(value));
  }

  validateChoices(type: string) {
    this.contextService.validSelection[type] = false;
    // if (type === 'industries') {
    //   if (
    //     this.contextService.selectedIndustries[type as keyof SelectedIndustries]
    //       .length >= this.ruleLength[type] &&
    //     !this.contextService.selectedIndustries[
    //       type as keyof SelectedIndustries
    //     ].some((x) => x.selected!.experience === '')
    //   ) {
    //     this.contextService.validSelection[type] = true;
    //   }
    //   return;
    // }

    if (type === 'jobHistory') {
      const valid = this.contextService.jobHistory.every((x: jobHistory) => {
        return (
          x.company.length >= 1 && x.industryId && x.start && x.role.length >= 1
        );
      });
      this.contextService.validSelection[type] = valid;
      this.platformService.reRender.next('experience');
      return;
    }
    // if (
    //   this.contextService.selectedProProfile[type as keyof SelectedPro]
    //     .length >= this.ruleLength[type]
    // ) {
    //   this.contextService.validSelection[type] = true;
    // }
  }

  getIndustry(indu: ProProfile) {
    return this.selectedIndustries.industries.some((x) => x.id === indu.id);
  }
  // selectedPro = (id: number, type: keyof type, single?: boolean): void => {
  //   if (type === 'industries') {
  //     let findex = this.selectedIndustries.industries.findIndex(
  //       (x) => x.id === id
  //     );
  //     let indu = this.industries.find((x) => x.id === id);
  //     if (findex === -1) {
  //       indu!.selected = {
  //         rank: this.selectedIndustries.industries.length,
  //         experience: '',
  //       };
  //       this.selectedIndustries.industries.push(indu!);
  //     } else {
  //       this.selectedIndustries.industries.splice(findex, 1);
  //     }
  //     this.validateChoices(type);
  //     this.platformService.reRender.next('experience');
  //     return;
  //   }
  //   if (type === 'jobHistory') {
  //     this.validateChoices(type);
  //     this.platformService.reRender.next('experience');
  //     return;
  //   }
  //   let findex = this.selectedProProfile[type].findIndex((x) => x === id);

  //   if (single) {
  //     this.selectedProProfile[type] = [];
  //   }
  //   if (findex === -1) {
  //     this.selectedProProfile[type].push(id);
  //   } else {
  //     this.selectedProProfile[type].splice(findex, 1);
  //   }

  //   this.validateChoices(type);
  //   this.platformService.reRender.next('experience');
  // };
}
