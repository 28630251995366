import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  availability,
  devidedGame,
  jobHistory,
  myProfileData,
  navigationMenu,
  profileData,
  ProProfile,
  salary,
  section,
  selectCities,
  SelectedIndustries,
  SelectedPro,
  selectProfProfile,
  selectStartDate,
  signupRule,
  stepsMenuSlug,
  validSelection,
} from 'src/ts/interfaces/profile.interface';
import { AuthenticationService } from '../authentication/authentication.service';
import { HelperFunctionsService } from '../helperFunctions/helper-functions.service';
import { PlatformService } from './platform.service';
import { status } from 'src/ts/interfaces/my-profile.interface';
const languages = require('src/assets/en.json');
@Injectable({
  providedIn: 'root',
})
export class ContextService {
  userInfo: any;
  languages: any = languages;
  dataLoaded = new BehaviorSubject<string>('');
  // cities: selectCities[]
  validMatomo = [
    'mtm_campaign',
    'matomo_campaign',
    'pk_campaign',
    'piwik_campaign',
    'utm_campaign',
    'utm_source',
    'utm_medium',
  ];

  authFlow = true;
  validSelection: validSelection = {
    jobFamily: false,
    yearExperience: false,
    asConsultant: false,
    levelWork: false,
    skills: false,
    topSkills: false,
    sweetSpot: false,
    game: false,
    industries: false,
    technologies: false,
    languages: false,
    city: false,
    workFrom: false,
    startDate: false,
    rate: false,
    salary: false,
    currency: false,
    profProfile: false,
    submitted: false,
    jobHistory: false,
  };
  selectedGame = {
    played: false,
  };
  selectedProProfile: SelectedPro = {
    jobFamily: [],
    yearExperience: [],
    asConsultant: [],
    levelWork: [],
    skills: [],
    topSkills: [],
    sweetSpot: [],
    industries: [],
    technologies: [],
    languages: [],
    workFrom: [],
    rate: [],
    jobHistory: [],
    workTime: [],
  };
  selectedSalery: salary = {
    salary: -1,
    currency: '',
  };

  selectedProfProfile: selectProfProfile[] = [];

  selectedIndustries: SelectedIndustries = {
    industries: [],
  };
  jobHistory: jobHistory[] = [];

  selectedCity: selectCities = {
    displayName: '',
    country: '',
    state: '',
    lat: '',
    lng: '',
  };
  selectedStartDate: availability = {
    availabilityDate: '',
    fullTime: false,
    partTime: false,
  };

  profileData: profileData = {
    jobFamily: [],
    technologies: [],
    skills: [],
    industries: [],
  };

  gameAssessments: devidedGame = {
    first: {},
    second: {},
  };

  myProfileData: myProfileData = {
    skills: [],
    genome: [],
    industries: [],
    assessments: [],
    gameAssessments: [],
    community: {},
  };
  signupRules: signupRule[] = [];
  profileSubmitted: boolean;

  accessTokens: any;
  later = '';
  levelOfExperience = ['lt5', '5to8', '8to10', 'mt10'];

  stepsMenu: navigationMenu[] = [];
  stepsMenuSlug: stepsMenuSlug;
  sevenNConsulatant: boolean;
  status: status[] = [];
  profileComplete: boolean;
  constructor(private helperFunctions: HelperFunctionsService) {
    this.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        this.sevenNConsulatant =
          this.myProfileData.genome?.labels.includes('7NConsultant');

        this.status = [
          {
            title: 'Profile completion',
            description: 'Increase your chances of getting accepted.',
            passed: true,
            type: '',
            state: false,
            estimate: 'a few hours',
          },
          {
            title: 'Pending review',
            description: 'A 7N agent will review your profile once submitted.',
            passed: false,
            type: '',
            state: false,
            estimate: 'Between 0-2 weeks',
          },
          {
            title: 'Interview',
            description: 'A 7N will reach out to you for an interview.',
            passed: false,
            type: '',
            state: false,
            disable: this.sevenNConsulatant,
            estimate: 'around 3 weeks',
          },
          {
            title: this.sevenNConsulatant
              ? 'Accepted'
              : 'Accepted (or not) 🤞🏻',
            description:
              'If you’ll be accepted you can enjoy all the 7N benefits.',
            passed: false,
            type: 'approved',
            state: false,
            estimate: '1 week',
          },
        ];
        this.later = this.languages.later;

        this.stepsMenuSlug = {
          welcome: this.helperFunctions.slugify(
            this.languages.stepsMenu.welcome
          ),
          game: this.helperFunctions.slugify(this.languages.stepsMenu.game),
          roles: this.helperFunctions.slugify(this.languages.stepsMenu.roles),
          allSkills: this.helperFunctions.slugify(
            this.languages.stepsMenu.allSkills
          ),
          topSkills: this.helperFunctions.slugify(
            this.languages.stepsMenu.topSkills
          ),
          sweetspot: this.helperFunctions.slugify(
            this.languages.stepsMenu.sweetspot
          ),
          experience: this.helperFunctions.slugify(
            this.languages.stepsMenu.experience
          ),
          technologies: this.helperFunctions.slugify(
            this.languages.stepsMenu.technologies
          ),
          languages: this.helperFunctions.slugify(
            this.languages.stepsMenu.languages
          ),
          location: this.helperFunctions.slugify(
            this.languages.stepsMenu.location
          ),
          income: this.helperFunctions.slugify(this.languages.stepsMenu.income),
          profPro: this.helperFunctions.slugify(
            this.languages.stepsMenu.profPro
          ),
          finish: this.sevenNConsulatant
            ? this.helperFunctions.slugify(
                this.languages.stepsMenu.finishProfile
              )
            : this.helperFunctions.slugify(this.languages.stepsMenu.submit),
        };
        this.stepsMenu = [
          {
            state: true,
            slug: this.stepsMenuSlug.welcome,
            displayName: this.languages.stepsMenu.welcome,
            rule: 'required',
            validation: [],
          },
        ];
        this.signupRules = [
          {
            step: 'game',
            rule: this.sevenNConsulatant ? 'optional' : 'required',
            validation: ['game'],
          },
          {
            step: 'roles',
            rule: 'required',
            validation: ['yearExperience', 'jobFamily', 'levelWork'],
          },
          { step: 'allSkills', rule: 'required', validation: ['skills'] },
          { step: 'topSkills', rule: 'required', validation: ['topSkills'] },
          { step: 'sweetspot', rule: 'required', validation: ['sweetSpot'] },
          { step: 'experience', rule: 'required', validation: ['jobHistory'] },
          {
            step: 'technologies',
            rule: 'required',
            validation: ['technologies'],
          },
          { step: 'languages', rule: 'required', validation: ['languages'] },
          {
            step: 'location',
            rule: 'required',
            validation: ['city', 'workFrom', 'startDate'],
          },
          {
            step: 'income',
            rule: 'required',
            validation: ['asConsultant', 'rate', 'salary', 'currency'],
          },
          { step: 'profPro', rule: 'optional', validation: ['profProfile'] },
        ];
        this.signupRules.forEach((val) => {
          if (val.rule !== 'hidden') {
            this.stepsMenu.push({
              state: false,
              slug: this.stepsMenuSlug[val.step as keyof stepsMenuSlug],
              displayName:
                this.languages.stepsMenu[val.step as keyof stepsMenuSlug],
              rule: val.rule,
              validation: val.validation,
            });
          }
        });
        this.stepsMenu.push({
          state: false,
          slug: this.stepsMenuSlug.finish,
          displayName: this.sevenNConsulatant
            ? this.languages.stepsMenu.finishProfile
            : this.languages.stepsMenu.submit,
          rule: 'required',
          validation: [],
        });
      }
    });
  }

  title() {
    const title: string = '7N';

    return title;
  }

  navMenues() {
    const dashboard: string[] = this.languages.dashboardMenu;

    return { dashboard };
  }

  proProfileTitle() {
    const title: section[] = [
      {
        section: this.languages.experience.roles,
      },

      {
        section: this.languages.roles.experience,
      },
      {
        section: [this.languages.location.location],
      },
    ];
    return title;
  }

  proProfile() {
    const topSkills: ProProfile[] = [];
    const profile: ProProfile[] = [
      {
        id: 1,
        displayName: 'Linkedin',
        value: '',
        placeholder: 'Your LinkedIn profile',
        icon: 'brand-linkedin',
      },
      {
        id: 2,
        displayName: 'Github',
        value: '',
        placeholder: 'Your Github profile if you have one',
        icon: 'brand-github',
      },
      {
        id: 3,
        displayName: 'Dribbble',
        value: '',
        placeholder: 'Your Dribbble profile if you have one',
        icon: 'brand-dribbble',
      },
      {
        id: 4,
        displayName: 'Website',
        value: '',
        placeholder: 'Your website or portfolio link',
        icon: 'world',
      },
      {
        id: 5,
        displayName: 'Other',
        value: '',
        placeholder: 'Anything else you’d like to share?',
        icon: 'dots',
      },
    ];
    const rate: ProProfile[] = [
      { id: 1, displayName: this.languages.proProfile.rate.hourly },
      { id: 2, displayName: this.languages.proProfile.rate.monthly },
    ];

    const workFrom: ProProfile[] = [
      {
        id: 1,
        displayName: this.languages.proProfile.workFrom.remote.title,
        description: this.languages.proProfile.workFrom.remote.description,
      },
      {
        id: 2,
        displayName: this.languages.proProfile.workFrom.onsite.title,
        description: this.languages.proProfile.workFrom.onsite.description,
      },
      {
        id: 3,
        displayName: this.languages.proProfile.workFrom.hybrid.title,
        description: this.languages.proProfile.workFrom.hybrid.description,
      },
    ];

    // const industries: ProProfile[] = [
    //   { id: 1, displayName: this.languages.proProfile.industries.finBank},
    //   { id: 2, displayName: this.languages.proProfile.industries.insPen },
    //   { id: 3, displayName: this.languages.proProfile.industries.pubSec },
    //   { id: 4, displayName: this.languages.proProfile.industries.pharm },
    //   { id: 5, displayName: this.languages.proProfile.industries.manu },
    //   { id: 6, displayName: this.languages.proProfile.industries.log},
    //   { id: 7, displayName: this.languages.proProfile.industries.energy },
    //   { id: 8, displayName: this.languages.proProfile.industries.it},
    //   { id: 9, displayName: this.languages.proProfile.industries.retail },
    //   { id: 10, displayName: 'Telecommunications' },
    //   { id: 11, displayName: 'Fast moving consumer goods' },
    //   // { id: 12, displayName: 'Other' },
    // ]

    const sweetSpot: ProProfile[] = [
      { id: 1, displayName: this.languages.proProfile.sweetspot.newSkills },
      { id: 2, displayName: this.languages.proProfile.sweetspot.teamWork },
      { id: 3, displayName: this.languages.proProfile.sweetspot.autonomy },
      { id: 4, displayName: this.languages.proProfile.sweetspot.social },
      { id: 5, displayName: this.languages.proProfile.sweetspot.formality },
      { id: 6, displayName: this.languages.proProfile.sweetspot.decision },
    ];

    // const jobFamily: ProProfile[] = [
    //   { id: 1, displayName: 'Data' },
    //   { id: 2, displayName: 'IT Ops' },
    //   { id: 3, displayName: 'Product Delivery' },
    //   { id: 4, displayName: 'QAT' },
    //   { id: 5, displayName: 'Technical' },
    //   { id: 6, displayName: 'User Centred Design' },
    // ]

    const yearExperience: ProProfile[] = [
      { id: 1, displayName: this.languages.proProfile.yearExperience['lt5'] },
      { id: 2, displayName: this.languages.proProfile.yearExperience['5to8'] },
      { id: 3, displayName: this.languages.proProfile.yearExperience['8to10'] },
      { id: 4, displayName: this.languages.proProfile.yearExperience['mt10'] },
    ];
    const asConsultant: ProProfile[] = [
      { id: 1, displayName: this.languages.experience.workPref.cta.yes },
      { id: 2, displayName: this.languages.experience.workPref.cta.no },
    ];
    const levelWork: ProProfile[] = [
      {
        id: 1,

        displayName: this.languages.proProfile.levelWork.experienced.title,
        description:
          this.languages.proProfile.levelWork.experienced.description,
      },
      {
        id: 2,
        displayName: this.languages.proProfile.levelWork.senior.title,
        description: this.languages.proProfile.levelWork.senior.description,
      },
      {
        id: 3,
        displayName: this.languages.proProfile.levelWork.lead.title,
        description: this.languages.proProfile.levelWork.lead.description,
      },
      {
        id: 4,
        displayName: this.languages.proProfile.levelWork.head.title,
        description: this.languages.proProfile.levelWork.head.description,
      },
    ];
    // const skills: ProProfile[] = [
    //   { id: 1, displayName: 'Communication skills (data)' },
    //   { id: 2, displayName: 'Data analysis and synthesis' },
    //   { id: 3, displayName: 'Data communication' },
    //   { id: 4, displayName: 'Data governance' },
    //   { id: 5, displayName: 'Data innovation' },
    //   { id: 6, displayName: 'Data modelling' },
    //   { id: 7, displayName: 'Data standards' },
    //   { id: 8, displayName: 'Metadata management' },
    //   { id: 9, displayName: 'Problem resolution (data)' },
    //   { id: 10, displayName: 'Strategic thinking (data architecture)' },
    //   { id: 11, displayName: 'Turning business problems into data design' },
    //   { id: 12, displayName: 'Availability and capacity management' },
    //   { id: 13, displayName: 'Development process optimisation' },
    //   { id: 14, displayName: 'Information security' },
    //   { id: 15, displayName: 'Modern standards approach' },
    //   { id: 16, displayName: 'Programming and build (software engineering)' },
    //   { id: 17, displayName: 'Prototyping' },
    //   { id: 18, displayName: 'Service support' },
    //   { id: 19, displayName: 'Systems design' },
    //   { id: 20, displayName: 'Systems integration' },
    //   { id: 21, displayName: 'User focus' },
    //   { id: 22, displayName: 'Coding & scripting' },
    //   { id: 23, displayName: 'IT infrastructure' },
    //   { id: 24, displayName: 'Ownership and initiative' },
    //   { id: 25, displayName: 'Problem solving' },
    //   { id: 26, displayName: 'Service focus' },
    //   {
    //     id: 27,
    //     displayName: 'Technical understanding (Infrastructure engineer)',
    //   },
    //   { id: 28, displayName: 'Testing' },
    //   { id: 29, displayName: 'Troubleshooting and problem resolution' },
    //   { id: 30, displayName: 'Governance and assurance' },
    //   { id: 31, displayName: 'Technical understanding (network architect)' },
    //   { id: 32, displayName: 'System integration (network architect)' },
    //   { id: 33, displayName: 'Analysis' },
    //   { id: 34, displayName: 'Communication skills (security architect)' },
    //   { id: 35, displayName: 'Design secure systems' },
    //   { id: 36, displayName: 'Enabling and informing risk-based decisions' },
    //   { id: 37, displayName: 'Research and innovation' },
    //   { id: 38, displayName: 'Specific security technology and understanding' },
    //   {
    //     id: 39,
    //     displayName: 'Understanding security implications of transformation',
    //   },
    //   {
    //     id: 40,
    //     displayName: 'Bridging the gap between the technical and non-technical',
    //   },
    //   { id: 41, displayName: 'Making and guiding decisions' },
    //   {
    //     id: 42,
    //     displayName: 'Turning business problems into technical design',
    //   },
    //   { id: 43, displayName: 'Understanding the whole context' },
    // ]

    // const technologies = [
    //   { family: 'Platform', id: 1, displayName: 'Web' },
    //   { family: 'Platform', id: 2, displayName: 'Mobile' },
    //   { family: 'Platform', id: 3, displayName: 'iOS' },
    //   { family: 'Platform', id: 4, displayName: 'Android' },
    //   { family: 'Platform', id: 5, displayName: 'Linux' },
    //   { family: 'Platform', id: 6, displayName: 'Windows Server' },
    //   { family: 'Tools', id: 7, displayName: 'Figma' },
    //   { family: 'Tools', id: 8, displayName: 'Adobe XD' },
    //   { family: 'Tools', id: 9, displayName: 'Sketch' },
    //   { family: 'Tools', id: 10, displayName: 'Photoshop' },
    //   { family: 'Tools', id: 11, displayName: 'Adobe Illustrator' },
    //   { family: 'Tools', id: 12, displayName: 'Invision' },
    //   { family: 'Tools', id: 13, displayName: 'Webflow' },
    //   { family: 'Tools', id: 14, displayName: 'Postman' },
    //   { family: 'Tools', id: 15, displayName: 'SQL DataModeler' },
    //   { family: 'Tools', id: 16, displayName: 'SwiftUI' },
    //   { family: 'Tools', id: 17, displayName: 'Apache Kafka' },
    //   { family: 'Tools', id: 18, displayName: 'Apache Spark' },
    //   { family: 'Tools', id: 19, displayName: 'Tableau' },
    //   { family: 'Tools', id: 20, displayName: 'Power BI' },
    //   { family: 'Tools', id: 21, displayName: 'Apache Hadoop' },
    //   { family: 'Tools', id: 22, displayName: 'Apache Hive' },
    //   { family: 'Tools', id: 23, displayName: 'Informatica' },
    //   { family: 'Tools', id: 24, displayName: 'Apache Ariflow' },
    //   { family: 'Tools', id: 25, displayName: 'Bash' },
    //   { family: 'Tools', id: 26, displayName: 'Jira' },
    //   { family: 'Tools', id: 27, displayName: 'Confluence' },
    //   { family: 'Tools', id: 28, displayName: 'Notion' },
    //   { family: 'Tools', id: 29, displayName: 'Visual Studio' },
    //   { family: 'Programming Language', id: 30, displayName: 'C++' },
    //   { family: 'Programming Language', id: 31, displayName: 'C#' },
    //   { family: 'Programming Language', id: 32, displayName: 'Golang' },
    //   { family: 'Programming Language', id: 33, displayName: 'Haskell' },
    //   { family: 'Programming Language', id: 34, displayName: 'Java' },
    //   { family: 'Programming Language', id: 35, displayName: 'JavaScript' },
    //   { family: 'Programming Language', id: 36, displayName: 'Kotlin' },
    //   { family: 'Programming Language', id: 37, displayName: 'Objective C' },
    //   { family: 'Programming Language', id: 38, displayName: 'Python' },
    //   { family: 'Programming Language', id: 39, displayName: 'VisualBasic' },
    //   { family: 'Programming Language', id: 40, displayName: 'PHP' },
    //   { family: 'Programming Language', id: 41, displayName: 'Ruby' },
    //   { family: 'Programming Language', id: 42, displayName: 'Rust' },
    //   { family: 'Programming Language', id: 43, displayName: 'Solidity' },
    //   { family: 'Programming Language', id: 44, displayName: 'TypeScript' },
    //   { family: 'Programming Language', id: 45, displayName: 'Ruby on Rails' },
    //   { family: 'Programming Language', id: 46, displayName: 'Scala' },
    //   { family: 'Programming Language', id: 47, displayName: 'CSS' },
    //   { family: 'Programming Language', id: 48, displayName: 'C' },
    //   { family: 'Programming Language', id: 49, displayName: 'Cobol' },
    //   { family: 'Programming Language', id: 50, displayName: 'Swift' },
    //   { family: 'Programming Language', id: 51, displayName: 'Tensorflow' },
    //   { family: 'Programming Language', id: 52, displayName: 'R' },
    //   { family: 'Programming Language', id: 53, displayName: 'Julia' },
    //   { family: 'Programming Language', id: 54, displayName: 'JSON' },
    //   { family: 'Programming Language', id: 55, displayName: 'T-SQL' },
    //   { family: 'Programming Language', id: 56, displayName: 'PL/SQL' },
    //   { family: 'Programming Language', id: 57, displayName: 'PowerShell' },
    //   { family: 'Frameworks', id: 58, displayName: 'Angular' },
    //   { family: 'Frameworks', id: 59, displayName: 'Django' },
    //   { family: 'Frameworks', id: 60, displayName: 'Flask' },
    //   { family: 'Frameworks', id: 61, displayName: 'Flutter' },
    //   { family: 'Frameworks', id: 62, displayName: 'Node.js' },
    //   { family: 'Frameworks', id: 63, displayName: 'Next.js' },
    //   { family: 'Frameworks', id: 64, displayName: 'Spring' },
    //   { family: 'Frameworks', id: 65, displayName: 'React.js' },
    //   { family: 'Frameworks', id: 66, displayName: 'React native' },
    //   { family: 'Frameworks', id: 67, displayName: 'Redux' },
    //   { family: 'Frameworks', id: 68, displayName: 'Vue.js' },
    //   { family: 'Frameworks', id: 69, displayName: 'Substrate' },
    //   { family: 'Frameworks', id: 70, displayName: 'SwiftUI' },
    //   { family: 'Frameworks', id: 71, displayName: 'Web3.js' },
    //   { family: 'Frameworks', id: 72, displayName: 'Wordpress' },
    //   { family: 'Frameworks', id: 73, displayName: 'Selenium' },
    //   { family: 'Frameworks', id: 74, displayName: 'Appium' },
    //   { family: 'Frameworks', id: 75, displayName: 'Cosmos SDK' },
    //   { family: 'Frameworks', id: 76, displayName: 'Tailwind' },
    //   { family: 'Frameworks', id: 77, displayName: 'Unity' },
    //   { family: 'Frameworks', id: 78, displayName: 'UIkit' },
    //   { family: 'Frameworks', id: 79, displayName: 'Apache Hadoop' },
    //   { family: 'Frameworks', id: 80, displayName: 'Spring Boot' },
    //   { family: 'Frameworks', id: 81, displayName: 'GraphQL' },
    //   { family: 'Frameworks', id: 82, displayName: '.Net' },
    //   { family: 'Frameworks', id: 83, displayName: 'ASP.NET' },
    //   { family: 'Frameworks', id: 84, displayName: 'Keras' },
    //   { family: 'Database', id: 85, displayName: 'MySQL' },
    //   { family: 'Database', id: 86, displayName: 'Oracle' },
    //   { family: 'Database', id: 87, displayName: 'Cassandra' },
    //   { family: 'Database', id: 88, displayName: 'MongoDB' },
    //   { family: 'Database', id: 89, displayName: 'Postgres' },
    //   { family: 'Database', id: 90, displayName: 'DynamoDB' },
    //   { family: 'Database', id: 91, displayName: 'MS SQL' },
    //   { family: 'Cloud CIDI', id: 92, displayName: 'AWS' },
    //   { family: 'Cloud CIDI', id: 93, displayName: 'Azure' },
    //   { family: 'Cloud CIDI', id: 94, displayName: 'Google Cloud Platform' },
    //   { family: 'Cloud CIDI', id: 95, displayName: 'Jenkins' },
    //   { family: 'Cloud CIDI', id: 96, displayName: 'Docker' },
    //   { family: 'Cloud CIDI', id: 97, displayName: 'Kubernetes' },
    //   { family: 'Cloud CIDI', id: 98, displayName: 'Terraform' },
    //   { family: 'Methods', id: 99, displayName: 'PRINCE2' },
    //   { family: 'Methods', id: 100, displayName: 'ITIL' },
    //   { family: 'Methods', id: 101, displayName: "PMI's PMBOK" },
    //   { family: 'Methods', id: 102, displayName: 'Lean' },
    //   { family: 'Methods', id: 103, displayName: 'Six Sigma' },
    //   { family: 'Methods', id: 104, displayName: 'Scrum' },
    //   { family: 'Methods', id: 105, displayName: 'Kanban' },
    // ]

    return {
      // jobFamily,
      yearExperience,
      asConsultant,
      levelWork,
      // skills,
      sweetSpot,
      // industries,
      // technologies,
      workFrom,
      rate,
      profile,
    };
  }
}
