<div class="loading" *ngIf="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading" (scroll)="onScroll($event)" [class]="'dashboard content '+ (openSidePanel ? 'open': 'close')">
  <div [class]="'left-content ' + (openSidePanel ? 'open': 'close')">
    <div>
      <div>
        <app-heading [showPageName]="showPageName" [selectedNav]="getFrendlyName(selectedNav)!"
          [openSidePanel]="openSidePanel" (callbackFunction)="toggleSide()"></app-heading>
        <app-navigation (step)="step($event)" [selectedNav]="selectedNav" [navs]="navs" [type]="'dashboard'"
          (step)="navigation($event)">
        </app-navigation>

      </div>
      <div class="bottom">
        <div class="get-in-touch">
          <div>
            <p class="label2">
              Get in touch
            </p>
            <p class="body2">
              Ideas, bugs or general feedback?<br>
              We'd like to hear from you at:
            </p>
          </div>
          <a class="link body2" href="mailto:feedback@7n.com">hello@7n.com</a>
        </div>
        <div>
          <app-button (click)="signout()" [class]="['inverted']" [label]="'Sign Out - '+givinName"></app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="right-content" [class]="{'not-submitted': !contextService.profileSubmitted}">
    <app-home *ngIf="selectedNav === 'home'"></app-home>
    <app-my-profile *ngIf="selectedNav === 'profile'"></app-my-profile>
    <app-community *ngIf="selectedNav === 'community' && hasAccessToCommunity"></app-community>
  </div>
</div>

<!-- <div class="loading" *ngIf="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading" (scroll)="onScroll($event)" [class]="'dashboard content '+ (openSidePanel ? 'open': 'close')">
  <div *ngIf="contextService.myProfileData.genome.status === 'Approved'"
    [class]="'left-content ' + (openSidePanel ? 'open': 'close')">
    <div>
      <app-heading [showPageName]="showPageName" [selectedNav]="getFrendlyName(selectedNav)!"
        [openSidePanel]="openSidePanel" (callbackFunction)="toggleSide()"></app-heading>
      <app-navigation (step)="step($event)" [selectedNav]="selectedNav" [navs]="navs" [type]="'dashboard'"
        (step)="navigation($event)">
      </app-navigation>
      <div class="get-in-touch">
        <div>
          <p class="label1">
            Get in touch
          </p>
          <p class="label2">
            Ideas, bugs or general feedback?<br>
            We'd like to hear from you at:
          </p>
        </div>
        <a class="link body2" href="mailto:feedback@7n.com">hello@7n.com</a>
      </div>
    </div>
    <div class="bottom">
      <div>
        <p (click)="signout()" class="link">Sign Out - {{givinName}}</p>
      </div>
    </div>
  </div>
  <div class="right-content" [class]="{'not-submitted': !contextService.profileSubmitted}">
    <app-home *ngIf="selectedNav === 'home'"></app-home>
    <app-my-profile *ngIf="selectedNav === 'profile'"></app-my-profile>
    <app-community *ngIf="selectedNav === 'community' && hasAccessToCommunity"></app-community>
  </div>
</div> -->
