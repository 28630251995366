<div class="claiming">
  <div class="left-content">
    <img src="assets/images/jobopportunity.jpeg" />
    <div>
      <img class="logo" src="assets/images/7nlogo.png" />
      <h1>Unleash your potential as a 7N consultant</h1>
    </div>
  </div>

  <div *ngIf="submitted === 0" class="right-content">
    <div class="loading" *ngIf="loading">
      <app-loading></app-loading>
    </div>
    <ng-container *ngIf="!loading">
      <h1>New opportunity</h1>
      <div class="request">
        <div
          [innerText]="
            'We are currently looking for consultants for the following opportunity. Please inform us of your interest and availability.'
          "
          class="agent-message"
        ></div>
        <div class="request-detail">
          <h3 class="subtitle1">{{ title }}</h3>
          <div class="description">
            <p class="label2">Description</p>
            <p class="body2" [innerText]="description"></p>
          </div>
        </div>
        <div class="actions">
          <h3>Are you interested?</h3>
          <div>
            <app-buttons
              *ngFor="let action of actions; let i = index"
              [single]="true"
              [selected]="respond.id === action.id"
              (click)="selectInterest(action.id)"
              type="levelWork"
              [loop]="i"
              [data]="action"
            >
            </app-buttons>

            <!-- <app-button *ngFor="let action of actions;" (click)="selectInterest(action.id)"
              [class]="['full-width', respond.id === action.id ? 'primary': 'secondary']"
              [label]="action.label"></app-button> -->
          </div>
          <ng-container *ngFor="let action of actions">
            <div
              class="more-info"
              *ngIf="respond.id === action.id && respond.id !== 1"
            >
              <div *ngFor="let moreInfo of action.moreInfo">
                <div>
                  <h4 class="subtitle2">{{ moreInfo.headline }}</h4>
                  <p class="body2" *ngIf="moreInfo.subtitle">
                    {{ moreInfo.subtitle }}
                  </p>
                </div>
                <app-input
                  trailingIcon="calendar"
                  *ngIf="moreInfo.type === 'calendar'"
                  type="calendar"
                  (selectedCallback)="input($event, 'availability')"
                ></app-input>
                <app-input
                  (input)="input($event, 'description')"
                  type="textarea"
                  placeholder="Describe why it’s not a good match"
                  *ngIf="moreInfo.type === 'input'"
                ></app-input>
              </div>
            </div>
          </ng-container>
          <app-button
            (click)="submit()"
            [disable]="!readyForSubmit"
            [class]="['primary']"
            label="Submit"
          ></app-button>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="submitted === 1" class="right-content">
    <div class="submitted">
      <div class="image">
        <img src="assets/images/In-progress.png" />
      </div>
      <div class="container">
        <h1>Great, {{ firstName }}!</h1>
        <p>
          Thank you for your response. <br />We will get back to you with the
          next steps as soon as possible.
        </p>
        <p>Best regards, 7N</p>
      </div>
    </div>
  </div>
  <div *ngIf="submitted > 1" class="right-content">
    <div class="submitted">
      <div class="image">
        <img src="assets/images/CV.png" />
      </div>
      <div class="container">
        <h1>Thank you, {{ firstName }}!</h1>
        <p>We appreciate your response!</p>
        <p>Best regards, 7N</p>
      </div>
    </div>
  </div>
  <div *ngIf="submitted === -1" class="right-content">
    <div class="submitted">
      <div class="image">
        <img src="assets/images/CV.png" />
      </div>
      <div class="container">
        <p>
          The link to the description of the opportunity has expired. Please
          reach out to your 7N Agent.
        </p>
        <!-- <p>We appreciate your feedback.</p>
        <p>Best regards, 7N</p> -->
      </div>
    </div>
  </div>
</div>
