<div *ngIf="!authFlow">
  <div *ngIf="!loading" [class]="{'applyhere':page === 'applyhere'}" class="landingPage">
    <div class="header">
      <app-logo></app-logo>
      <div class="menues">
        <div *ngFor="let menu of menues">
          <p class="hover-link" (click)="route(menu.route)">{{menu.title}}</p>
        </div>
      </div>
    </div>
    <app-apply-here *ngIf="page === 'applyhere'" [postslug]="postslug"></app-apply-here>
    <div *ngIf="page === ''" class="content">
      <section class="first-section">
        <div class="card">
          <div>
            <h1> <span>{{languages.landingPage.firstSection.headlineBold}}
              </span>{{languages.landingPage.firstSection.headline}}</h1>
            <p class="body2">{{languages.landingPage.firstSection.excerpt}}</p>
            <p class="">{{languages.landingPage.firstSection.description}}</p>
          </div>
          <div>
            <div>
              <p class="bold">{{languages.landingPage.firstSection.h4}}</p>
              <p class="">{{languages.landingPage.firstSection.h4_excerpt}}</p>
            </div>
            <app-button (click)="route('/signup')" [class]="['primary']"
              [label]="languages.landingPage.firstSection.cta" [trailingIcon]="'chevron-right'"></app-button>
          </div>
          <p class="grayed">{{languages.landingPage.firstSection.p_small_grayed}}</p>
        </div>
        <div class="image">
          <ng-container *ngFor="let image of imageObject">
            <img [src]="image.thumbImage" />

            <h1>{{image.title}}</h1>
          </ng-container>
        </div>
      </section>
      <section class="second-section">
        <h1>{{languages.landingPage.secondSection.headline}}</h1>
        <div>
          <div *ngFor="let lookingfor of languages.landingPage.secondSection.lookingfor">
            <p class="baseline_xlarge">{{lookingfor.headline}}</p>
            <p class="">{{lookingfor.description}}
            </p>
          </div>
        </div>
        <div class="image">
          <img class="desktop" src="./assets/images/profile_desktop.png" />
          <img class="mobile" src="./assets/images/profile_mobile.png" />
        </div>
      </section>
      <section class="third-section">
        <div>
          <div>
            <h1>{{languages.landingPage.thirdSection.headline}}</h1>
            <p>{{languages.landingPage.thirdSection.description}}</p>
          </div>
          <div class="agent-info">
            <div>
              <p class="baseline_small">{{languages.agent.title}}</p>
              <p class="baseline_small">{{languages.agent.name}}</p>
              <p class="baseline_small">{{languages.agent.email}}</p>
            </div>
            <div class="image">
              <img [src]="languages.agent.imgUrl" />
            </div>
          </div>
        </div>
        <div class="status">
          <div *ngFor="let steps of languages.landingPage.thirdSection.steps; let i = index">
            <fa-icon [icon]="faCheck"></fa-icon>
            <p><span>{{i}}.</span> {{steps}}</p>
          </div>
        </div>
      </section>
      <section class="fourth-section">
        <div *ngFor="let card of languages.landingPage.fourthSection.cards">
          <h1>{{card.headline}}</h1>
          <p *ngFor="let description of card.description">{{description}}</p>
          <a *ngIf="card.readMore" [href]="card.readMore.link" target="_blank">{{card.readMore.description}}</a>
        </div>
      </section>
      <section class="section-fifth">
        <div>
          <h1>{{languages.landingPage.fifthSection.headline}}</h1>
          <app-button (click)="route('/signup')" [class]="['primary']"
          [label]="languages.landingPage.firstSection.cta" [trailingIcon]="'chevron-right'"></app-button>
        </div>
      </section>
    </div>
  </div>
  <footer [class]="{'applyhere':page === 'applyhere'}">
    <div>
      <div *ngFor="let footer of languages?.landingPage.footer">
        <p class="body2">{{footer.headline}}</p>
        <p *ngFor="let desc of footer.description">{{desc}}</p>
        <a *ngFor="let link of footer.link" [href]="link.href" target="_blank">{{link.text}}</a>
      </div>
    </div>
  </footer>
</div>
