<div class="navbar label2" [class]="class">
  <div>
    <i-tabler *ngIf="leadingIcon" [name]="leadingIcon"></i-tabler>
    <span *ngIf="number">{{number}}</span>
    <div *ngIf="label">{{label}}</div>
  </div>
  <div>
    <div *ngIf="amount" class="amount caption">{{amount}}</div>
    <i-tabler *ngIf="trailingIcon" [name]="trailingIcon"></i-tabler>
  </div>
</div>
