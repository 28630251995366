import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { b2cPolicies, authScopes } from 'src/app/auth-config';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  steps: number;
  loading: boolean = true;
  languages: any;
  agent: any;
  progress: { label: string; state: boolean }[] = [
    { label: '', state: false },
    { label: '', state: false },
    { label: '', state: false },
    { label: '', state: false },
    { label: '', state: false },
  ];
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.steps = 1;
    this.platformService.steps.subscribe((value) => {
      this.progress.map((x, i) => {
        x.state = i < value;
      });

      this.steps = value;
    });
    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'successNoToken') {
        this.languages = this.contextService.languages.leftPanelSignup;
        this.agent = this.contextService.languages.agent;
        this.loading = false;
      }
    });
  }
  ngOnInit(): void {
    if (localStorage.getItem('selectedProProfile')) {
      this.contextService.selectedProProfile = JSON.parse(
        localStorage.getItem('selectedProProfile')!
      );
    }
    if (localStorage.getItem('selectedCity')) {
      this.contextService.selectedCity = JSON.parse(
        localStorage.getItem('selectedCity')!
      );
    }
  }

  title = this.contextService.title();

  signin() {
    this.authenticationService.signin();
  }

  signup() {
    let signupFlowRequest = {
      scopes: authScopes,
      authority: b2cPolicies.authorities.signup.authority,
    };

    this.authenticationService.userRequest(signupFlowRequest);
  }

  step(value: number, type?: string) {
    if (value < 1 && type === 'previous') {
      this.router.navigate(['/signup']);
      return;
    }

    switch (value) {
      case 2:
        if (
          this.contextService.selectedProProfile.jobFamily.length === 0 ||
          this.contextService.selectedProProfile.levelWork.length === 0
        )
          return;
        break;
      case 3:
        if (
          this.contextService.selectedProProfile.asConsultant.length === 0 ||
          this.contextService.selectedProProfile.yearExperience.length === 0
        )
          return;
        break;
      case 4:
        if (this.contextService.selectedCity?.displayName.length === 0) return;
        localStorage.setItem(
          'selectedProProfile',
          JSON.stringify(this.contextService.selectedProProfile)
        );
        localStorage.setItem(
          'selectedCity',
          JSON.stringify(this.contextService.selectedCity)
        );
        const terms = {
          timeOfConsent: new Date().toISOString(),
          urlOfTermsOfUse: 'https://www.7n.com/privacy-policy',
        };
        sessionStorage.setItem('terms', JSON.stringify(terms));
        sessionStorage.setItem('signup', 'true');
        sessionStorage.setItem('referrer', document.referrer);
        sessionStorage.setItem('signupOrSignin', 'true');
        this.signup();
        return;
      default:
        break;
    }

    this.steps = value;
    this.router.navigate(['/registration'], {
      queryParams: { step: value },
      queryParamsHandling: 'merge',
    });
    this.platformService.changeStep(this.steps);
  }
}
