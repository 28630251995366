import { Component, Input, OnInit } from '@angular/core';
import {
  ProProfileSlider,
  ruleLength,
  SelectedPro,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import { Options } from '@angular-slider/ngx-slider';
import { MatSliderChange } from '@angular/material/slider';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-sweet-spot',
  templateUrl: './sweet-spot.component.html',
  styleUrls: ['./sweet-spot.component.scss'],
})
export class SweetSpotComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  selectedProProfile = this.contextService.selectedProProfile;
  sliderOptions: ProProfileSlider[] = [
    { value: -2, legend: 'Not important' },
    { value: -1 },
    { value: 0 },
    { value: 1 },
    { value: 2, legend: 'Very important' },
  ];
  value: number[] = this.selectedProProfile.sweetSpot;
  options: Options = {
    showTicksValues: true,
    floor: 0,
    ceil: 100,
  };
  ruleLength: ruleLength = {
    sweetSpot: this.proProfile.sweetSpot.length,
  };
  languages = this.contextService.languages.sweetspot;
  validated = this.contextService.validSelection['sweetSpot'];
  @Input() disabled = false;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {
    this.platformService.reRender.subscribe((val) => {
      if (val === 'save') {
        this.value = this.selectedProProfile.sweetSpot;
      }
    });
    if (this.selectedProProfile.sweetSpot.length === 0) {
      this.proProfile.sweetSpot.forEach(() => {
        this.selectedProProfile.sweetSpot.push(-1);
      });
    }
  }

  validateChoices(type: string) {
    this.validated = false;
    this.contextService.validSelection[type] = this.validated;
    if (
      !this.contextService.selectedProProfile[type as keyof SelectedPro].some(
        (x) => x < 0
      )
    ) {
      this.validated = true;
      this.contextService.validSelection[type] = this.validated;
    }
    this.platformService.reRender.next(type);
  }

  onChange(type: string, i: number) {}
  ngOnInit(): void {}

  selectedPro = (val: object, index: number): void => {};
}
