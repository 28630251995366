import { Component } from '@angular/core'
import { faCircle, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  faSpinner = faSpinnerThird
  faCircle = faCircle
}
