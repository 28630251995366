<div class="wrap-selection">

  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
       {{languages.headline}}
      </h1>
      <p class="body2">{{languages.description}}</p>
    </div> -->
    <app-header [rule]="rule" [languages]="languages"></app-header>
    <div class="professional-profile">
      <div *ngFor="let profile of proProfile.profile">
        <!-- <p class="label1">{{profile.displayName}}</p> -->
        <app-input combined="leading" (blur)="validate(profile.id)" [placeholder]="profile.placeholder!"
          (input)="input($event, profile.id)" [value]="getValue(profile.id)" [icon]="profile.icon!"></app-input>
        <!-- <input (blur)="validate(profile.id)" (input)="input($event, profile.id)" [value]="getValue(profile.id)" /> -->
      </div>
    </div>
  </div>
</div>
