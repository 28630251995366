<div *ngIf="!loading" class="signup-component">
  <div class="for-responsive">
    <div class="testimonial">
      <img src="assets/images/testimonial.png" />
      <div class="text"></div>
    </div>
    <div class="content">
      <div>
        <div class="text">
          <h2 class="title2">{{ languages.headline }}</h2>
          <p class="body2" [innerHTML]="languages.description"></p>
        </div>
        <app-button
          [routerLink]="['/', 'registration']"
          [queryParams]="{ step: 1 }"
          queryParamsHandling="merge"
          [class]="['primary']"
          [label]="languages.cta"
          [trailingIcon]="'chevron-right'"
        ></app-button>

        <p class="body2">
          {{ languages.alreadySignin }}
          <a class="link" (click)="signin()">Sign in</a> instead
        </p>
      </div>
    </div>
  </div>
</div>
