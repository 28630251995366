<div class="wrap-selection">
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages?.profile.headline}}
      </h1>
      <p class="body2">{{languages?.profile.description}}</p>
    </div> -->
    <app-header [languages]="languages?.profile"></app-header>
    <div [class]="{'nonSelected':  selectedProProfile.jobFamily.length === 0, 'skills': true}">
      <app-buttons *ngFor="let job of jobFamily; let i = index" [single]="true"
        [selected]="selectedProProfile.jobFamily.includes(job.id)" [callbackFunction]="selectedPro" type="jobFamily"
        [loop]="i" [data]="job">
      </app-buttons>

    </div>
  </div>
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages?.level.headline}}
      </h1>
      <p class="body2">{{languages?.level.description}}</p>
    </div> -->
    <app-header [languages]="languages?.level"></app-header>
    <div [class]="{'nonSelected':  selectedProProfile.levelWork.length === 0, 'level-work': true}">
      <app-buttons *ngFor="let level of proProfile.levelWork; let i = index" [single]="true"
        [selected]="selectedProProfile.levelWork.includes(level.id)" [callbackFunction]="selectedPro" type="levelWork"
        [loop]="i" [data]="level">
      </app-buttons>

    </div>
  </div>
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages.experience.workPref.title}}
      </h1>
      <p class="body2"> {{languages.experience.workPref.description}}</p>
    </div> -->
    <app-header [languages]="languages?.experience[1]"></app-header>
    <div [class]="{'nonSelected': selectedProProfile.asConsultant.length === 0, 'asConsultant': true}">
      <app-buttons *ngFor="let level of proProfile.asConsultant; let i = index" [single]="true" [maxSelection]="2"
        [selectedArray]="selectedProProfile.asConsultant"
        [selected]="selectedProProfile.asConsultant.includes(level.id)" [callbackFunction]="selectedPro"
        type="asConsultant" [loop]="i" [data]="level">
      </app-buttons>
    </div>
  </div>
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages.experience[0].title}}
      </h1>
      <p class="body2">{{languages.experience[0].description}}</p>
    </div> -->
    <app-header [languages]="languages?.experience[0]"></app-header>
    <div [class]="{'nonSelected':  selectedProProfile.yearExperience.length === 0, 'yearExp': true}">
      <app-buttons *ngFor="let yearExp of proProfile.yearExperience; let i = index"
        [selected]="selectedProProfile.yearExperience.includes(yearExp.id)" [callbackFunction]="selectedPro"
        type="yearExperience" [loop]="i" [single]="true" [data]="yearExp"></app-buttons>

    </div>
  </div>


</div>
