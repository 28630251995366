<div [class]="type[0] + ' dialog'">
  <div *ngIf="type[0] === 'playGame'">
    <div class="card-top">
      <div></div>
      <p (click)="closeDialog()" class="link">Close</p>
    </div>
    <div class="card-content">
      <div class="play-game">
        <div *ngIf="!introRead" class="introduction">
          <div>
            <div>
              <h1>Introduction</h1>
            </div>
            <div>
              <p>We have teamed up with a leading provider in game-based assessments to offer a fun way to understand
                where your soft skills are strongest.</p>
              <div>
                <p>The games can’t be played in incognito-mode.</p>
                <p>
                  You need a minimum 30 min to complete the games.
                </p>
                <p>
                  You can’t pause the games so make sure you have the time available and you are in a comfortable
                  environment to complete them.
                </p>
              </div>
              <p>The results will be made available to you on your profile page after completion. The games will output
                both your strengths and your challenges. This data will also be available to your 7N agent. The primary
                purpose of the game-based assessment is to match you best possible to potential jobs </p>
              <p><b>It’s based on science!</b><br>
                The games have been developed by our partner Equalture. <a class="link" target="_blank"
                  href="https://equalture.com">
                  Learn more.</a></p>
            </div>
          </div>
          <app-cta (callbackFunction)="step($event)" [buttons]="introductionBtn">
          </app-cta>
        </div>
        <app-game-assessments [gameText]="gameText" *ngIf="introRead"
          (callbackFunction)="closeDialog($event)"></app-game-assessments>
      </div>
    </div>
  </div>
  <div *ngIf="type[0] === 'card'">
    <div class="card-top">
      <div></div>
      <p (click)="closeDialog()" class="link">Close</p>
    </div>
    <div class="card-content">
      <ng-container *ngIf="type[1] === 'job-history'">
        <div class="job-history">
          <app-profile></app-profile>
        </div>
      </ng-container>
      <ng-container *ngIf="type[1] === 'join-community'">
        <div class="join-community" *ngFor="let ben of cardInfo">
          <div>
            <div>
              <h1>{{ben.title}}</h1>
            </div>
            <div>
              <div *ngFor="let article of ben.articles">
                <div class="text">
                  <p class="body2 bold">{{article.headline}}</p>
                  <p class="description" [innerHTML]="article.description"></p>
                </div>
                <div class="image">
                  <img *ngIf="article.imgUrl" [src]="article.imgUrl" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="type[1] === 'welcome'">
        <div class="benifits">
          <div>
            <div class="headline">
              <h1>Welcome to 7N</h1>
              <p>We are happy to welcome you to 7N and look forward to working with you. Below is an introduction to
                what 7N offers today and what is coming in 2023 </p>
            </div>
            <div class="cards" *ngFor="let ben of cardInfo">
              <div>
                <h1>{{ben.title}}</h1>
              </div>
              <div>
                <div *ngFor="let article of ben.articles">
                  <div class="image">
                    <img *ngIf="article.imgUrl" [src]="article.imgUrl" />
                  </div>
                  <div class="text">
                    <p class="body2 bold">{{article.headline}}</p>
                    <p class="description">{{article.description}}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="type[0] === 'edit-section'">
    <div>
      <app-profile (closeDialog)="closeDialog()" [edit]="type[1]" [toEdit]="toEdit"></app-profile>
    </div>
  </div>
  <div *ngIf="type[0] === 'assessment'">
    <div class="assessment-top">
      <div>{{languages.dialog.additional}}</div>
      <p (click)="closeDialog()" class="link">Close</p>
    </div>
    <div class="assessment-content">
      <div class="index">
        <h1>{{data.scores[0].traitDisplayName}}</h1>
        <div>
          <p class="basline_large bold">
            {{languages.dialog.game.dialog}}
          </p>
          <p>
            {{data.scores[0].traitExcerpt}}
          </p>
        </div>
      </div>
      <section>
        <p>{{data.scores[0].scoreExcerpt}}</p>
        <div class="five-step">
          <div [class]="{'active':data.scores[0].score === 1}"></div>
          <div [class]="{'active':data.scores[0].score === 2}"></div>
          <div [class]="{'active':data.scores[0].score === 3}"></div>
          <div [class]="{'active':data.scores[0].score === 4}"></div>
          <div [class]="{'active':data.scores[0].score === 5}"></div>
        </div>
        <div class="five-step-description">
          <div>
            <p class="body2 bold"> {{data.scores[0].leftHandScoreLabel}}</p>
            <p class="baseline_small"> {{data.scores[0].leftHandScoreExcerpt}}</p>
          </div>
          <div>
            <p class="body2 bold"> {{data.scores[0].rightHandScoreLabel}}</p>
            <p class="baseline_small"> {{data.scores[0].rightHandScoreExcerpt}}</p>
          </div>
        </div>
      </section>
      <section class="details">
        <div>
          <p class="body2 bold">{{languages.dialog.game.prosCons[0]}}</p>
          <ul>
            <li *ngFor="let strengths of data.scores[0].strengths">
              {{strengths}}
            </li>
          </ul>
        </div>
        <div>
          <p class="body2 bold">{{languages.dialog.game.prosCons[1]}}</p>
          <ul>
            <li *ngFor="let challenge of data.scores[0].challenges">
              {{challenge}}
            </li>
          </ul>
        </div>
      </section>
      <section class="details">
        <div>
          <p class="body2 bold">{{languages.dialog.game.prosCons[2]}}</p>
          <ul>
            <li *ngFor="let fitTasks of data.scores[0].fitTasks">
              {{fitTasks}}
            </li>
          </ul>
        </div>
        <div>
          <p class="body2 bold">{{languages.dialog.game.prosCons[3]}}</p>
          <ul>
            <li *ngFor="let noFitTasks of data.scores[0].noFitTasks">
              {{noFitTasks}}
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</div>
