import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { ContextService } from './context.service';
import { ValidationService } from './validation.service';
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  routerInfo = new BehaviorSubject<boolean>(false);

  reRender = new BehaviorSubject<string>('');

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private contextService: ContextService,
    private validationService: ValidationService
  ) {
    if (
      !['opportunity.7n20.cloud', 'opportunity.7n.com'].includes(
        window.location.hostname
      )
    ) {
      this.getData();
    }
  }

  getData(accessTokens?: any) {
    this.authenticationService.accessToken.subscribe(async (tokens: any) => {
      this.contextService.accessTokens = tokens;
      const campaign = sessionStorage.getItem('campaign');
      const referrer = sessionStorage.getItem('referrer');
      const signup = sessionStorage.getItem('signup');
      if (campaign && signup) {
        await this.apiService
          .saveGenome(
            '/tracking/register',
            {
              campaign,
              source: referrer ?? '',
            },
            tokens.genome
          )
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.promiseProProfile(tokens)
        .then((response) => {
          if (response) {
            this.contextService.profileData = response.profile;
            this.contextService.myProfileData = response.myProfile;
            this.validationService.setSelected();
            this.contextService.dataLoaded.next('success');
          }
        })
        .catch((err) => {
          this.contextService.dataLoaded.next('error:' + err);
        });
    });
    if (accessTokens) {
      this.promiseProProfile(accessTokens)
        .then((response) => {
          if (response) {
            this.contextService.profileData = response.profile;
            this.contextService.myProfileData = response.myProfile;

            this.contextService.dataLoaded.next('success');
          }
        })
        .catch((err) => {
          this.contextService.dataLoaded.next('error:' + err);
        });
    }
    this.authenticationService.loginDisplay.subscribe((val) => {
      if (!val)
        this.promiseProProfile('')
          .then((response) => {
            if (response) {
              this.contextService.profileData = response.profile;
              this.contextService.dataLoaded.next('successNoToken');
            }
          })
          .catch((err) => {
            console.log(err);
          });
    });
  }

  async promiseProProfile(accessToken: any) {
    this.contextService.dataLoaded.next('fetching');
    // return false
    let profile: any = {
      jobFamily: {},
      technologies: {},
      skills: {},
      industries: {},
    };
    let myProfile: any = {
      genome: {},
      skills: {},
      industries: {},
      assessments: {},
      gameAssessments: {},
    };

    const promiseFamilies = new Promise((resolve) => {
      this.apiService.getSkills('/skills/families').then((response) => {
        resolve(response.data);
      });
    });

    let promiseAvailableskills = {};
    let promiseTech = {};
    let promiseIndustries = {};
    let promiseMySkills = {};
    let promiseMyIndustries = {};
    let promiseMyGeneome = {};
    let promiseMyProfile = {};
    let promiseGameAssessments = {};
    let promiseCommunity = {};
    if (accessToken) {
      promiseGameAssessments = new Promise((resolve) => {
        this.apiService
          .getAssessment('profile/assessmentresults', accessToken.assessments)
          .then((response) => {
            resolve(response.gameInstanceResults);
          })
          .catch(() => {
            resolve([]);
          });
      });

      promiseMySkills = new Promise((resolve) => {
        this.apiService
          .getSkills('/profile/my', accessToken.skills)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
      promiseMyIndustries = new Promise((resolve) => {
        this.apiService
          .getIndustries('/profile/my', accessToken.industries)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
      promiseMyGeneome = new Promise((resolve) => {
        this.apiService
          .getGenome('/genome/my', accessToken.genome)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
      promiseMyProfile = new Promise((resolve) => {
        this.apiService
          .getAssessment('/profile/my', accessToken.assessments)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });

      promiseAvailableskills = new Promise((resolve) => {
        this.apiService
          .getSkills('/skills/availableskills', accessToken.skills)
          .then((response) => {
            resolve(response.data);
          })
          .catch(() => {
            resolve([]);
          });
      });
      promiseTech = new Promise((resolve) => {
        this.apiService
          .getSkills('/skills/technologies', accessToken.skills)
          .then((response) => {
            resolve(response.data);
          })
          .catch(() => {
            resolve([]);
          });
      });
      promiseIndustries = new Promise((resolve) => {
        this.apiService
          .getIndustries('industries', accessToken.industries)
          .then((response) => {
            resolve(response.data);
          })
          .catch(() => {
            resolve([]);
          });
      });
      promiseCommunity = new Promise((resolve) => {
        this.apiService
          .getCommunity('profile/my', accessToken.community)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }

    await Promise.all([
      promiseFamilies,
      promiseAvailableskills,
      promiseTech,
      promiseIndustries,
      promiseMySkills,
      promiseMyIndustries,
      promiseMyGeneome,
      promiseMyProfile,
      promiseGameAssessments,
      promiseCommunity,
    ])
      .then((values) => {
        profile.jobFamily = values[0];
        profile.skills = values[1];
        profile.technologies = values[2];
        profile.industries = values[3];
        myProfile.skills = values[4];
        myProfile.industries = values[5];
        myProfile.genome = values[6];
        myProfile.assessments = values[7];
        myProfile.gameAssessments = values[8];
        myProfile.community = values[9];
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      profile,
      myProfile,
    };
  }

  getValue(): Observable<boolean> {
    return this.routerInfo.asObservable();
  }

  setValue(newValue: boolean): void {
    this.routerInfo.next(newValue);
  }

  steps = new BehaviorSubject<number>(-1);
  changeStep(val: number) {
    this.steps.next(val);
  }
}
