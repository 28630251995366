<div (clickOutside)="foundData = []" class="select">
  <label *ngIf="label">{{label}}</label>
  <div>
    <div class="wrap-select">
      <input class="body1" (blur)="blur($event)" type="search" (focus)="searchChar === 0 ? foundData = data: false"
        [disabled]="!loading" [placeholder]="!loading ? 'Please wait...': placeholder"
        [value]="value && value !== 'NaN' ? value : ''" #input (input)="search($event)" (keydown)="onKeydown($event)" (search)="searchEvent($event)" />
      <i-tabler *ngIf="trailingIcon && !searchString && !value" [name]="trailingIcon"></i-tabler>
    </div>
    <div class="select-dropdown">
      <div tabindex="-1" (keydown)="onKeydown($event)" #select>
        <div (click)="clickSelected(d)" [class]="(keySelected === i ? 'selected' : '')"
          *ngFor="let d of foundData; let i = index">
          <div class="split" *ngIf="split === i && split !== 0 "></div>
          <span *ngFor="let option of options; let i = index">
            <span *ngIf="i != 0">
              -
            </span>
            {{d[option]}}
          </span>
          <span *ngIf="options.length === 0">
            {{d}}
          </span>
        </div>
      </div>
    </div>
  </div>

</div>
