import { Component, Input, OnInit } from '@angular/core';
// import { FormControl } from '@angular/forms'
import {
  languages,
  ruleLength,
  select,
  SelectedPro,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';

import { ContextService } from 'src/services/platform/context.service';
import { dropdown } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  // selectedLanguages = new FormControl('')
  @Input() lang: dropdown[];
  showSearch: boolean = false;
  languages: dropdown[] = [];
  selected: dropdown[] = [];
  ruleLength: ruleLength = {
    levelWork: 1,
  };
  selectedProProfile = this.contextService.selectedProProfile;
  languagesText = this.contextService.languages.language;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}
  ngOnInit(): void {
    this.languages = this.lang;

    this.languages.forEach((x) => {
      if (this.selectedProProfile.languages.includes(x.id!)) {
        this.selected.push(x);
      }
    });
  }

  findLanguage(value: string): dropdown {
    let allLanguage: dropdown[] = JSON.parse(JSON.stringify(this.lang));
    return allLanguage.find((x) => x.countryCode === value)!;
  }

  removeLang(i: number) {
    // let findex = this.selectedProProfile.languages.findIndex((x) => x === id);
    // this.selectedProProfile.languages.splice(findex, 1);
    this.selected.splice(i, 1);
    this.selectedProProfile.languages = this.selected.map((x) => {
      return x.id!;
    });
    this.validateChoice();
  }

  selectedValue(value: dropdown, index?: number) {
    if (index !== undefined) {
      this.selected[index] = value;
      return;
    }
    this.selected.push(value);
    this.selectedProProfile.languages = this.selected.map((x) => {
      return x.id!;
    });
    this.validateChoice();
  }

  validateChoice() {
    this.contextService.validSelection['languages'] = false;
    if (this.selected.length > 0) {
      this.contextService.validSelection['languages'] = true;
    }
    this.platformService.reRender.next('languages');
  }
  search(e: Event) {
    const element = e.currentTarget as HTMLInputElement;
    this.languages = JSON.parse(JSON.stringify(this.lang));
    if (element.value.length > 0) {
      this.languages = this.languages.filter((x) =>
        x.displayName.toLowerCase().includes(element.value.toLowerCase())
      );
    }
  }
}
