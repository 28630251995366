import { Component, Input } from '@angular/core';
import { classes } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() class: classes[] = [];
  @Input() label: string = '';
  @Input() leadingIcon: string = '';
  @Input() trailingIcon: string = '';
  @Input() style: string;
  @Input() disable: boolean;
}
