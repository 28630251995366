import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import axios from 'axios';
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @ViewChild('select') select: ElementRef;
  @ViewChild('input') input: ElementRef;
  @Input() label: string = '';
  @Input() type: string = '';
  @Input() loading: boolean = true;
  @Input() data: any[];
  @Input() foundData: any[];
  @Output() selected = new EventEmitter<any>();
  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() searchChar: number = 0;
  @Input() options: string[] = ['value'];
  @Input() split: number = 0;
  @Input() trailingIcon: string = '';
  @Input() callbackFunction: (cities: any) => void = () => {};
  found: boolean = false;
  searchString: string = '';
  keySelected: number = 0;
  constructor() {
    if (this.searchChar === 0) this.foundData = this.data;
  }

  ngOnInit(): void {
    // if (!this.foundData) {
    //   this.foundData = this.data;
    // }
  }

  onKeydown(e: KeyboardEvent) {
    if (!this.loading) {
      return;
    }
    if (e!.key === 'ArrowDown') {
      if (this.keySelected < this.foundData.length - 1) {
        this.keySelected++;
      }
      this.select.nativeElement.focus();
      e!.preventDefault();
      this.select.nativeElement.scrollTop = this.keySelected * 56;

      return;
    }
    if (e!.key === 'ArrowUp') {
      if (this.keySelected > 0) {
        this.keySelected--;
      }
      this.select.nativeElement.focus();
      e!.preventDefault();
      this.select.nativeElement.scrollTop = this.keySelected * 56;
      return;
    }
    if (e!.key === 'Enter') {
      this.value = '';
      setTimeout(() => {
        this.clickSelected(this.foundData[this.keySelected]);
      }, 100);

      return;
    }
    if (e.key !== 'Backspace') {
      this.value = '';
    }
    this.keySelected = 0;
    this.input.nativeElement.focus();
  }
  searchEvent(event: Event) {
    this.value = '';
  }
  blur(event: Event) {
    if (
      !this.data.some((x) => x.displayName === this.input.nativeElement.value)
    ) {
      this.input.nativeElement.value = '';
      this.selected.emit('');
    }
  }

  clickSelected(data: any) {
    this.selected.emit(data);
    this.found = true;
    this.foundData = [];
    this.keySelected = 0;
    this.searchString = '';
    this.value = data.displayName;
  }

  queryGeoNames(event: Event) {
    const query = event.target as HTMLInputElement;
    if (query.value.length === 0) {
      this.selected.emit('');
      this.foundData = [];
      this.keySelected = 0;
    }
    if (query.value.length > this.searchChar) {
      axios
        .get(
          'https://secure.geonames.net/searchJSON?name_startsWith=' +
            query.value +
            '&featureClass=p&maxRows=50&username=jacobheidelbach'
        )
        .then((response) => {
          this.foundData = response.data.geonames.map((value: any) => {
            const cities = {
              country: value.countryCode,
              name: value.name,
              state: value.adminName1,
            };
            return cities;
          });
        });
    }
  }
  search(e: Event) {
    if (!this.loading) {
      return;
    }
    if (this.type === 'cities') {
      this.queryGeoNames(e);
      return;
    }
    this.foundData = [];
    const element = e.currentTarget as HTMLInputElement;
    this.searchString = element.value;
    if (element.value.length > this.searchChar) {
      this.foundData = this.data
        .filter((x) => {
          let valid = false;
          if (this.options.length > 0) {
            for (let i = 0; i < this.options.length; i++) {
              if (typeof x[this.options[i]] === 'number') {
                x[this.options[i]] = JSON.stringify(x[this.options[i]]);
              }
              valid = x[this.options[i]]
                .toLowerCase()
                .includes(element.value.toLowerCase());
            }
          } else {
            if (typeof x === 'number') {
              x = JSON.stringify(x);
            }
            valid = x.toLowerCase().includes(element.value.toLowerCase());
          }
          return valid;
        })
        .filter(
          (v, i, a) =>
            a.findIndex((v2) => {
              let valid = false;
              if (this.options.length > 0) {
                for (let i = 0; i < this.options.length; i++) {
                  valid = v2[this.options[i]] === v[this.options[i]];
                  if (!valid) {
                    break;
                  }
                }
              } else {
                valid = v2 === v;
              }
              return valid;
            }) === i
        );
    }

    if (element.value.length === 0) {
      this.selected.emit('');
      this.foundData = [];
      this.keySelected = 0;
    }
  }
}
