<app-dialog [languages]="languages" *ngIf="dialog === true" [data]="result" [type]="dialogType" [toEdit]="toEdit"
  (close)="toogleDialog()">
</app-dialog>
<div class="my-profile">
  <div class="top">
  </div>
  <div class="middle" *ngIf="!profileSubmitted">
    {{languages?.myProfile.notSubmitted}}
  </div>
  <div class="middle" *ngIf="!loading && profileSubmitted">
    <div class="profile">
      <h1>{{givinName}} {{familyName}}</h1>
      <p><span *ngFor="let email of  emails">{{email}}</span></p>
    </div>
    <div class="placeholder-profile" *ngIf="!profileComplete">
      <div class="complete-profile">
        <div class="text">
          <h3>Complete your profile </h3>
          <p class="baseline_small">Complete your profile to increase your profile
            strength. That way better and more personalised matches will come your way. </p>
        </div>
        <app-button (click)="profileFlow()" [class]="['primary']" [label]="'Complete my profile'"></app-button>
      </div>
      <img src="./assets/images/dummy profile.png" />
    </div>
    <div *ngIf="profileComplete" class="edit-section">
      <section class="assessments">
        <div class="edit">
          <p class="title"> {{languages.myProfile.game.headline}}</p>
          <p></p>

        </div>
        <div class="cards">

          <div class="first">
            <div *ngFor="let result of gameInstanceResults.first">
              <div *ngFor="let score of result.scores">
                <div>
                  <p class="body2 bold">{{score.traitDisplayName}}</p>
                  <p>{{languages.myProfile.game.assessments.scores}} {{score.scoreExcerpt}}</p>
                </div>
                <div *ngIf="score.strengths.length > 0">
                  <p class="bold">Strengths (1 Of {{score.strengths.length}})</p>
                  <p>{{score.strengths[0]}}</p>
                </div>
                <div *ngIf="score.challenges.length > 0">
                  <p class="bold">Challenges (1 Of {{score.challenges.length}})</p>
                  <p>{{score.challenges[0]}}</p>
                </div>
                <app-button (click)="toogleDialog([result.dialogType ?? 'assessment'], result)" [class]="['secondary']"
                  [label]="score.btn ?? 'Full result'"></app-button>
              </div>
            </div>
          </div>

          <div class="second">
            <div *ngFor="let result of gameInstanceResults.second">
              <div *ngFor="let score of result.scores">
                <div>
                  <p class="body2 bold">{{score.traitDisplayName}}</p>
                  <p>{{languages.myProfile.game.assessments.scores}} {{score.scoreExcerpt}}</p>
                </div>
                <div *ngIf="score.strengths.length > 0">
                  <p class="bold">Strengths (1 Of {{score.strengths.length}})</p>
                  <p>{{score.strengths[0]}}</p>
                </div>
                <div *ngIf="score.challenges.length > 0">
                  <p class="bold">Challenges (1 Of {{score.challenges.length}})</p>
                  <p>{{score.challenges[0]}}</p>
                </div>
                <app-button (click)="toogleDialog([result.dialogType ?? 'assessment'], result)" [class]="['secondary']"
                  [label]="score.btn ?? 'Full result'"></app-button>

              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="body2 bold">{{languages.myProfile.game.refference.bold}}</p>
          <p>{{languages.myProfile.game.refference.basline}}</p>
          <a class="link" href="https://www.equalture.com/science/" target="_blank">Read more</a>
        </div>
      </section>
      <section>
        <div class="edit">
          <p class="title">{{languages.roles.title}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.roles], ['roles', 'all-skills', 'your-top-skills'])"
            class="link">
            Edit section
          </p>
        </div>
        <div>
          <p class="baseline_small">{{languages.roles.experience[0].shortTitle}}</p>
          <div>
            <p *ngFor="let yearExperience of getSelection(proProfile.yearExperience, 'yearExperience'); let last = last"
              class="baseline_xlarge">
              {{yearExperience.displayName}}<span *ngIf="!last">,</span>
            </p>
          </div>
        </div>
        <div>
          <p class="baseline_small">{{languages.roles.profile.headline}}</p>
          <div>
            <p *ngFor="let jobFamily of getSelection(profileData.jobFamily, 'jobFamily'); let last = last"
              class="baseline_xlarge">
              {{jobFamily.displayName}}<span *ngIf="!last">,</span>
            </p>
          </div>
        </div>
        <div>
          <p class="baseline_small">{{languages.allSkills.title}}</p>
          <div>
            <p *ngFor="let skills of getSelection(profileData.skills, 'skills'); let last = last"
              class="baseline_xlarge">
              {{skills.displayName}}<span *ngIf="!last">,</span>
            </p>
          </div>
        </div>
        <div>
          <p class="baseline_small">{{languages.topSkills.title}}</p>
          <div>
            <p *ngFor="let topSkills of getSelection(profileData.skills, 'topSkills'); let last = last"
              class="baseline_xlarge">
              {{topSkills.displayName}}<span *ngIf="!last">,</span>
            </p>
          </div>
        </div>
      </section>
      <section>

        <div class="edit">
          <p class="title">{{languages.sweetspot.title}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.sweetspot], [stepsMenuSlug.sweetspot])"
            class="edit link">
            Edit section
          </p>
        </div>
        <div>
          <div class="sweet-spot">
            <!-- <div *ngFor="let skill of proProfile.sweetSpot; let i = index">
              <p class="body2">{{skill.displayName}}</p>
              <div>
                <div class="counter">
                  <p class="baseline_small">{{selectedProfile.sweetSpot[i]}}/100</p>
                </div>
                <mat-slider [disabled]="true" class="black" [(ngModel)]="selectedProfile.sweetSpot[i]"
                  aria-label="unit(s)">
                </mat-slider>
                <div class="start-end">
                  <p>{{languages.sweetspot.title.not}}</p>
                  <p>{{languages.sweetspot.title.very}}</p>
                </div>
              </div>
            </div>
          </div> -->
            <app-sweet-spot [disabled]="true"></app-sweet-spot>
          </div>
        </div>
      </section>
      <!-- <section>

        <div class="edit">
          <p class="title">{{languages.experience.headline}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.experience], [stepsMenuSlug.experience])"
            class="edit link">
            Edit section
          </p>
        </div>

        <div>
          <p class="baseline_small">{{languages.experience.industries.headline}}</p>
          <div>
            <p *ngFor="let industries of selectedIndustries.industries; let last = last"
              [class]="{'border' :!last, 'industries': true, 'baseline_xlarge': true}">
              <span>
                {{industries.displayName}}
              </span>
              <span>

                {{industries.selected?.experience}}
              </span>
            </p>
          </div>
        </div>
      </section> -->
      <section>
        <div class="edit">
          <p class="title">{{languages.experience.headline}}</p>
          <p *ngIf="selectedJobHistory.length > 0"
            (click)="editSection(['edit-section', stepsMenuSlug.experience], [stepsMenuSlug.experience])"
            class="edit link">
            Edit section
          </p>
        </div>

        <div id="experience" class="job-history">
          <div *ngIf="selectedJobHistory.length > 0">
            <ng-container *ngFor="let jobHistory of selectedJobHistory; let last = last">
              <div [class]="{'current': jobHistory.current}" class="each-history">
                <div class="current-label">
                  <p class="baseline_small">Current position</p>
                </div>
                <div>
                  <div class="column left">
                    <div class="column">
                      <p class="baseline_small">Role</p>
                      <p class="baseline_xlarge">
                        {{jobHistory.role}}
                      </p>
                    </div>
                    <div class="row">
                      <div class="column">
                        <p class="baseline_small">Company</p>
                        <p class="baseline_xlarge">
                          {{jobHistory.company}}
                        </p>
                      </div>
                      <div class="column">
                        <p class="baseline_small">Industry</p>
                        <p class="baseline_xlarge">
                          {{getIndustry(jobHistory.industryId)}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column right">
                    <div class="column">
                      <p class="baseline_small">Start</p>
                      <p class="baseline_xlarge">
                        {{getMonthYear(jobHistory.start)}}
                      </p>
                    </div>
                    <div *ngIf="jobHistory.end" class="column">
                      <p class="baseline_small">End</p>
                      <p class="baseline_xlarge">
                        {{getMonthYear(jobHistory.end)}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="no-job-history" *ngIf="selectedJobHistory.length === 0">
            <div>
              <p class="body2">Add your job history</p>
              <p class="baseline_small">You can now add your job history and get better matched to future jobs</p>
            </div>
            <div>
              <app-button (click)="editSection(['edit-section', stepsMenuSlug.experience], [stepsMenuSlug.experience])"
                [class]="['primary']" [label]="'Add job history'"></app-button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="edit">
          <p class="title">{{languages.allSkills.title}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.technologies], [stepsMenuSlug.technologies])"
            class="edit link">
            Edit section
          </p>
        </div>
        <div *ngFor="let family of familyChunk(profileData.technologies, 'technologies')">

          <p class="baseline_small">{{family}}</p>
          <div>
            <ng-container
              *ngFor="let technologies of getSelection(profileData.technologies, 'technologies'); let last = last">
              <p *ngIf="family === technologies.family" class="baseline_xlarge">{{technologies.displayName}},</p>
            </ng-container>
          </div>

        </div>
      </section>
      <section>
        <div class="edit">
          <p class="title">{{languages.language.headline}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.languages], [stepsMenuSlug.languages])"
            class="edit link">
            Edit section
          </p>
        </div>
        <div>
          <div>
            <p *ngFor="let lang of getLanguages(); let last = last" class="baseline_xlarge">
              {{lang.displayName}}<span *ngIf="!last">,</span>
            </p>
          </div>
        </div>
      </section>
      <section>
        <div class="edit">
          <p class="title">{{languages.location.location.title}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.location], [stepsMenuSlug.location])"
            class="edit link">
            Edit section
          </p>
        </div>
        <div>
          <p class="baseline_small">{{languages.location.location.title}}</p>
          <div>
            <p class="baseline_xlarge">
              {{city}}
            </p>
          </div>
        </div>
        <div class="row">
          <span>
            <p class="baseline_small">{{languages.location.work.headline}}</p>
            <div>
              <p *ngFor="let workFrom of getSelection(proProfile.workFrom, 'workFrom'); let last = last"
                class="baseline_xlarge">
                {{workFrom.displayName}}<span *ngIf="!last">,</span>
              </p>
            </div>
          </span>
          <span>
            <p class="baseline_small">{{languages.income.available.headline}}</p>
            <div>
              <p class="baseline_xlarge">
                {{startDate}}
              </p>
            </div>
          </span>
        </div>
      </section>
      <section>
        <div class="edit">
          <p class="title">{{languages.income.salary.title}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.income], [stepsMenuSlug.income])" class="edit link">
            Edit section
          </p>
        </div>
        <div>
          <p class="baseline_small"> {{languages.experience.workPref.title}}</p>
          <div>
            <p class="baseline_xlarge">
              {{selectedProfile.asConsultant[0] === 2 ? "No": "Yes"}}
            </p>
          </div>
        </div>
        <div>
          <p class="baseline_small">{{languages.income.salary.expected}}</p>
          <span>
            <div>
              <p class="baseline_xlarge">
                {{selectedSalary.salary}}
                {{selectedSalary.currency}}
                {{getSelection(proProfile.rate, 'rate')[0].displayName}}
              </p>
            </div>
          </span>

        </div>
      </section>
      <section>
        <div class="edit">
          <p class="title">{{languages.professional.title}}</p>
          <p (click)="editSection(['edit-section', stepsMenuSlug.profPro], [stepsMenuSlug.profPro])" class="edit link">
            Edit section
          </p>
        </div>
        <div>
          <div class="prof-profile">
            <ng-container *ngFor="let profile of getProfile(proProfile.profile, 'profile'); let last = last">
              <ng-container *ngIf="profile.value!.length > 0">
                <div>
                  <p class="baseline_small">
                    {{profile.displayName}}
                  </p>
                  <a [href]="profile.value" target="_blank" class="baseline_xlarge link">
                    {{profile.value}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
