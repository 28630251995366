<div class="background"></div>
<div class="apply-here">
  <div class="content">
    <div class="text">
      <ng-container *ngFor="let item of applyText">
        <h1 *ngIf="getKey(item) === 'h1'">{{item.h1}}</h1>
        <h2 *ngIf="getKey(item) === 'h2'">{{item.h2}}</h2>
        <p *ngIf="getKey(item) === 'p'">{{item.p}}</p>
        <div *ngIf="getKey(item) === 'p_newline'">
          <p *ngFor="let newLine of item.p_newline">
            <ng-container *ngIf="getKey(newLine) === 'p'">
              <p>{{newLine.p}}</p>
            </ng-container>
            <ng-container *ngIf="getKey(newLine) === 'i'"><i>{{newLine.i}}</i></ng-container>
          </p>
        </div>
        <div *ngIf="getKey(item) === 'i_newline'">
          <p *ngFor="let newLine of item.i_newline">
            <i>{{newLine}}</i>
          </p>
        </div>
        <ul *ngIf="getKey(item)=== 'li'">
          <li *ngFor="let li of item.li">
            {{li}}
          </li>
        </ul>
        <app-button (click)="signup()" *ngIf="getKey(item) === 'btn'" [class]="['primary']"
          [label]="item.btn"></app-button>
      </ng-container>

    </div>
  </div>
</div>
