<div class="month-year-select">
  <div (clickOutside)="closePicker();">
    <app-input [disabled]="disabled" [trailingIcon]="trailingIcon" [label]="label" (blur)="setSelectedDate(true, $event)" (click)="openPicker()" [value]="monthYear"
      [placeholder]="placeholder" />

    <!-- <input (blur)="setSelectedDate(true, $event)" (click)="openPicker()" [value]="monthYear" [placeholder]="placeholder"/> -->
    <div *ngIf="selectedPicker.index === index && selectedPicker.type === type" class="dropDown">
      <div class="year">
        <div (click)="changeYear(-1)"><i-tabler [name]="'chevron-left'"></i-tabler></div>
        <p class="label2">{{year}}</p>
        <div (click)="changeYear(1)"><i-tabler [name]="'chevron-right'"></i-tabler></div>
      </div>
      <div class="months">
        <div [class]="{'active': selectedDate.getMonth() === i && value}" (click)="selectMonth(i)" class="month"
          *ngFor="let month of months; let i = index">
          <p class="label2">{{month}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
