<div #heading [class]="'topbar '+ (!openSidePanel ? 'close' : 'open')">
  <div class="top">
    <app-logo *ngIf="counter.length === 0"  [back]="false"></app-logo>
    <div class="counter label2">
      <div *ngIf="counter.length > 0" >
        {{counter.current}}
        <span>/ {{counter.length}}</span>
      </div>
    </div>
    <div (click)=" toggleSide()" class="mobile-menu">
      <i-tabler class="menu-2" name="menu-2"></i-tabler>
      <i-tabler class="times" name="X"></i-tabler>
    </div>
  </div>
  <div class="wrap-greeting">
    <div class="agent">
      <div>
        <!-- <div class="balloon-name balloon">{{agent.name}}</div> -->
        <img src="../../../assets/images/agent.png" />
      </div>
    </div>
    <div class="greeting">
      <h3 class="subtitle1">{{languages.headline}}</h3>
      <p class="body2">
        {{languages.description}}
      </p>
    </div>
  </div>
</div>
