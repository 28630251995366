<div class="wrap-selection">

  <div class="selection">
    <div class="submit">
      <div class="agent">
        <img src="../../../../assets/images/agent.png" />
      </div>
      <ng-container *ngIf="!sevenNConsulatant">
        <h1 class="title3">{{missing.length === 0 ? languages.new.ready.headline : languages.new.notReady.headline}}</h1>
        <p class="body2">{{missing.length === 0 ? languages.new.ready.description :
          languages.new.notReady.description}}
        </p>
      </ng-container>
      <ng-container *ngIf="sevenNConsulatant">
        <h1 class="title3">{{missing.length === 0 ? languages.existing.ready.headline : languages.existing.notReady.headline}}</h1>
        <p class="body2">{{missing.length === 0 ? languages.existing.ready.description :
          languages.existing.notReady.description}}
        </p>
      </ng-container>
    </div>
    <div class="missing">
      <div *ngFor="let step of missing; let i = index">
        {{step.id}}. {{step.displayName}}
      </div>
    </div>
  </div>
</div>
