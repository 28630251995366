<div (clickOutside)="toggleDropdown(false)" class="list">
  <label *ngIf="label" class="label2">{{label}}</label>
  <div>
    <div class="search-input body1">
      <div tabindex="0" [class]="{'active': dropdown}" class="input" #input (click)="toggleDropdown(true)">
        <i-tabler *ngIf="leadingIcon" [name]="leadingIcon"></i-tabler>
        <div [attr.placeholder]="placeholder" [innerText]="getSelected()" (input)="search($event)" class="innerText"
          [class]="{'trailingIcon':trailingIcon, 'leadingIcon': leadingIcon}">
        </div>
        <i-tabler *ngIf="trailingIcon" [name]="trailingIcon"></i-tabler>
      </div>
    </div>
    <div *ngIf="dropdown" #dropdownList class="dropdown label2">
      <div *ngFor="let option of options" (click)="selectDropdown(option)" [attr.name]="option.displayName">
        {{option.displayName}}<span>{{option.trailingInfo ? option.trailingInfo: ''}}</span>
      </div>
    </div>
  </div>
</div>
