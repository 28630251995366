<div class="welcome">
  <div>
    <div class="agent">
      <img src="../../../assets/images/agent.png" />
    </div>
    <h1 class="title3">{{languages.headline}}</h1>
    <p class="body1">{{languages.description}} </p>
  </div>
  <app-button [style]="'min-width: 168;'" (click)="next()" [class]="['primary']" [label]="languages.cta"
    trailingIcon="chevron-right"> </app-button>
</div>
