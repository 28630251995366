export default [
  {
    postslug: '7N20-Senior-Backend-Software-Engineer-Ontario-Canada',
    text: [
      {
        h1: 'Senior Backend Software Engineer',
      },
      {
        p_newline: [
          { i: 'Applicants must be eligible to work in Canada.' },
          { p: 'Location: Remote / Hybrid in Ontario, Canada.' },
        ],
      },
      {
        p: 'Our client, a global technology company, are looking for another fast-paced, ambitious, informal and nerdy Senior Backend Software Engineer to join their engineering team.',
      },

      {
        p: 'You will play a key role in developing and implementing architectural plans and initiatives needed to achieve the organizations strategic goals. You will join one of the feature teams and here develop the team and the individuals in the team based on your experience and motivation.',
      },

      {
        p: 'Our client focus on continuous integration and deployment practices and you will be delving into different projects, and always be on the lookout for new innovative ways to approach tasks using the latest tools and cutting-edge technologies.',
      },

      {
        h2: 'You can expect to:',
      },

      {
        li: [
          'Mentor your team on day-to-day tasks and approaches.',

          'Grow as a person by being part of a software platform scaling journey.',

          'Improve and scale our platform as we continue to grow our business.',

          'Help your colleagues to become better by using your seniority and mentoring skills.',

          'Spark creativity in design and planning sessions and think out of the box to meet customer needs.',

          'Be heard and strive to make an impact, because your projects are your responsibility.',
        ],
      },

      {
        h2: 'Who are you:',
      },

      {
        li: [
          'You have experience from software position(s) ideally in SaaS or at an early stage start-up.',

          'You speak Java fluently and have experience with specifying and developing REST APIs using Spring Boot or similar frameworks.',

          'You take pride in writing well-designed, tested code, and peer code review is part of your way-of-working.',

          'You have experience or are motivated to learn open-source, scale-out platforms/technologies such as Kafka, Flink, Cassandra, Kubernetes, SQL, PostgreSQL, and Redis as well as metrics/monitoring solutions such as Prometheus, Grafana, and Humio.',

          'You have experience within CI/CD and might know one or more of these tools/frameworks: Ansible, Terraform, Docker, and CircleCI.',

          'You have a degree in Computer Science, Information Sciences, Software Development, or related, could also be that you are self-taught with a portfolio of running software.',
        ],
      },

      {
        p: 'Below you can apply for the job by signing up to the 7N platform (a digital sign-up and assessment solution) and submitting your profile. Afterwards a 7N agent will reach out to you.',
      },
      {
        btn: 'Apply here',
      },
      {
        p: '7N is a flexible IT consultancy with a global network of more than 1,600 extraordinary IT people who work daily for our 160+ clients to solve critical IT tasks and deliver digital success. For more than 30 years, 7N has built a global network of extraordinary IT people – delivering on our clients’ objectives and beyond. Today, our 300+ employees dedicate themselves to finding the right match between our consultants and the companies we serve. As an IT consultant with 7N, you receive a personal agent from the very start. We connect you with the most exciting projects related to your field of expertise, help you develop your skills and expand your toolbox, and make you part of a social and professional community of extraordinary people.',
      },
    ],
  },
  {
    postslug: '7N20-Full-Stack-Java-Developer-Piscataway',

    text: [
      {
        h1: 'Full Stack Java Developer',
      },

      {
        p_newline: [
          { i: 'Applicants must be eligible to work in USA.' },
          { p: 'Location: Hybrid remote in Piscataway, NJ.' },
        ],
      },
      {
        p: 'The overall purpose of this position is to support software development initiatives and operational tasks for our client, with a focus on the underlying authentication/authorization component. The ideal candidate would be a strong Java developer with excellent database skills, competence with Linux command line and scripting, and prior experience or an interest in application security features and authentication methods.',
      },

      {
        p: 'The position has no direct reports and works under moderate supervision within a fast-paced Agile team. While we are looking for a self-starter with strong analytical and programming skills, the role offers excellent opportunities for learning and growth for the right candidate.',
      },

      {
        h2: 'Key Responsibilities:',
      },

      {
        li: [
          'Develops process workflow, and custom Java/J2EE web applications which includes design, hands-on implementation, and maintenance/support for full stack development.',

          'Participates in the development efforts for all improvements, upgrades and support for the access and authentication system.',

          'Responsible for maintaining high quality of deliverables by conducting unit testing, integration/system testing, regression testing, production validation, and post production support.',

          'Creates and maintains software documentation.',

          'Responsible for maintaining high quality of deliverables by conducting unit testing, integration/system testing, regression testing, production validation, and post production support.',

          'Keeps security best practices at the forefront in all design and development activities.',

          'Works closely with the Business Stakeholders & Other Technical Stakeholder teams.',

          'Reports progress and activity to Manager and/or Project Lead on a continuing basis.',

          'Stays current with evolving technology trend',
        ],
      },

      {
        h2: 'Qualifications:',
      },

      {
        li: [
          '2-4 years of related work experience required.',

          'At least 3 years of analysis, design, and application development expertise.',

          'Hands-on experience with Java/J2EE development and Linux using various commands and utilities is required.',

          'Hands-on development experience using RDBMS/SQL (e.g./ MySQL).',

          'Hands-on experience with Spring/Spring Boot framework.',

          'Experience in implementation and integration with web services (SOAP/REST based, JSON, XML) required.',

          'Hands-on development experience using RDBMS/SQL (e.g./ MySQL) and NoSQL databases (e.g./ MongoDB).',

          'Hands-on experience with implementing Front-end applications using JavaScript (jQuery/Angular/ReactJS), HTML5, CSS3 technologies, responsive web design preferred.',

          'Experience with e-Commerce applications, Content Management Systems (e.g./ Adobe Experience Manager), process workflow applications/platforms is preferred.',

          'Proficient understanding of code versioning/integration/deployment tools (e.g./ SVN, GIT, Maven, Bamboo).',

          'Must have effective communication skills both written and verbal required to effectively present ideas and concepts to the user community/stakeholders/technical personnel.',

          'Ability to work as part of a team as well as with all levels of management.',

          'Experience with project life cycle, including design, development, testing, UAT, and production support.',

          'Good knowledge of Agile and Waterfall methodologies.',

          'Java SE / Java EE Certification preferred.',

          'Bachelors degree or equivalent experience or higher in Computer Science or related field preferred or equivalent experience required.',
        ],
      },

      {
        p: 'Below you can apply for the job by signing up to the 7N platform (a digital sign-up and assessment solution) and submitting your profile. Afterwards a 7N agent will reach out to you. ',
      },
      {
        btn: 'Apply here',
      },
      {
        p: '7N is a flexible IT consultancy with a global network of more than 1,600 extraordinary IT people who work daily for our 160+ clients to solve critical IT tasks and deliver digital success. For more than 30 years, 7N has built a global network of extraordinary IT people – delivering on our clients’ objectives and beyond. Today, our 300+ employees dedicate themselves to finding the right match between our consultants and the companies we serve. As an IT consultant with 7N, you receive a personal agent from the very start. We connect you with the most exciting projects related to your field of expertise, help you develop your skills and expand your toolbox, and make you part of a social and professional community of extraordinary people.',
      },
    ],
  },

  {
    postslug: '7N20-Dev-Ops-Engineer-Poland1',

    text: [
      {
        h1: 'DevOps Engineer',
      },

      {
        h2: 'Overview',
      },

      {
        p: 'We are looking for a talented and experienced DevOps Engineer to join our team at the most innovative bank in Poland. The successful candidate will be responsible for automating the deployment and monitoring processes, and supporting the development team with their infrastructure needs. The ideal candidate will have experience in both development and operations and will be open to learn cloud technologies.',
      },

      {
        p_newline: [{ p: 'Expected salary: 140-180zł/h net' }],
      },

      {
        h2: 'Responsibilities:',
      },

      {
        li: [
          'Developing automation and deployment processes for applications and services.',

          'Implementing monitoring and alerting tools to ensure system availability and performance.',

          'Collaborating with development teams to ensure smooth deployment and operation of their applications.',

          'Managing and maintaining production and non-production environments.',

          'Providing technical support for infrastructure-related issues.',
        ],
      },

      {
        h2: 'Requirements:',
      },

      {
        li: [
          'Strong experience in DevOps practices and methodologies.',

          'Proficiency in scripting languages such as Bash, Python or PowerShell.',

          'Familiarity with containerization technologies such as Docker and Kubernetes.',

          'Experience with infrastructure-as-code tools such as Terraform or Ansible.',

          'Good understanding of networking and security principles.',

          'Familiarity with source code management tools such as Git.',

          'Ability to work in a team and excellent communication skills.',

          'Fluent Polish language skills.',
        ],
      },

      {
        h2: 'Nice to have:',
      },

      {
        li: ['Experience with cloud technologies (GCP, Azure).'],
      },

      {
        p: 'Below you can apply for the job by signing up to the 7N platform (a digital sign-up and assessment solution) and submitting your profile. Afterwards a 7N agent will reach out to you.',
      },

      {
        btn: 'Apply here',
      },

      {
        p: '7N is a flexible IT consultancy with a global network of more than 1,600 extraordinary IT people who work daily for our 160+ clients to solve critical IT tasks and deliver digital success. For more than 30 years, 7N has built a global network of extraordinary IT people – delivering on our clients’ objectives and beyond. Today, our 300+ employees dedicate themselves to finding the right match between our consultants and the companies we serve. As an IT consultant with 7N, you receive a personal agent from the very start. We connect you with the most exciting projects related to your field of expertise, help you develop your skills and expand your toolbox, and make you part of a social and professional community of extraordinary people.',
      },
    ],
  },
  {
    postslug: '7N20-Dev-Ops-Engineer-Poland2',

    text: [
      {
        h1: 'DevOps Engineer',
      },

      {
        h2: 'Overview',
      },

      {
        p: 'We are looking for a talented and experienced DevOps Engineer to join our team at the most innovative bank in Poland. The successful candidate will be responsible for automating the deployment and monitoring processes, and supporting the development team with their infrastructure needs. The ideal candidate will have experience in both development and operations and will be open to learn cloud technologies.',
      },

      {
        h2: 'Responsibilities:',
      },

      {
        li: [
          'Developing automation and deployment processes for applications and services.',

          'Implementing monitoring and alerting tools to ensure system availability and performance.',

          'Collaborating with development teams to ensure smooth deployment and operation of their applications.',

          'Managing and maintaining production and non-production environments.',

          'Providing technical support for infrastructure-related issues.',
        ],
      },

      {
        h2: 'Requirements:',
      },

      {
        li: [
          'Strong experience in DevOps practices and methodologies.',

          'Proficiency in scripting languages such as Bash, Python or PowerShell.',

          'Familiarity with containerization technologies such as Docker and Kubernetes.',

          'Experience with infrastructure-as-code tools such as Terraform or Ansible.',

          'Good understanding of networking and security principles.',

          'Familiarity with source code management tools such as Git.',

          'Ability to work in a team and excellent communication skills.',

          'Fluent Polish language skills.',
        ],
      },

      {
        h2: 'Nice to have:',
      },

      {
        li: ['Experience with cloud technologies (GCP, Azure).'],
      },

      {
        p: 'Below you can apply for the job by signing up to the 7N platform (a digital sign-up and assessment solution) and submitting your profile. Afterwards a 7N agent will reach out to you.',
      },

      {
        btn: 'Apply here',
      },

      {
        p: '7N is a flexible IT consultancy with a global network of more than 1,600 extraordinary IT people who work daily for our 160+ clients to solve critical IT tasks and deliver digital success. For more than 30 years, 7N has built a global network of extraordinary IT people – delivering on our clients’ objectives and beyond. Today, our 300+ employees dedicate themselves to finding the right match between our consultants and the companies we serve. As an IT consultant with 7N, you receive a personal agent from the very start. We connect you with the most exciting projects related to your field of expertise, help you develop your skills and expand your toolbox, and make you part of a social and professional community of extraordinary people.',
      },
    ],
  },
  {
    postslug: '7N20-Full-Stack-Developer-Poland1',

    text: [
      {
        h1: 'Fullstack .NET/Angular Developer',
      },

      {
        h2: 'Overview',
      },

      {
        p: 'We are looking for a Fullstack .NET Developer with experience in Angular to join our SCRUM team at the most innovative bank in Poland. The successful candidate will be responsible for creating and maintaining web applications that support key business processes for the bank. The ideal candidate will have experience working with Microsoft .NET technologies and skills in designing and implementing web applications using Angular.',
      },

      {
        p_newline: [{ p: 'Expected salary: 140-190zł/h net' }],
      },

      {
        h2: 'Responsibilities:',
      },

      {
        li: [
          'Designing and implementing web applications using .NET and Angular technologies.',

          'Maintaining and enhancing existing web applications.',

          'Collaborating with other developers, testers, and analysts to ensure high-quality code and application usability.',

          'Troubleshooting issues related to web applications and implementing new features.',

          'Documenting projects and code.',
        ],
      },

      {
        h2: 'Requirements:',
      },

      {
        li: [
          'Experience in designing and implementing web applications using .NET and Angular technologies.',

          'Proficiency in C# and TypeScript.',

          'Experience working with SQL databases.',

          'Knowledge of HTML, CSS, and JavaScript.',

          'Ability to work with version control systems such as Git.',

          'Ability to work in a team.',

          'Fluent Polish language skills.',
        ],
      },

      {
        p: 'Below you can apply for the job by signing up to the 7N platform (a digital sign-up and assessment solution) and submitting your profile. Afterwards a 7N agent will reach out to you.',
      },

      {
        btn: 'Apply here',
      },

      {
        p: '7N is a flexible IT consultancy with a global network of more than 1,600 extraordinary IT people who work daily for our 160+ clients to solve critical IT tasks and deliver digital success. For more than 30 years, 7N has built a global network of extraordinary IT people – delivering on our clients’ objectives and beyond. Today, our 300+ employees dedicate themselves to finding the right match between our consultants and the companies we serve. As an IT consultant with 7N, you receive a personal agent from the very start. We connect you with the most exciting projects related to your field of expertise, help you develop your skills and expand your toolbox, and make you part of a social and professional community of extraordinary people.',
      },
    ],
  },
  {
    postslug: '7N20-Full-Stack-Developer-Poland2',
    text: [
      {
        h1: 'Fullstack .NET/Angular Developer',
      },

      {
        h2: 'Overview',
      },

      {
        p: 'We are looking for a Fullstack .NET Developer with experience in Angular to join our SCRUM team at the most innovative bank in Poland. The successful candidate will be responsible for creating and maintaining web applications that support key business processes for the bank. The ideal candidate will have experience working with Microsoft .NET technologies and skills in designing and implementing web applications using Angular.',
      },

      {
        h2: 'Responsibilities:',
      },

      {
        li: [
          'Designing and implementing web applications using .NET and Angular technologies.',

          'Maintaining and enhancing existing web applications.',

          'Collaborating with other developers, testers, and analysts to ensure high-quality code and application usability.',

          'Troubleshooting issues related to web applications and implementing new features.',

          'Documenting projects and code.',
        ],
      },

      {
        h2: 'Requirements:',
      },

      {
        li: [
          'Experience in designing and implementing web applications using .NET and Angular technologies.',

          'Proficiency in C# and TypeScript.',

          'Experience working with SQL databases.',

          'Knowledge of HTML, CSS, and JavaScript.',

          'Ability to work with version control systems such as Git.',

          'Ability to work in a team.',

          'Fluent Polish language skills.',
        ],
      },

      {
        p: 'Below you can apply for the job by signing up to the 7N platform (a digital sign-up and assessment solution) and submitting your profile. Afterwards a 7N agent will reach out to you.',
      },

      {
        btn: 'Apply here',
      },

      {
        p: '7N is a flexible IT consultancy with a global network of more than 1,600 extraordinary IT people who work daily for our 160+ clients to solve critical IT tasks and deliver digital success. For more than 30 years, 7N has built a global network of extraordinary IT people – delivering on our clients’ objectives and beyond. Today, our 300+ employees dedicate themselves to finding the right match between our consultants and the companies we serve. As an IT consultant with 7N, you receive a personal agent from the very start. We connect you with the most exciting projects related to your field of expertise, help you develop your skills and expand your toolbox, and make you part of a social and professional community of extraordinary people.',
      },
    ],
  },
];
