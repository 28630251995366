import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';
import {
  IconChevronRight,
  IconChevronDown,
  IconChevronLeft,
  IconChevronUp,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconInbox,
  IconPlus,
  IconX,
  IconHome,
  IconBriefcase,
  IconFilter,
  IconUserCircle,
  IconCheck,
  IconMoodSmile,
  IconExternalLink,
  IconAddressBook,
  IconLogout,
  IconLogin,
  IconSearch,
  IconInfoCircleFilled,
  IconTrash,
  IconCalendar,
  IconWorld,
  IconBrandLinkedin,
  IconBrandDribbble,
  IconBrandGithub,
  IconDots,
  IconExchange,
  IconMapPin,
  IconTopologyStarRing3,
  IconArrowRight,
  IconClockHour3,
  IconBalloon,
  IconCreditCard,
  IconCopy,
  IconSend,
  IconAt,
  IconPhone,
  IconPuzzle,
  IconMenu2
} from 'angular-tabler-icons/icons';

// Select some icons (use an object, not an array)
const icons = {
  IconTopologyStarRing3,
  IconExchange,
  IconChevronRight,
  IconChevronDown,
  IconChevronLeft,
  IconChevronUp,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconInbox,
  IconPlus,
  IconX,
  IconHome,
  IconBriefcase,
  IconFilter,
  IconUserCircle,
  IconCheck,
  IconMoodSmile,
  IconExternalLink,
  IconAddressBook,
  IconLogout,
  IconLogin,
  IconSearch,
  IconInfoCircleFilled,
  IconTrash,
  IconMapPin,
  IconCalendar,
  IconWorld,
  IconBrandLinkedin,
  IconBrandDribbble,
  IconBrandGithub,
  IconDots,
  IconArrowRight,
  IconClockHour3,
  IconBalloon,
  IconCreditCard,
  IconCopy,
  IconSend,
  IconAt,
  IconPhone,
  IconPuzzle,
  IconMenu2
};
@NgModule({
  imports: [
    CommonModule,
    TablerIconsModule.pick(icons)
  ],
  exports: [
    TablerIconsModule
  ]
})

export class IconsModule {}
