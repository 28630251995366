import { Component, OnInit } from '@angular/core';
import { type } from 'src/ts/interfaces/profile.interface';
const countrycode = require('src/assets/countrycode.json');

@Component({
  selector: 'app-design-system',
  templateUrl: './design-system.component.html',
  styleUrls: ['./design-system.component.scss'],
})
export class DesignSystemComponent implements OnInit {
  countrycode = countrycode.sort((a: any, b: any) => {
    if (a.icon > b.icon) {
      return 1;
    }
    if (a.icon < b.icon) {
      return -1;
    }
    return 0;
  });

  selected: number[] = [];
  levelWork = [
    {
      id: 1,
      displayName: 'Title',
      description: 'Description',
    },
    {
      id: 2,
      displayName: 'Title',
      description: 'Description',
    },
  ];
  levelWork1 = [
    {
      id: 11,
      displayName: 'Title',
      description: 'Description',
    },
    {
      id: 22,
      displayName: 'Title',
      description: 'Description',
    },
  ];
  industries = [
    {
      id: 3,
      displayName: 'Title',
    },
    {
      id: 4,
      displayName: 'Title',
    },
  ];
  industries1 = [
    {
      id: 33,
      displayName: 'Title',
    },
    {
      id: 44,
      displayName: 'Title',
    },
  ];
  industries2 = [
    {
      id: 333,
      displayName: 'Title',
    },
    {
      id: 444,
      displayName: 'Title',
    },
  ];

  list: list = {
    title: 'Available on',
    icon: 'chevron-right',
    type: 'available',
    selected: [],
    input: '',
    default: true,
    placeHolder: 'Availability',
  };

  selectedValue(event: { type: string; date: string; index: number }) {
    // this.list.selected.push(value);
  }
  ngOnInit(): void {}
  callback = (id: number, type: keyof type, single?: boolean): void => {
    if (single && this.selected.length > 0) {
      this.selected = [];
    }
    const findex = this.selected.findIndex((x) => x === id);
    if (findex > -1) {
      this.selected.splice(findex, 1);
      return;
    }
    this.selected.push(id);
  };
}
type list = {
  selected: string[];
  title: string;
  icon: string;
  type: string;
  input: string;
  default: boolean;
  placeHolder: string;
};
