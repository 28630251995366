import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
  faCircle,
  faCircleNodes,
  faEllipsis,
  faHome,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';
import { environment } from '../../environments/environment';
import { nav } from 'src/ts/interfaces/profile.interface';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  loginDisplay = this.authenticationService.loginDisplay;
  openSidePanel = true;
  faHome = faHome;
  selectedNav = (sessionStorage.getItem('dashboard') ?? 'home').split('?')[0];
  showPageName = false;
  givinName: string | unknown;
  loading = true;
  hasAccessToCommunity: boolean = false;
  approved = false;
  @HostListener('scroll', ['$event'])
  onScroll(e: Event): void {
    const element = e.currentTarget as HTMLInputElement;
    if (element.scrollTop >= 73) {
      this.showPageName = true;
    } else {
      this.showPageName = false;
    }
  }
  navs: nav[] = [];
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private helperFunctions: HelperFunctionsService,
    private platformService: PlatformService,
    private authService: MsalService,
    public contextService: ContextService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const arr = val.url.split('/');
        this.selectedNav = arr[arr.length - 1].split('?')[0];
        this.selectedNav =
          this.selectedNav === 'dashboard' ? 'home' : this.selectedNav;
        sessionStorage.setItem('dashboard', this.selectedNav);
      }
    });
    this.openSidePanel = window.innerWidth >= 1164;
    this.givinName =
      this.authService.instance.getActiveAccount()?.idTokenClaims![
        'given_name'
      ];

    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        this.hasAccessToCommunity =
          this.contextService.myProfileData.community.hasAccessToCommunity;
        const status = this.contextService.myProfileData.genome.status;
        this.approved = status === 'Approved';
        let activateProfile = false;
        if (
          ['Approved', 'NewLead', 'PendingInterview', 'Rejected'].includes(
            status
          )
        ) {
          activateProfile = true;
        }

        this.navs = [
          {
            state: false,
            slug: this.helperFunctions.slugify('Home'),
            displayName: 'Home',
            icon: 'home',
          },
          {
            state: false,
            slug: this.helperFunctions.slugify('Profile'),
            displayName: 'Profile',
            icon: 'address-book',
            disabled: !activateProfile,
          },
          {
            state: false,
            slug: this.helperFunctions.slugify('Community'),
            displayName: 'Community',
            icon: 'topology-star-ring-3',
            disabled: !this.hasAccessToCommunity,
          },
        ];

        this.loading = false;
      }
    });
  }

  getFrendlyName(selectedNavn: string) {
    return this.navs.find((x) => x.slug === selectedNavn)?.displayName;
  }
  navigation(slug: string) {
    if (window.innerWidth <= 1164) {
      this.openSidePanel = !this.openSidePanel;
    }
  }
  toggleSide() {
    this.openSidePanel = !this.openSidePanel;
  }
  ngOnInit(): void {
    window.onresize = () => {
      this.openSidePanel = window.innerWidth >= 1164;
    };
  }
  step(value: string) {
    this.selectedNav = value;
  }
  signin() {
    this.authenticationService.signin();
  }
  signout() {
    this.authenticationService.signout();
  }
}
