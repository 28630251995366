import { Component, OnInit } from '@angular/core';
import {
  ProProfile,
  ruleLength,
  SelectedPro,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss'],
})
export class TechnologiesComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  technologies = this.contextService.profileData.technologies;
  selectedProProfile = this.contextService.selectedProProfile;
  selectedTechnologies: ProProfile[] = [];
  ruleLength: ruleLength = {
    technologies: 1,
  };
  familyChunk: string[] = [];
  languages = this.contextService.languages.technologies;
  devider: any[] = [];
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {
    this.technologies.forEach((tech) => {
      if (!this.familyChunk.includes(tech.family!))
        this.familyChunk.push(tech.family!);
    });
  }

  ngOnInit(): void {
    this.technologies.sort((a: any, b: any) => {
      if (a.displayName > b.displayName) {
        return 1;
      }
      if (a.displayName < b.displayName) {
        return -1;
      }
      return 0;
    });
    this.familyChunk.forEach((x: string) => {
      let fam: any = [];
      this.technologies.forEach((y) => {
        if (y.family === x) {
          fam.push(y);
        }
      });
      let chunkSize = 10;
      if (fam.length > 10) {
        chunkSize = Math.ceil(fam.length / 3);
      }
      const mult: any = [];
      for (let i = 0; i < fam.length; i += chunkSize) {
        const chunk = fam.slice(i, i + chunkSize);
        mult.push(chunk);
      }
      this.devider.push(mult);
    });
  }
  getAmount(value: string) {
    let count = 0;
    this.technologies.forEach((element) => {
      if (
        element.family === value &&
        this.selectedProProfile.technologies.some((x) => x === element.id)
      ) {
        count++;
      }
    });
    return count;
  }
  validateChoices(type: string) {
    this.contextService.validSelection[type] = false;
    if (
      this.contextService.selectedProProfile[type as keyof SelectedPro]
        .length >= this.ruleLength[type]
    ) {
      this.contextService.validSelection[type] = true;
    }
  }

  selectedPro = (id: number, type: keyof type, single?: boolean): void => {
    if (single) {
      this.selectedProProfile[type] = [];
    }
    let findex = this.selectedProProfile[type].findIndex((x) => x === id);
    if (findex === -1) {
      this.selectedProProfile[type].push(id);
    } else {
      this.selectedProProfile[type].splice(findex, 1);
    }
    this.validateChoices(type);
    this.platformService.reRender.next('technologies');
  };
}
