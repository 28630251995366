import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from 'src/services/api/api.service';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';
import { ValidationService } from 'src/services/platform/validation.service';
import { gameInstance } from 'src/ts/interfaces/my-profile.interface';
import {
  jobHistory,
  profileData,
  ProProfile,
  salary,
  selectedCurrency,
  SelectedIndustries,
  SelectedPro,
  selectProfProfile,
  selectStartDate,
  stepsMenuSlug,
} from 'src/ts/interfaces/profile.interface';
import languages from '../../../assets/languages';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  userInfo = this.contextService.userInfo;
  givinName: string;
  familyName: string;
  emails: [];
  loading: boolean = true;
  dialog: boolean = false;
  gameInstanceResults: { first: gameInstance[]; second: gameInstance[] } = {
    first: [],
    second: [],
  };
  result: gameInstance;
  profileData: profileData;
  proProfile: any = {};
  selectedIndustries = this.contextService.selectedIndustries;
  selectedJobHistory: jobHistory[];
  selectedProfile: SelectedPro;
  SelectedIndustries: SelectedIndustries;
  lang = languages;
  languages: any;
  city = '';
  startDate: string;
  profProfile: selectProfProfile[];
  dialogType: string[];
  toEdit: string[];
  profileSubmitted: boolean;
  stepsMenuSlug: stepsMenuSlug;
  selectedSalary: salary;
  Object = Object;
  months: any[] = this.helperFunction.listOfOptions('stringMonth');
  profileComplete: boolean;
  constructor(
    private contextService: ContextService,
    public validationService: ValidationService,
    private platformService: PlatformService,
    private helperFunction: HelperFunctionsService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private _elementRef: ElementRef,
    private router: Router
  ) {
    this.contextService.dataLoaded.subscribe((val) => {

      this.profileSubmitted =
        this.contextService.myProfileData.genome.status !== 'Registered';
      if (val === 'success') {
        this.languages = this.contextService.languages;
      }
      if (this.profileSubmitted) {
        if (val === 'success') {
          this.proProfile = this.contextService.proProfile();
          this.stepsMenuSlug = this.contextService.stepsMenuSlug;
          this.givinName = this.userInfo['given_name'];
          this.familyName = this.userInfo['family_name'];
          this.emails = this.userInfo['emails']!;
          this.profProfile = this.contextService.selectedProfProfile;
          const availabilityDate = new Date(
            this.contextService.selectedStartDate.availabilityDate
          );
          this.startDate =
            this.months[availabilityDate.getMonth()] +
            ', ' +
            availabilityDate.getFullYear();
          this.city = this.contextService.selectedCity?.displayName;
          this.profileData = this.contextService.profileData;
          this.selectedProfile = this.contextService.selectedProProfile;
          this.selectedIndustries = this.contextService.selectedIndustries;
          this.selectedSalary = this.contextService.selectedSalery;
          this.selectedJobHistory = this.contextService.jobHistory;
          this.gameInstanceResults = {
            first: [],
            second: [],
          };
          if (this.contextService.myProfileData.gameAssessments.length === 0) {
            [
              'Speed-accuracy tradeoff',
              'Collaboration',
              'Speed-accuracy tradeoff',
              'Collaboration',
              'Problem sovling style',
            ].forEach((val: string, index) => {
              const value = {
                scores: [
                  {
                    traitDisplayName: val,
                    scoreExcerpt:
                      'Complete the game to find out your scores and how you compare to others.',
                    challenges: [],
                    strengths: [],
                    btn: 'Play all games',
                  },
                ],
                dialogType: 'playGame',
              };
              if (index % 2) {
                this.gameInstanceResults.second.push(value);
              } else {
                this.gameInstanceResults.first.push(value);
              }
            });
          }
          this.contextService.myProfileData.gameAssessments.forEach(
            (val: gameInstance, i: number) => {
              val.scores.forEach((score: any) => {
                if (
                  i <
                  Math.round(
                    this.contextService.myProfileData.gameAssessments.length / 2
                  )
                ) {
                  this.gameInstanceResults.first.push({
                    gameCode: val.gameCode,
                    scores: [score],
                  });
                } else {
                  this.gameInstanceResults.second.push({
                    gameCode: val.gameCode,
                    scores: [score],
                  });
                }
              });
            }
          );
          this.loading = false;
          this.route.queryParams.subscribe((params) => {
            const query = params as { scrollTo: string };
            if (query.scrollTo) {
              setTimeout(() => {
                this._elementRef.nativeElement
                  .querySelector('#' + query.scrollTo)
                  .scrollIntoView({ behavior: 'smooth', block: 'center' });
              }, 100);
              this.router.navigate([], {
                queryParams: {
                  yourParamName: null,
                  youCanRemoveMultiple: null,
                },
              });
            }
          });
        }
      }
    });
  }
  getMonthYear(date: string) {
    const d = new Date(date);
    return this.months[d.getMonth()] + ', ' + d.getFullYear();
  }
  getValue(value: string, object: jobHistory) {
    const key = value as keyof jobHistory;
    return object[key];
  }
  getLanguages() {
    return this.lang.list.filter((x) =>
      this.selectedProfile.languages.some((z) => z === x.id)
    );
  }
  familyChunk(object: ProProfile[], type: string) {
    let familyChunk: string[] = [];
    let technologies = object.filter((x) =>
      this.selectedProfile[type as keyof SelectedPro].some((z) => z === x.id)
    );
    technologies.forEach((tech) => {
      if (!familyChunk.includes(tech.family!)) familyChunk.push(tech.family!);
    });
    return familyChunk;
  }

  getProfile(object: ProProfile[], type: string) {
    return object
      .filter((x) => this.profProfile.some((z) => z.id === x.id))
      .map((val) => {
        val.value = this.profProfile.find((x) => x.id === val.id)?.name ?? '';
        return val;
      });
  }

  getSelection(object: ProProfile[], type: string) {
    return this.selectedProfile[type as keyof SelectedPro].map((id) => {
      return object.find((x) => x.id === id)!;
    });
  }

  editSection(type: string[], toEdit: string[]) {
    this.dialog = !this.dialog;
    this.toEdit = toEdit;
    this.dialogType = type;
    this.platformService.reRender.next(toEdit[0]);
  }

  getIndustry(id: string | number) {
    return this.contextService.profileData.industries.find((x) => x.id === id)
      ?.displayName;
  }

  toogleDialog(type?: string[], result?: gameInstance) {
    this.dialog = !this.dialog;
    if (type) {
      this.dialogType = type;
    } else {
      this.dialogType = [];
    }
    if (result) {
      this.result = result;
    }
  }
  profileFlow() {
    this.router.navigate(['/profile-flow'], {
      queryParamsHandling: 'preserve',
    });
  }
  ngOnInit(): void {
    this.profileComplete = this.validationService.allComplete();
  }
}
