<div (clickOutside)="toggleDropdown(false)" class="list">
  <label *ngIf="label" class="label2">{{label}}</label>
  <div>
    <div class="search-input body1">
      <div [class]="{'active': dropdown, 'trailingIcon': trailingIcon, 'leadingIcon':leadingIcon}" class="input" #input
        (click)="toggleDropdown(true)">

        <i-tabler *ngIf="leadingIcon" [name]="leadingIcon"></i-tabler>
        <ng-container *ngIf="selected?.country">
          <span [class]="'flag flag-country-'+selected.country!.toLocaleLowerCase()"></span>
        </ng-container>
        <div [attr.placeholder]="placeholder" [innerText]="getSelected()" [class]="{'empty': !getSelected()}"
          (input)="search($event)" (focus)="search($event)" class="innerText">
        </div>

        <span>{{selected.state}}</span>
        <i-tabler *ngIf="trailingIcon" [name]="trailingIcon"></i-tabler>
      </div>
    </div>
    <div *ngIf="dropdown" #dropdownList class="dropdown label2">
      <div *ngFor="let option of options" (click)="selectDropdown(option)" [attr.name]="option.displayName">
        <ng-container *ngIf="option?.country">
          <span [class]="'flag flag-country-'+option.country!.toLocaleLowerCase()"></span>
        </ng-container>
        {{option.displayName}}<span>{{option.state ? option.state: ''}}</span>
      </div>
    </div>
  </div>
</div>
