import { Injectable } from '@angular/core';
import {
  ruleLength,
  SelectedIndustries,
  SelectedPro,
} from 'src/ts/interfaces/profile.interface';
import { ContextService } from './context.service';
import languages from '../../assets/languages';
import { HelperFunctionsService } from '../helperFunctions/helper-functions.service';
import { ApiService } from '../api/api.service';
@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(
    private contextService: ContextService,
    private helperFunctions: HelperFunctionsService,
    private apiService: ApiService
  ) {}

  validateChoices(type: string, ruleLength?: ruleLength) {
    this.contextService.validSelection[type] = false;
    if (type === 'jobHistory') {
      if (this.contextService.jobHistory.length >= ruleLength![type]) {
        this.contextService.validSelection[type] = true;
      }
      return;
    }
    if (type === 'industries') {
      if (
        this.contextService.selectedIndustries[type as keyof SelectedIndustries]
          .length >= ruleLength![type] &&
        !this.contextService.selectedIndustries[
          type as keyof SelectedIndustries
        ].some((x: any) => x.selected!.experience === '')
      ) {
        this.contextService.validSelection[type] = true;
      }

      return;
    }
    if (type === 'sweetSpot') {
      this.contextService.validSelection[type] = false;
      if (
        !this.contextService.selectedProProfile[type as keyof SelectedPro].some(
          (x) => x < 0
        )
      ) {
        this.contextService.validSelection[type] = true;
      }
      return;
    }
    if (type === 'rate') {
      if (
        this.contextService.selectedProProfile[type as keyof SelectedPro]
          .length >= ruleLength![type]
      ) {
        this.contextService.validSelection[type] = true;
      }
      if (this.contextService.selectedSalery.currency?.length > 0) {
        this.contextService.validSelection['currency'] = true;
      }
      if (this.contextService.selectedSalery.salary > 0) {
        this.contextService.validSelection['salary'] = true;
      }
      return;
    }
    if (
      this.contextService.selectedProProfile[type as keyof SelectedPro]
        .length >= ruleLength![type]
    ) {
      this.contextService.validSelection[type] = true;
    }
  }

  getProcentage() {
    return this.setSelected();
  }
  async saveSignUp(signupSteps: SelectedPro) {
    this.contextService.dataLoaded.next('fetching');
    let consenttotermsofusebefore = {};
    if (sessionStorage.getItem('terms')) {
      const terms = JSON.parse(sessionStorage.getItem('terms') as string);
      consenttotermsofusebefore = await this.apiService.saveGenome(
        'proxy/consenttotermsofuse',
        terms,
        this.contextService.accessTokens?.genome
      );
    }
    let setworkedasconsultantbefore = await this.apiService.saveGenome(
      'proxy/setworkedasconsultantbefore',
      {
        value: signupSteps.asConsultant[0] === 1,
      },
      this.contextService.accessTokens?.genome
    );

    let updateCity = await this.apiService.saveGenome(
      'proxy/updatelocation',
      {
        country: this.contextService.selectedCity.country,
        city: this.contextService.selectedCity.displayName,
        state: this.contextService.selectedCity.state,
      },
      this.contextService.accessTokens.genome
    );

    let updatejobfamily = await this.apiService.saveSkills(
      'proxy/updatejobfamily',
      { id: signupSteps.jobFamily[0] },
      this.contextService.accessTokens.skills
    );

    const levelWork = this.contextService.proProfile().levelWork;
    const findIndexLevel = levelWork.findIndex(
      (x) => x.id === signupSteps.levelWork[0]
    );
    let displayName = '';
    if (findIndexLevel !== -1) {
      displayName = levelWork[findIndexLevel].displayName;
    }
    let updatelevelofexperience: any = '';
    if (displayName.length > 0) {
      updatelevelofexperience = await this.apiService.saveGenome(
        'proxy/updatelevelofexperience',
        { value: displayName },
        this.contextService.accessTokens?.genome
      );
    }
    const validData = this.contextService.levelOfExperience;
    const ye = this.contextService.proProfile().yearExperience;
    const findIndex = ye.findIndex(
      (x) => x.id === signupSteps.yearExperience[0]
    );
    let yearExperience = await this.apiService.saveIndustries(
      'proxy/updateyearsofexperience',
      {
        value: validData[findIndex],
      },
      this.contextService.accessTokens.industries
    );
    if (
      [
        setworkedasconsultantbefore,
        updateCity,
        updatejobfamily,
        updatelevelofexperience,
        yearExperience,
        consenttotermsofusebefore,
      ].some((x) => x !== true)
    ) {
      return;
    } else {
      this.contextService.myProfileData.genome.previouslyWorkedAsConsultant =
        signupSteps.asConsultant[0] === 1;
      sessionStorage.removeItem('terms');
      localStorage.removeItem('selectedCity');
      localStorage.removeItem('selectedProProfile');
      // this.setSelected()
      this.contextService.dataLoaded.next('success');
    }
    // Promise.all([
    //   setworkedasconsultantbefore,
    //   updatejobfamily,
    //   updatelevelofexperience,
    //   yearExperience,
    //   consenttotermsofusebefore,
    // ]).then((value) => {
    //   if (value.some((x) => x !== true)) {
    //     return
    //   }
    //   sessionStorage.removeItem('terms')
    //   localStorage.removeItem('selectedProProfile')
    //   this.setSelected()
    // })
  }

  setSelected() {
    if (this.contextService.myProfileData.assessments) {
      if (
        this.contextService.myProfileData.assessments.assignedAssessment
          ?.status === 'Completed'
      ) {
        this.contextService.validSelection['game'] = true;
      }
    }

    if (this.contextService.myProfileData.industries.jobHistory) {
      this.contextService.jobHistory =
        this.contextService.myProfileData.industries.jobHistory;
    }
    this.validateChoices('jobHistory', {
      jobHistory: 1,
    });
    if (this.contextService.myProfileData.industries.levelOfExperience) {
      let findex = this.contextService.levelOfExperience.findIndex(
        (x) =>
          x === this.contextService.myProfileData.industries.levelOfExperience
      );

      this.contextService.selectedProProfile.yearExperience = [
        this.contextService.proProfile().yearExperience[findex]?.id,
      ];
    }
    this.validateChoices('yearExperience', {
      yearExperience: 1,
    });
    if (
      this.contextService.myProfileData.industries.selectedIndustries?.length >
      0
    ) {
      const industries = this.contextService.profileData.industries;
      this.contextService.selectedIndustries.industries = [];
      this.contextService.myProfileData.industries.selectedIndustries.forEach(
        (val: any) => {
          let indu = industries.find((x) => x.id === val.id);
          if (!indu) {
            return;
          }
          let findex = this.contextService.levelOfExperience.findIndex(
            (x) => x === val.levelOfExperience
          );
          indu!.selected = {
            rank: val.rank,
            experience:
              this.contextService.proProfile().yearExperience[findex]
                .displayName,
          };
          this.contextService.selectedIndustries.industries.push(indu!);
        }
      );
      this.contextService.myProfileData.industries.selectedIndustries;
    }

    this.validateChoices('industries', {
      yearExperience: 1,
      industries: 1,
    });

    if (this.contextService.myProfileData.skills) {
      if (this.contextService.myProfileData.skills?.jobFamily?.id) {
        this.contextService.selectedProProfile.jobFamily = [
          this.contextService.myProfileData.skills.jobFamily.id,
        ];
      }

      if (this.contextService.myProfileData.skills.selectedSkills) {
        this.contextService.selectedProProfile.skills = [];
        this.contextService.myProfileData.skills.selectedSkills.forEach(
          (val: any) => {
            this.contextService.selectedProProfile.skills.push(val.id);
          }
        );
      }

      if (this.contextService.myProfileData.skills.topSelectedSkills) {
        this.contextService.selectedProProfile.topSkills = [];
        this.contextService.myProfileData.skills.topSelectedSkills.forEach(
          (val: any) => {
            this.contextService.selectedProProfile.topSkills.push(val.id);
          }
        );
      }
      if (this.contextService.myProfileData.skills.technologySkills) {
        this.contextService.selectedProProfile.technologies = [];
        this.contextService.myProfileData.skills.technologySkills.forEach(
          (val: any) => {
            this.contextService.selectedProProfile.technologies.push(val.id);
          }
        );
      }
    }
    this.validateChoices('jobFamily', {
      jobFamily: 1,
    });
    this.validateChoices('skills', {
      skills: 1,
    });
    this.validateChoices('topSkills', {
      topSkills: 1,
    });
    this.validateChoices('technologies', {
      technologies: 1,
    });
    if (this.contextService.myProfileData.genome) {
      if (this.contextService.profileSubmitted) {
        this.contextService.validSelection['submitted'] = true;
      }

      if (this.contextService.myProfileData.genome.levelOfExperience) {
        const findex = this.contextService
          .proProfile()
          .levelWork.findIndex(
            (x) =>
              x.displayName ===
              this.contextService.myProfileData.genome.levelOfExperience
          );
        if (findex !== -1) {
          this.contextService.selectedProProfile.levelWork = [
            this.contextService.proProfile().levelWork[findex].id,
          ];
        }
      }

      this.validateChoices('levelWork', {
        levelWork: 1,
      });
      if (this.contextService.myProfileData.genome.workingSweetspot) {
        let dimension =
          this.contextService.myProfileData.genome.workingSweetspot;
        this.contextService.selectedProProfile.sweetSpot = [
          dimension.dimension1,
          dimension.dimension2,
          dimension.dimension3,
          dimension.dimension4,
          dimension.dimension5,
          dimension.dimension6,
        ];
        this.validateChoices('sweetSpot');
      }
      if (this.contextService.myProfileData.genome.workingLanguages) {
        this.contextService.selectedProProfile.languages = [];
        this.contextService.myProfileData.genome.workingLanguages.forEach(
          (val: any) => {
            this.contextService.selectedProProfile.languages.push(
              languages.list.find((x) => x.countryCode === val)!.id
            );
          }
        );
        this.validateChoices('languages', {
          languages: 1,
        });
      }

      if (this.contextService.myProfileData.genome.location) {
        let location = this.contextService.myProfileData.genome.location;
        if (location.country && location.city) {
          this.contextService.selectedCity.country = location.country;
          this.contextService.selectedCity.displayName = location.city;
          this.contextService.selectedCity.state = location.state;
          this.contextService.validSelection['city'] = true;
        }
      }
      if (this.contextService.myProfileData.genome.workingPreferences) {
        this.contextService.selectedProProfile.workFrom = [];
        let workingPreferences =
          this.contextService.myProfileData.genome.workingPreferences;

        if (workingPreferences.remote) {
          this.contextService.selectedProProfile.workFrom.push(1);
        }

        if (workingPreferences.onSite) {
          this.contextService.selectedProProfile.workFrom.push(2);
        }
        if (workingPreferences.hybrid) {
          this.contextService.selectedProProfile.workFrom.push(3);
        }
        this.validateChoices('workFrom', {
          workFrom: 1,
        });
      }
      if (
        this.contextService.myProfileData.genome.availabilityDate &&
        this.contextService.myProfileData.genome.availabilityDate !==
          '0001-01-01' &&
        (this.contextService.myProfileData.genome.worksFullTime ||
          this.contextService.myProfileData.genome.worksPartTime)
      ) {
        this.contextService.selectedStartDate.availabilityDate =
          this.contextService.myProfileData.genome.availabilityDate;
        this.contextService.selectedStartDate.fullTime =
          this.contextService.myProfileData.genome.worksFullTime;
        this.contextService.selectedProProfile.workTime = [];
        if (this.contextService.selectedStartDate.fullTime) {
          this.contextService.selectedProProfile.workTime.push(1);
        }
        this.contextService.selectedStartDate.partTime =
          this.contextService.myProfileData.genome.worksPartTime;
        if (this.contextService.selectedStartDate.partTime) {
          this.contextService.selectedProProfile.workTime.push(2);
        }
        // let d = new Date(
        //   this.contextService.myProfileData.genome.availabilityDate
        // );
        // this.contextService.selectedStartDate.DD = JSON.stringify(d.getDate());

        // this.contextService.selectedStartDate.MM =
        //   this.helperFunctions.listOfOptions('month')[d.getMonth()] as string;
        // this.contextService.selectedStartDate.YY = JSON.stringify(
        //   d.getFullYear()
        // );
        // if (
        //   this.contextService.selectedStartDate.DD &&
        //   this.contextService.selectedStartDate.MM &&
        //   this.contextService.selectedStartDate.YY
        // ) {
        this.contextService.validSelection['workTime'] = true;
        this.contextService.validSelection['startDate'] = true;
        // }
      }

      if (
        this.contextService.myProfileData.genome.previouslyWorkedAsConsultant
      ) {
        this.contextService.selectedProProfile.asConsultant = [1];
      } else {
        this.contextService.selectedProProfile.asConsultant = [2];
      }

      this.validateChoices('asConsultant', {
        asConsultant: 1,
      });
      if (this.contextService.myProfileData.genome?.expectedIncome?.isHourly) {
        this.contextService.selectedProProfile.rate = [1];
      }
      if (
        this.contextService.myProfileData.genome?.expectedIncome?.isHourly ===
        false
      ) {
        this.contextService.selectedProProfile.rate = [2];
      }

      this.contextService.selectedSalery.salary =
        this.contextService.myProfileData.genome.expectedIncome?.value;
      this.contextService.selectedSalery.currency =
        this.contextService.myProfileData.genome.expectedIncome?.currency;
      this.validateChoices('rate', {
        rate: 1,
        salary: 1,
        currency: 1,
      });

      if (this.contextService.myProfileData.genome.professionalProfiles) {
        this.contextService.selectedProfProfile = [];
        for (const [key, value] of Object.entries(
          this.contextService.myProfileData.genome.professionalProfiles
        )) {
          // if (`${value}` !== '') {
          let find = this.contextService
            .proProfile()
            .profile.find(
              (x) => x.displayName.toLocaleLowerCase() === `${key}`
            );
          this.contextService.selectedProfProfile.push({
            id: find!.id,
            name: `${value}`,
          });
          this.contextService.validSelection['profProfile'] = true;
          // }
        }
      }
    }
    if (
      localStorage.getItem('selectedProProfile') &&
      sessionStorage.getItem('signup') &&
      this.contextService.accessTokens
    ) {
      this.contextService.selectedProProfile = JSON.parse(
        localStorage.getItem('selectedProProfile')!
      );
      this.contextService.selectedCity = JSON.parse(
        localStorage.getItem('selectedCity')!
      );

      this.saveSignUp(this.contextService.selectedProProfile);
    }

    let lengthOfSteps = 0;
    let lengthOfFinishSteps = 0;
    for (const [key, value] of Object.entries(
      this.contextService.validSelection
    )) {
      if (value) {
        lengthOfFinishSteps++;
      }
      lengthOfSteps++;
    }
    this.allComplete();
    return (lengthOfFinishSteps * 100) / lengthOfSteps;
  }
  allComplete() {
    const step = this.contextService.validSelection;
    const allComplete: boolean[] = [];
    this.contextService.stepsMenu.forEach((menu) => {
      if (menu.rule === 'required') {
        menu.validation.forEach((x) => {
          allComplete.push(step[x]);
        });
      } else {
        allComplete.push(true);
      }
    });
    return allComplete.every((x) => x);
  }
}
