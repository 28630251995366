import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
} from '@azure/msal-angular';
import {
  InteractionType,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { catchError, filter, Subject, takeUntil } from 'rxjs';
import { type } from 'src/ts/interfaces/profile.interface';
import {
  appAccessScopes,
  authScopes,
  b2cPolicies,
} from '../../app/auth-config';
import { ContextService } from '../platform/context.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _destroying$ = new Subject<void>();
  isLogin = false;
  loginDisplay = new Subject<boolean>();
  accessToken = new Subject<object>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private contextService: ContextService,
    private router: Router
  ) {}

  initialize() {
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.eventType === 'msal:acquireTokenFailure') {
          this.signout();
        } else {
          if (this.authService.instance.getAllAccounts().length === 0) {
            window.location.pathname = '/';
          } else {
            this.setLoginDisplay();
          }
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((response) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.loginDisplay.subscribe(async (val) => {
      if (val) {
        this.contextService.userInfo =
          this.authService.instance.getAllAccounts()[0]!.idTokenClaims!;
        let accessTokens: { [key: string]: string } = {};
        for (let i = 0; i < appAccessScopes.length; i++) {
          const accessTokenRequest = {
            scopes: [appAccessScopes[i].scope],
            account: this.authService.instance.getAllAccounts()[0],
          };
          await new Promise((resolve) => {
            this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
              (response: any) => {
                accessTokens[appAccessScopes[i].api] = response.accessToken;
                resolve(true);
              },
              (err: any) => {
                console.log(err);
              }
            );
          });

          if (this.router.url === '/') {
            this.router.navigate(['/home'], {
              queryParamsHandling: 'preserve',
            });
          }
        }

        this.accessToken.next(accessTokens);
      }
      this.contextService.authFlow = false;
    });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();

      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getClaims(claims: any) {}

  setLoginDisplay() {
    this.changeLoginDisplay(
      this.authService.instance.getAllAccounts().length > 0
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  changeLoginDisplay(val: boolean) {
    this.loginDisplay.next(val);
    this.isLogin = val;
  }
  signin() {
    sessionStorage.removeItem('signup');
    localStorage.removeItem('selectedProProfile');
    sessionStorage.setItem('signupOrSignin', 'true');
    let signinFlowRequest = {
      scopes: authScopes,
      authority: b2cPolicies.authorities.signin.authority,
    };

    this.userRequest(signinFlowRequest);
  }
  signout() {
    this.authService.instance
      .handleRedirectPromise()
      .then((res) => {
        this.authService.logout();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  userRequest(userFlowRequest?: RedirectRequest | PopupRequest) {
    // if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //   if (this.msalGuardConfig.authRequest) {
    //     this.authService
    //       .loginPopup({
    //         ...this.msalGuardConfig.authRequest,
    //         ...userFlowRequest,
    //       } as PopupRequest)
    //       .subscribe((response: AuthenticationResult) => {
    //         this.authService.instance.setActiveAccount(response.account)
    //       })
    //   } else {
    //     this.authService
    //       .loginPopup(userFlowRequest)
    //       .subscribe((response: AuthenticationResult) => {
    //         this.authService.instance.setActiveAccount(response.account)
    //       })
    //   }
    // } else {

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest,
      } as RedirectRequest);
    } else {
      this.authService.instance
        .handleRedirectPromise()
        .then((res: any) => {
          const account = this.authService.instance.getActiveAccount();
          if (account) {
            this.authService.loginRedirect(userFlowRequest);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  // }
}
