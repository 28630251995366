<div class="wrap-selection">

  <div class="selection">
    <app-header [languages]="languages"></app-header>
    <div *ngFor="let family of familyChunk; let i = index">
      <p class="label2">{{family}} <span class="caption">{{getAmount(family)}}</span></p>
      <div [class]="{'nonSelected':  selectedProProfile.technologies.length === 0, 'technology': true}">
        <div *ngFor="let dev of devider[i]" [class]="{'row':devider[i].length === 1}">
          <ng-container *ngFor="let technology of dev; let i = index">
            <app-buttons *ngIf="technology.family === family" [single]="false"
              [selected]="selectedProProfile.technologies.includes(technology.id)" [callbackFunction]="selectedPro"
              type="technologies" [loop]="i" [data]="technology">
            </app-buttons>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
