import { Component, NgZone, OnInit } from '@angular/core'
import { menues } from 'src/ts/interfaces/profile.interface'
import { AuthenticationService } from 'src/services/authentication/authentication.service'
import { PlatformService } from 'src/services/platform/platform.service'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ContextService } from 'src/services/platform/context.service'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

@Component({
  selector: 'app-landingPage',
  templateUrl: './landingPage.component.html',
  styleUrls: ['./landingPage.component.scss'],
})
export class LandingPageComponent implements OnInit {
  title = this.contextService.title()
  menues: menues[] = []
  loading = true
  authFlow:boolean;
  languages: any
  imageObject: Array<any> = []
  faCheck = faCheck
  page = this.activatedRoute.snapshot.data['page']
  postslug = this.activatedRoute.snapshot.params['postslug']
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private contextService: ContextService,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url.split('?')
        if (url[0] === '/' && this.authenticationService.isLogin) {
          // this.router.navigate(['/home'], { queryParamsHandling: 'preserve' })
        }
      }
    })
    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'successNoToken') {
        this.loading = false
        this.languages = this.contextService.languages
        this.imageObject = this.languages.landingPage.firstSection.imageObject
      }
    })
  }
  ngOnInit(): void {
    this.authFlow = this.contextService.authFlow;
    if (this.authenticationService.isLogin) {
      this.menues = [
        { route: '/home', title: 'Home' },
        { route: 'signout', title: 'Sign out' },
      ]
    } else {
      this.menues = [
        { route: '/signup', title: 'Sign up' },
        { route: 'signin', title: 'Sign in' },
      ]
    }
  }
  route(value: string) {
    if (value === 'signout') {
      this.authenticationService.signout()
      return
    }
    if (value === 'signin') {
      this.authenticationService.signin()
      return
    }
    this.router.navigate([value], { queryParamsHandling: 'preserve' })
  }
}
