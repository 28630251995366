import { DOCUMENT, ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import axios from 'axios';
import { dropdown } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)',
  },
})
export class DropdownComponent {
  @Input() options: dropdown[] = [];
  @ViewChild('dropdownList') dropdownList: ElementRef<HTMLElement>;
  @ViewChild('input') input: ElementRef<HTMLElement>;
  @Input() styles: string;
  @Input() label: string;
  @Input() leadingIcon: string;
  @Input() trailingIcon: string;
  @Input() placeholder: string;
  @Input() selected: dropdown;
  @Input() searchable: boolean;
  @Output() selectedCallback = new EventEmitter<any>();
  rawOptions: dropdown[];
  dropdown: boolean = false;
  constructor() {}

  handleKeyboardEvents(event: KeyboardEvent) {
    if (this.dropdown && event.code.includes('Key')) {
      const dropdownList: HTMLCollection =
        this.dropdownList.nativeElement.children;
      for (let i = 0; i < dropdownList.length; i++) {
        if (
          dropdownList[i]
            .getAttribute('name')
            ?.startsWith(event.key.toUpperCase())
        ) {
          this.dropdownList.nativeElement.scroll({
            top:
              this.dropdownList.nativeElement.scrollTop +
              dropdownList[i].getBoundingClientRect().top -
              500,
            left: 0,
            behavior: 'smooth',
          });
          break;
        }
      }
    }
  }

  search(event: Event) {
    const target = event.currentTarget as HTMLInputElement;

    if (target.innerText.length === 0) {
      this.selected = { displayName: '' };
      this.options = [];
    }
    if (!this.rawOptions) {
      this.rawOptions = this.options;
    }

    if (target.innerText.length === 0) {
      this.options = this.rawOptions;
      return;
    }
    this.options = this.rawOptions.filter((x) =>
      x.displayName.toLowerCase().startsWith(target.innerText.toLowerCase())
    );
  }

  findContainer() {
    const findContainer = this.input.nativeElement.querySelector(
      '.innerText'
    ) as HTMLElement;
    return findContainer;
  }

  toggleDropdown(value: boolean) {
    if (value && this.findContainer()) {
      this.findContainer()!.setAttribute(
        'contenteditable',
        this.searchable ? 'true' : 'false'
      );
      this.findContainer()!.focus();
    }
    this.dropdown = value;
  }

  selectDropdown(option: dropdown) {
    // this.selected = option;=
    // this.findContainer().innerText  option.displayName;
    this.selectedCallback.emit(option);
    this.dropdown = false;
  }
  getSelected() {
    let selectedString = this.selected?.displayName ?? '';
    if (this.selected?.trailingInfo) {
      selectedString += ', ' + this.selected.trailingInfo;
    }
    return selectedString;
  }
  ngAfterViewInit() {}
}
