import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class HelperFunctionsService {
  constructor() {}

  slugify(text: string) {
    // return text.toString().toLowerCase()
    //   .replace(/\s+/g, '-')           // Replace spaces with -
    //   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    //   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    //   .replace(/^-+/, '')             // Trim - from start of text
    //   .replace(/-+$/, '');            // Trim - from end of text

    text = text.replace(/^\s+|\s+$/g, '') // trim
    text = text.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâæåðèéëêìíïîòóöôùúüûñçýø·/_,:;'
    var to = 'aaaaaadeeeeiiiioooouuuuncyo------'
    for (var i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    text = text
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return text
  }
  listOfOptions(type: string, amount?: number, array?: string[]) {
    let list: any = [];
    for (let i = 0; i < amount!; i++) {
      list.push(i + 1);
    }
    if (type === 'years') {
      list = [];
      const d = new Date();
      for (let i = 0; i < amount!; i++) {
        list.push(d.getFullYear() + i);
      }
    }
    if (type === 'stringMonth') {
      list = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
    }
    if (type === 'month') {
      list = [
        { monthIndex: 1, id: 1, displayName: 'January', year: '' },
        { monthIndex: 2, id: 2, displayName: 'February', year: '' },
        { monthIndex: 3, id: 3, displayName: 'March', year: '' },
        { monthIndex: 4, id: 4, displayName: 'April', year: '' },
        { monthIndex: 5, id: 5, displayName: 'May', year: '' },
        { monthIndex: 6, id: 6, displayName: 'June', year: '' },
        { monthIndex: 7, id: 7, displayName: 'July', year: '' },
        { monthIndex: 8, id: 8, displayName: 'August', year: '' },
        { monthIndex: 9, id: 9, displayName: 'September', year: '' },
        { monthIndex: 10, id: 10, displayName: 'October', year: '' },
        { monthIndex: 11, id: 11, displayName: 'November', year: '' },
        { monthIndex: 12, id: 12, displayName: 'December', year: '' },
      ];
      let monthList: any = [];
      array?.forEach((val) => {
        const d = new Date(val);
        if (!d.getDate()) {
          return;
        }
        let monthInfo = list.find((x: any) => x.id === d.getMonth() + 1);
        monthInfo = JSON.parse(JSON.stringify(monthInfo));
        monthInfo.year = d.getFullYear();
        monthInfo.id = monthInfo.id + '' + d.getFullYear();
        monthList.push(monthInfo);
      });
      return monthList;
    }
    if (type === 'monthDate') {
      list = [
        { monthIndex: 1, id: 1, displayName: 'January', year: '' },
        { monthIndex: 2, id: 2, displayName: 'February', year: '' },
        { monthIndex: 3, id: 3, displayName: 'March', year: '' },
        { monthIndex: 4, id: 4, displayName: 'April', year: '' },
        { monthIndex: 5, id: 5, displayName: 'May', year: '' },
        { monthIndex: 6, id: 6, displayName: 'June', year: '' },
        { monthIndex: 7, id: 7, displayName: 'July', year: '' },
        { monthIndex: 8, id: 8, displayName: 'August', year: '' },
        { monthIndex: 9, id: 9, displayName: 'September', year: '' },
        { monthIndex: 10, id: 10, displayName: 'October', year: '' },
        { monthIndex: 11, id: 11, displayName: 'November', year: '' },
        { monthIndex: 12, id: 12, displayName: 'December', year: '' },
      ];
      let monthList: any = [];
      array?.forEach((val) => {
        const d = new Date(val);
        if (!d.getDate()) {
          return;
        }
        let monthInfo = list.find((x: any) => x.id === d.getMonth() + 1);
        monthInfo = JSON.parse(JSON.stringify(monthInfo));
        monthInfo.year = d.getFullYear();
        monthInfo.id =
          d.getFullYear() +
          '-' +
          (monthInfo.id < 10 ? '0' : '') +
          monthInfo.id +
          '-' +
          '01';
        monthList.push(monthInfo);
      });
      return monthList;
    }
    return list;
  }

  // listOfOptions(type: string, amount?: number) {
  //   let list = []
  //   for (let i = 0; i < amount!; i++) {
  //     list.push(i + 1)
  //   }
  //   if (type === 'years') {
  //     list = []
  //     const d = new Date()
  //     for (let i = 0; i < amount!; i++) {
  //       list.push(d.getFullYear() + i)
  //     }
  //   }
  //   if (type === 'month') {
  //     list = [
  //       'January',
  //       'February',
  //       'March',
  //       'April',
  //       'May',
  //       'June',
  //       'July',
  //       'August',
  //       'September',
  //       'October',
  //       'November',
  //       'December',
  //     ]
  //   }
  //   return list
  // }
}
