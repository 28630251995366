import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';
import { ValidationService } from 'src/services/platform/validation.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ruleLength,
  SelectedIndustries,
  SelectedPro,
} from 'src/ts/interfaces/profile.interface';
import {
  card,
  status,
  statusMessage,
} from 'src/ts/interfaces/my-profile.interface';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  faCheck = faCheck;
  givinName: string | unknown;
  faSpinner = faSpinner as IconProp;
  title: string = '0';
  loading: boolean = true;
  profileSubmitted: boolean;
  welcome: string = '';
  status: status[] = [];
  languages: any = {};
  dialogType: string[];
  cardInfo: any;
  sevenNConsultant = this.contextService.sevenNConsulatant;
  toEdit: string[];
  module: boolean;
  cards: card[] = [
    {
      tag: 'Onboarding',
      headline: 'Welcome to 7N',
      description:
        'We are happy to welcome you to 7N and look forward to working with you',
      btnText: 'Read more',
      btnSlug: 'welcome',
      backgroundImage: './assets/images/welcome-7n.png',
      disable: false,
    },
    {
      tag: 'Matching',
      headline: 'Add your job history',
      description:
        'You can now add your job history and get better matched to future jobs',
      btnText: 'Read more',
      btnSlug: 'experience',
      backgroundImage: './assets/images/job-history.png',
      disable:
        this.contextService.myProfileData.industries.jobHistory.length !== 0,
    },
    {
      tag: 'Onboarding',
      headline: 'Join 7N community',
      description:
        'Would you be interested in joining the 7N digital community',
      btnText: 'Read more',
      btnSlug: 'join-community',
      backgroundImage: './assets/images/join-community.png',
      disable: !this.sevenNConsultant,
    },
    {
      tag: 'Matching',
      headline: 'Play the games',
      description: 'You have the option to play the games whenever you want.',
      btnText: 'Take me there',
      btnSlug: 'games',
      backgroundImage: './assets/images/playinggames.png',
      disable:
        this.contextService.myProfileData.assessments?.assignedAssessment
          ?.status === 'Completed',
    },
    {
      tag: 'Matching',
      headline: 'Complete your profile',
      description:
        'Complete your profile and increase your chances to get matched!',
      btnText: 'Take me there',
      btnSlug: 'complete-profile',
      backgroundImage: './assets/images/notification-cards.png',
      disable: this.validationService.allComplete(),
    },
  ];
  dialog = false;
  statusMessage: any[] = [
    {
      status: 'Incomplete',
      title: '',
      description: '',
    },
    {
      status: 'PendingReview',
      title: '',
      description: '',
    },
    {
      status: 'PendingInterview',
      title: '',
      description: '',
    },
    {
      status: 'Rejected',
      title: '',
      description: '',
    },
    {
      status: 'Approved',
      title: '',
      description: '',
    },
  ];
  message: statusMessage = this.statusMessage[0];
  benifits: any[] = [];
  currentDate = new Date();
  sevenNConsulatant = false;
  constructor(
    private authService: MsalService,
    private router: Router,
    private platformService: PlatformService,
    private validationService: ValidationService,
    private authenticationService: AuthenticationService,
    public contextService: ContextService
  ) {
    const data: [number, string][] = [
        // [22, 'Working late'],
        [18, 'Good evening,'],
        [12, 'Good afternoon,'],
        [5, 'Good morning,'],
        [0, 'ZZzzz..'],
      ],
      hr = this.currentDate.getHours();
    for (var i = 0; i < data.length; i++) {
      if (hr >= data[i][0]) {
        this.welcome = data[i][1];
        break;
      }
    }
    this.givinName =
      this.authService.instance.getActiveAccount()?.idTokenClaims![
        'given_name'
      ];
    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        const status = this.contextService.myProfileData.genome.status;
        this.sevenNConsulatant = this.contextService.sevenNConsulatant;
        this.profileSubmitted = status !== 'Registered' ?? false;
        setTimeout(() => {
          this.contextService.profileSubmitted = this.profileSubmitted;
        });
        this.languages = this.contextService.languages;
        this.benifits = this.languages.benifits;
        if (this.sevenNConsulatant) {
          this.languages.statusMessage.headline = 'Join the 7N community';
        }
        this.statusMessage.map((elm) => {
          if (elm.status === 'Incomplete') {
            elm.title = this.languages.statusMessage.Incomplete.title;
            elm.description =
              this.languages.statusMessage.Incomplete.description;
          }
          if (elm.status === 'PendingReview') {
            elm.title = this.languages.statusMessage.PendingReview.title;
            elm.description =
              this.languages.statusMessage.PendingReview.description;
          }
          if (elm.status === 'Approved') {
            elm.title = this.languages.statusMessage.Approved.title;
            elm.description = this.languages.statusMessage.Approved.description;
          }
          if (elm.status === 'Rejected') {
            elm.title = this.languages.statusMessage.Rejected.title;
            elm.description = this.languages.statusMessage.Rejected.description;
          }
          if (elm.status === 'PendingInterview') {
            elm.title = this.languages.statusMessage.PendingInterview.title;
            elm.description =
              this.languages.statusMessage.PendingInterview.description;
          }
        });
        this.status = this.contextService.status.map((val) => {
          if (val.title === 'Pending review') {
            val.passed = this.profileSubmitted;
            if (val.passed) {
              this.message = this.statusMessage.find(
                (x) => x.status === 'PendingReview'
              );
            }
          }
          if (val.title === 'Interview') {
            val.type = status;
            val.passed = status === 'PendingInterview' || status === 'Rejected';
            if (val.passed) {
              this.message = this.statusMessage.find(
                (x) => x.status === status
              );
            }
          }
          if (val.type === 'approved') {
            val.passed = status === 'Approved';
            if (val.passed) {
              this.message = this.statusMessage.find(
                (x) => x.status === status
              );
            }
          }
          return val;
        });
        this.status[this.status.filter((x) => x.passed).length - 1].state =
          true;
        this.loading = false;
      }
    });
  }
  toogleDialog(event?: any) {
    if (event) {
      if (event === 'games') {
        this.router.navigate(['/profile']);
        return;
      }
      if (event === 'experience' || event === 'complete-profile') {
        this.router.navigate(['/profile'], {
          queryParams: { scrollTo: 'experience' },
        });
        return;
      }

      this.dialogType = ['card', event];
      if (event === 'welcome') {
        this.cardInfo = this.benifits;
      }
      if (event === 'join-community') {
        this.cardInfo = this.languages.joinCommunity;
      }
    }
    this.dialog = !this.dialog;
  }
  closeModule() {
    this.module = !this.module;
  }
  lastOfPassed(i: number) {
    return i === this.status.map(x=>x.passed).lastIndexOf(true);
  }
  signout() {
    this.authenticationService.signout();
  }
  goToRoute(value: boolean) {
    if (value) {
      this.router.navigate(['/profile'], { queryParamsHandling: 'preserve' });
    } else {
      sessionStorage.removeItem('profile-flow');
      this.router.navigate(['/profile-flow'], {
        queryParamsHandling: 'preserve',
      });
    }
  }
  ngOnInit(): void {}
}
