import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss'],
})
export class WidgetsComponent {
  @Input() type: string = '';
  @Input() agent: { name: string; email: string; image: string };
  @Input() profile: { percent: number };
}
