<router-outlet></router-outlet>
<div *ngIf="!cookiesSet" class="cookies">
  <div class="text">
    <p class="bolder">We use cookies</p>
    <p>We use cookies only for the purpose of analyzing the usage of the website.</p>
  </div>
  <div class="cta">
    <app-button (click)="handleCookies('1')" [class]="['primary']" [label]="'Accept'"></app-button>
    <app-button (click)="handleCookies('0')" [class]="['primary']" [label]="'Reject'"></app-button>
  </div>
</div>
