import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { buttons } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
})
export class CtaComponent implements OnInit {
  @Input() buttons: buttons[] = [];
  @Input() text: string = '';
  @Input() isSafari: boolean = false;
  @Output() callbackFunction = new EventEmitter<any>();
  constructor() {}

  click(value: buttons) {
    this.callbackFunction.emit(value);
  }

  ngOnInit(): void {}
}
