<div class="widgets">
  <div class="agent" *ngIf="type === 'agent'">
    <div>
      <label class="caption">YOUR AGENT</label>
      <label class="label2">{{agent.name}}</label>
      <a [href]="'mailto:'+agent.email" class="body2">{{agent.email}}</a>
    </div>
    <div>
      <img [src]="agent.image" />
    </div>
  </div>
  <div class="profile-strength" *ngIf="type === 'profile-strength'">
    <div>
      <label class="caption">PROFILE STRENGTH</label>
      <label class="label2">Complete your profile and become super duper</label>
    </div>
    <div class="circle">
      <circle-progress class="label1" [toFixed]="0" [percent]="profile.percent" [radius]="100" [animation]="true"
        title="{{profile.percent}}%" [showSubtitle]="false" [animationDuration]="200"
        [showUnits]="false"
        outerStrokeLinecap="round"
        [innerStrokeWidth]="12"
        [outerStrokeWidth]="19"
        ></circle-progress>
    </div>
  </div>
</div>
