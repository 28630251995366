import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  ruleLength,
  SelectedPro,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import { ApiService } from 'src/services/api/api.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  selectedProProfile = this.contextService.selectedProProfile;
  jobFamily = this.contextService.profileData.jobFamily;
  ruleLength: ruleLength = {
    levelWork: 1,
    jobFamily: 1,
    yearExperience: 1,
    asConsultant: 1,
  };
  languages = this.contextService.languages.roles;
  @Output() change = new EventEmitter<any>();
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {}

  validateChoices(type: string) {
    this.contextService.validSelection[type] = false;
    if (
      this.contextService.selectedProProfile[type as keyof SelectedPro]
        .length >= this.ruleLength[type]
    ) {
      this.contextService.validSelection[type] = true;
    }
  }

  selectedPro = (id: number, type: keyof type, single?: boolean): void => {
    let findex = this.selectedProProfile[type].findIndex((x) => x === id);
    this.contextService.selectedProProfile[type] = [];
    this.contextService.validSelection[type] = false;
    if (type === 'jobFamily') {
      this.contextService.selectedProProfile.skills = [];
      this.contextService.validSelection['skills'] = false;
      this.contextService.selectedProProfile.topSkills = [];
      this.contextService.validSelection['topSkills'] = false;
    }
    if (single) {
      this.selectedProProfile[type] = [];
    }

    if (findex === -1) {
      this.selectedProProfile[type].push(id);
    } else {
      this.selectedProProfile[type].splice(findex, 1);
    }

    this.validateChoices(type);
    this.platformService.reRender.next('roles');
  };
}
