<div class="wrap-selection">
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages.headline}}
      </h1>
      <p class="body2">{{languages.description}} </p>
    </div> -->
    <ng-container *ngIf="!disabled">
      <app-header [languages]="languages"></app-header>
      <div>
        <p class="label1">What do you find most important in a work place?</p>
      </div>
    </ng-container>
    <div>
      <div *ngFor="let skill of proProfile.sweetSpot; let i = index">
        <!-- <p class="body2">{{skill.displayName}}</p> -->

        <div>
          <app-slider [disabled]="disabled" [title]="skill.displayName" [minLabel]="languages.underTitle.not"
            (change)="value[i] = $event;validateChoices('sweetSpot')" [maxLabel]="languages.underTitle.very"
            [value]="value[i] < 0 ? 2 : value[i]"></app-slider>


          <!-- <mat-slider class="black" (change)="validateChoices('sweetSpot')" [(ngModel)]="value[i]" aria-label="unit(s)">
          </mat-slider>
          <div class="start-end">
            <div>{{languages.underTitle.not}}</div>
            <div>{{languages.underTitle.very}}</div>
          </div> -->
        </div>
      </div>

    </div>
  </div>

</div>
