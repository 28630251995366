import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() dropdown: 'leading' | 'trailing' = 'leading';
  @Input() type: string = 'field';
  @Input() combined: string;
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() value: string | number = '';
  @Input() option: { icon?: string; displayName: string };
  @Input() options: { icon?: string; displayName: string }[];
  @Input() inputBox: number[] = [];
  @Input() trailingIcon: string;
  @Output() blur = new EventEmitter<FocusEvent>();
  @Output() selectedCallback = new EventEmitter<any>();
  @ViewChildren('box') box!: QueryList<ElementRef>;

  isApple() {
    var expression = /(Mac|iPhone|iPod|iPad)/i;
    return expression.test(navigator.userAgent);
  }

  validate(event: KeyboardEvent) {
    if (this.isControl(event)) {
      if (
        ['KeyA', 'KeyV', 'KeyC'].some((x) => {
          return x === event.code;
        })
      ) {
        return true;
      }
    }
    if (
      ['Backspace', 'Tab'].some((x) => {
        return x === event.code;
      })
    ) {
      return true;
    }
    return /^[0-9]$/.test(event.key);
  }

  setValueAndFocus(split: string[]) {
    let loop = 0;
    let next = 0;
    this.box.forEach((element, key) => {
      const active = element.nativeElement.ownerDocument.activeElement.name;
      const currentIndex = JSON.parse(active);
      if (key >= currentIndex) {
        if (/^[0-9]$/.test(split[loop])) {
          element.nativeElement.value = split[loop];
          next = currentIndex + 1;
          loop++;
        }
        if (next === key) {
          element.nativeElement.focus();
        }
      }
    });
  }

  paste(event: ClipboardEvent) {
    event.preventDefault();
    const value = event.clipboardData?.getData('text') ?? '';
    const split = value.split('');
    this.setValueAndFocus(split);
  }

  input(event: Event) {
    const input = event as InputEvent;
    const split = (input.data ?? '').split('');

    this.setValueAndFocus(split);
  }

  isControl(event: KeyboardEvent) {
    if (this.isApple()) {
      return event.metaKey;
    }
    return event.ctrlKey;
  }
}
