import { DOCUMENT, ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import axios from 'axios';

@Component({
  selector: 'app-country-dropdow',
  templateUrl: './country-dropdow.component.html',
  styleUrls: ['./country-dropdow.component.scss'],
})
export class CountryDropdowComponent {
  @Input() options: dropdown[] = [];
  @ViewChild('dropdownList') dropdownList: ElementRef<HTMLElement>;
  @ViewChild('input') input: ElementRef<HTMLElement>;
  @Input() styles: string;
  @Input() label: string;
  @Input() leadingIcon: string;
  @Input() trailingIcon: string;
  @Input() selected: dropdown;
  @Input() searchable: boolean;
  @Input() placeholder: string;
  @Output() selectedCallback = new EventEmitter<any>();
  rawOptions: dropdown[];
  constructor() {
    this.rawOptions = this.options;
  }

  dropdown: boolean = false;
  handleKeyboardEvents(event: KeyboardEvent) {
    if (this.dropdown && event.code.includes('Key')) {
      const dropdownList: HTMLCollection =
        this.dropdownList.nativeElement.children;
      for (let i = 0; i < dropdownList.length; i++) {
        if (
          dropdownList[i]
            .getAttribute('name')
            ?.startsWith(event.key.toUpperCase())
        ) {
          this.dropdownList.nativeElement.scroll({
            top:
              this.dropdownList.nativeElement.scrollTop +
              dropdownList[i].getBoundingClientRect().top -
              500,
            left: 0,
            behavior: 'smooth',
          });
          break;
        }
      }
    }
  }
  search(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    if (target.innerText.length === 0) {
      // this.selected.emit('');
      this.selected = { displayName: '' };
      this.options = [];
    }

    if (target.innerText.length > 1) {
      axios
        .get(
          'https://secure.geonames.net/searchJSON?name_startsWith=' +
            target.innerText +
            '&featureClass=p&maxRows=50&username=jacobheidelbach'
        )
        .then((response) => {
          const cities = response.data.geonames.map((value: any) => {
            const city = {
              country: value.countryCode,
              displayName: value.name,
              state: value.adminName1,
              lat: value.lat,
              lng: value.lng,
            };
            return city;
          });
          this.options = cities;
        });
    }
  }

  findContainer() {
    const findContainer = this.input.nativeElement.querySelector(
      '.innerText'
    ) as HTMLElement;
    return findContainer;
  }

  toggleDropdown(value: boolean) {
    if (value && this.findContainer()) {
      this.findContainer()!.setAttribute(
        'contenteditable',
        this.searchable ? 'true' : 'false'
      );
      this.findContainer()!.focus();
    }

    this.dropdown = value;
  }

  selectDropdown(option: dropdown) {
    this.findContainer().innerText = option.displayName;
    this.selectedCallback.emit(option);
    this.dropdown = false;
  }
  getSelected() {
    let selectedString = this.selected?.displayName ?? '';
    return selectedString;
  }
  ngAfterViewInit() {}
}

type dropdown = {
  country?: string;
  displayName: string;
  state?: string;
};
