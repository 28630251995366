import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextService } from 'src/services/platform/context.service';
import { ValidationService } from 'src/services/platform/validation.service';
import { gameInstance } from 'src/ts/interfaces/my-profile.interface';
import { buttons } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() title: string = '';
  @Input() excerpt: string = '';
  @Input() text: string = '';
  @Input() cta: string = '';
  @Input() type: string[] = [];
  @Input() toEdit: string[] = [];
  @Input() languages: any;
  @Input() data: gameInstance;
  @Input() cardInfo: any;
  @Output() close = new EventEmitter<any>();
  reRender: boolean = true;
  introRead: boolean;
  introductionBtn: buttons[] = [
    { title: 'Next', type: 'next', class: 'primary' },
  ];

  gameText = {
    headline: 'Games',
    excerpt:
      'Once you hit “Play” you will play all games continuously after each other without any breaks. Have fun!',
    description: '',
  };
  constructor(
    private contextService: ContextService,
    private validationServive: ValidationService
  ) {}

  ngOnInit(): void {}
  closeDialog(event?: any) {
    if (event === 'previous') {
      this.introRead = false;
      return;
    }
    this.close.emit();
  }
  step(event: any) {
    if (event === 'next') {
      this.introRead = true;
    }
    if (event === 'close') {
      this.close.emit();
    }
  }
  change(valid?: boolean) {
    this.reRender = false;
    setTimeout(() => {
      this.reRender = true;
    }, 0);
  }
  save() {}
}
