import { Component, Input, OnInit, Output } from '@angular/core';
import {
  type,
  ProProfile,
  SelectedPro,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {
  @Input() selectedArray: number[] = [];
  @Input() selected: boolean = false;
  @Input() type: keyof type = 'jobFamily';
  @Input() data: ProProfile = { id: 0, displayName: '' };
  @Input() loop: number = 0;
  @Input() single: boolean = false;
  @Input() noIcon: boolean = false;
  @Input() maxSelection: number = -1;
  @Input() callbackFunction: (
    id: number,
    type: keyof type,
    single?: boolean
  ) => void = () => {};

  constructor() {}

  ngOnInit(): void {}
}
