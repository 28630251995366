import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  heading,
  section,
  selectCities,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import { ContextService } from 'src/services/platform/context.service';
import axios from 'axios';

@Component({
  selector: 'app-proProfile',
  templateUrl: './proProfile.component.html',
  styleUrls: ['./proProfile.component.scss'],
})
export class ProProfileComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  selectedProProfile = this.contextService.selectedProProfile;
  jobFamily = this.contextService.profileData.jobFamily;
  proProfileTitle: section = this.contextService.proProfileTitle()[0];
  cities: any = [];
  foundCities: any[];
  keySelected: number = 0;
  city: { displayName: string; state?: string; country?: string };
  searchChar: number = 2;
  @Input() steps: number;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}
  ngOnInit(): void {

    this.steps = 1;
    this.platformService.steps.subscribe((value) => {
      this.steps = value;
      this.proProfileTitle = this.contextService.proProfileTitle()[value - 1];
    });

    const value = this.contextService.selectedCity;
    this.city = {
      displayName: value.displayName,
      state: value.state,
      country: value.country,
    };

  }
  selected(value: any) {
    this.city = value;

    this.contextService.selectedCity = {
      displayName: value.displayName,
      state: value.state,
      country: value.country,
      lat: value.lat,
      lng: value.lng,
    };
  }

  selectedPro = (id: number, type: keyof type, single?: boolean): void => {
    if (single) {
      this.selectedProProfile[type] = [];
    }
    let findex = this.selectedProProfile[type].findIndex((x) => x === id);
    if (findex === -1) {
      this.selectedProProfile[type].push(id);
      return;
    }
    this.selectedProProfile[type].splice(findex, 1);
  };
}
