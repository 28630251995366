import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSliderChange, MatSliderModule } from '@angular/material/slider';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() value: number = 1;
  @Input() minLabel: string = '';
  @Input() maxLabel: string = '';
  @Input() title: string = '';
  @Input() disabled: boolean;
  @Output() change = new EventEmitter<number>();

  onChange(event: MatSliderChange) {
    this.change.emit(event.value!);
  }
}
