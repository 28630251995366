<div class="wrap-selection">

  <div class="selection">
    <app-header [languages]="languagesText"></app-header>
    <div class="add_language">
      <label class="label2">Selected languages<span class="caption">{{selected.length}}</span></label>
      <div class="selected" *ngFor="let sel of selected; let i = index">
        <app-dropdown [selected]="sel" placeholder="Select your preferred working language" trailingIcon="chevron-down"
          [options]="languages" (selectedCallback)="selectedValue($event, i)"></app-dropdown>
        <div (click)="removeLang(i)" class="remove-lang"><i-tabler name="trash"></i-tabler>
        </div>
      </div>
      <div class="select">
        <app-dropdown [searchable]="true" [selected]="{displayName:''}"
          placeholder="Select your preferred working language" trailingIcon="chevron-down" [options]="languages"
          (selectedCallback)="selectedValue($event)"></app-dropdown>
      </div>
    </div>
  </div>
</div>
