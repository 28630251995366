import { Component } from '@angular/core'
import { environment } from '../../../environments/environment'
@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityComponent {
  loading = true
  communityUrl = environment.communityUrl;
  constructor() {
    // window.addEventListener('message', this.receiveMessage.bind(this))
  }
  // receiveMessage(event: MessageEvent) {
  //   this.loading = event.data === 'loaded'
  // }
  open() {
    window.open(this.communityUrl)
  }
}
