<div [class]="{'navigation': true, 'mobile':mobile }">
  <ng-container *ngFor="let nav of navs; let i = index;">
    <app-navbar (click)="selectNav(nav.slug, nav.type)" [number]="!nav.icon ? (i+1)+'.': ''" [class]="getClasses(nav)"
      [label]="nav.displayName" [leadingIcon]="nav.icon ? nav.icon : ''" [leadingIcon]="nav.icon"
      [trailingIcon]="nav.state ? 'check' : ''" [disabled]="nav.disabled ?? false"></app-navbar>


    <!-- <div (click)="selectNav(nav.slug, nav.type)"
      [class]="{'active' : nav.slug === selectedNav, 'checkState':nav.state}" class="nav">
      <div class="wrap-nav">
        <span *ngIf="nav.icon" class="icon">
          <fa-icon [icon]="nav.icon"></fa-icon>
        </span>
        <span *ngIf="!nav.icon">
          {{nav.state ? nav.slug === selectedNav ? (i +1) + '.' : "" : (i +1) + '.'}}
        </span>
        <p>{{nav.displayName}}</p>
      </div>
    </div> -->
  </ng-container>
</div>
