export default {
  list: [
    {
      countryCode: 'en',
      id:2,
      displayName: 'English',
    },
    {
      countryCode: 'da',
      id:3,
      displayName: 'Danish',
    },
    {
      countryCode: 'aa',
      id:4,
      displayName: 'Afar',
    },
    {
      countryCode: 'ab',
      id:5,
      displayName: 'Abkhazian',
    },
    {
      countryCode: 'ae',
      id:6,
      displayName: 'Avestan',
    },
    {
      countryCode: 'af',
      id:7,
      displayName: 'Afrikaans',
    },
    {
      countryCode: 'ak',
      id:8,
      displayName: 'Akan',
    },
    {
      countryCode: 'am',
      id:9,
      displayName: 'Amharic',
    },
    {
      countryCode: 'an',
      id:10,
      displayName: 'Aragonese',
    },
    {
      countryCode: 'ar',
      id:11,
      displayName: 'Arabic',
    },
    {
      countryCode: 'as',
      id:12,
      displayName: 'Assamese',
    },
    {
      countryCode: 'av',
      id:13,
      displayName: 'Avaric',
    },
    {
      countryCode: 'ay',
      id:14,
      displayName: 'Aymara',
    },
    {
      countryCode: 'az',
      id:15,
      displayName: 'Azerbaijani',
    },
    {
      countryCode: 'ba',
      id:16,
      displayName: 'Bashkir',
    },
    {
      countryCode: 'be',
      id:17,
      displayName: 'Belarusian',
    },
    {
      countryCode: 'bg',
      id:18,
      displayName: 'Bulgarian',
    },
    {
      countryCode: 'bh',
      id:19,
      displayName: 'Bihari languages',
    },
    {
      countryCode: 'bi',
      id:20,
      displayName: 'Bislama',
    },
    {
      countryCode: 'bm',
      id:21,
      displayName: 'Bambara',
    },
    {
      countryCode: 'bn',
      id:22,
      displayName: 'Bengali',
    },
    {
      countryCode: 'bo',
      id:23,
      displayName: 'Tibetan',
    },
    {
      countryCode: 'br',
      id:24,
      displayName: 'Breton',
    },
    {
      countryCode: 'bs',
      id:25,
      displayName: 'Bosnian',
    },
    {
      countryCode: 'ca',
      id:26,
      displayName: 'Catalan; Valencian',
    },
    {
      countryCode: 'ce',
      id:27,
      displayName: 'Chechen',
    },
    {
      countryCode: 'ch',
      id:28,
      displayName: 'Chamorro',
    },
    {
      countryCode: 'co',
      id:29,
      displayName: 'Corsican',
    },
    {
      countryCode: 'cr',
      id:30,
      displayName: 'Cree',
    },
    {
      countryCode: 'cs',
      id:31,
      displayName: 'Czech',
    },
    {
      countryCode: 'cu',
      id:32,
      displayName:
        'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    },
    {
      countryCode: 'cv',
      id:33,
      displayName: 'Chuvash',
    },
    {
      countryCode: 'cy',
      id:34,
      displayName: 'Welsh',
    },

    {
      countryCode: 'de',
      id:35,
      displayName: 'German',
    },
    {
      countryCode: 'dv',
      id:36,
      displayName: 'Divehi; Dhivehi; Maldivian',
    },
    {
      countryCode: 'dz',
      id:37,
      displayName: 'Dzongkha',
    },
    {
      countryCode: 'ee',
      id:38,
      displayName: 'Ewe',
    },
    {
      countryCode: 'el',
      id:39,
      displayName: 'Greek, Modern',
    },
    {
      countryCode: 'eo',
      id:40,
      displayName: 'Esperanto',
    },
    {
      countryCode: 'es',
      id:41,
      displayName: 'Spanish; Castilian',
    },
    {
      countryCode: 'et',
      id:42,
      displayName: 'Estonian',
    },
    {
      countryCode: 'eu',
      id:43,
      displayName: 'Basque',
    },
    {
      countryCode: 'fa',
      id:44,
      displayName: 'Persian',
    },
    {
      countryCode: 'ff',
      id:45,
      displayName: 'Fulah',
    },
    {
      countryCode: 'fi',
      id:46,
      displayName: 'Finnish',
    },
    {
      countryCode: 'fj',
      id:47,
      displayName: 'Fijian',
    },
    {
      countryCode: 'fo',
      id:48,
      displayName: 'Faroese',
    },
    {
      countryCode: 'fr',
      id:49,
      displayName: 'French',
    },
    {
      countryCode: 'fy',
      id:50,
      displayName: 'Western Frisian',
    },
    {
      countryCode: 'ga',
      id:51,
      displayName: 'Irish',
    },
    {
      countryCode: 'gd',
      id:52,
      displayName: 'Gaelic; Scomttish Gaelic',
    },
    {
      countryCode: 'gl',
      id:53,
      displayName: 'Galician',
    },
    {
      countryCode: 'gn',
      id:54,
      displayName: 'Guarani',
    },
    {
      countryCode: 'gu',
      id:55,
      displayName: 'Gujarati',
    },
    {
      countryCode: 'gv',
      id:56,
      displayName: 'Manx',
    },
    {
      countryCode: 'ha',
      id:57,
      displayName: 'Hausa',
    },
    {
      countryCode: 'he',
      id:58,
      displayName: 'Hebrew',
    },
    {
      countryCode: 'hi',
      id:59,
      displayName: 'Hindi',
    },
    {
      countryCode: 'ho',
      id:60,
      displayName: 'Hiri Motu',
    },
    {
      countryCode: 'hr',
      id:61,
      displayName: 'Croatian',
    },
    {
      countryCode: 'ht',
      id:62,
      displayName: 'Haitian; Haitian Creole',
    },
    {
      countryCode: 'hu',
      id:63,
      displayName: 'Hungarian',
    },
    {
      countryCode: 'hy',
      id:64,
      displayName: 'Armenian',
    },
    {
      countryCode: 'hz',
      id:65,
      displayName: 'Herero',
    },
    {
      countryCode: 'ia',
      id:66,
      displayName: 'Interlingua (International Auxiliary Language Association)',
    },
    {
      countryCode: 'id',
      id:67,
      displayName: 'Indonesian',
    },
    {
      countryCode: 'ie',
      id:68,
      displayName: 'Interlingue; Occidental',
    },
    {
      countryCode: 'ig',
      id:69,
      displayName: 'Igbo',
    },
    {
      countryCode: 'ii',
      id:70,
      displayName: 'Sichuan Yi; Nuosu',
    },
    {
      countryCode: 'ik',
      id:71,
      displayName: 'Inupiaq',
    },
    {
      countryCode: 'io',
      id:72,
      displayName: 'Ido',
    },
    {
      countryCode: 'is',
      id:73,
      displayName: 'Icelandic',
    },
    {
      countryCode: 'it',
      id:74,
      displayName: 'Italian',
    },
    {
      countryCode: 'iu',
      id:75,
      displayName: 'Inuktitut',
    },
    {
      countryCode: 'ja',
      id:76,
      displayName: 'Japanese',
    },
    {
      countryCode: 'jv',
      id:77,
      displayName: 'Javanese',
    },
    {
      countryCode: 'ka',
      id:78,
      displayName: 'Georgian',
    },
    {
      countryCode: 'kg',
      id:79,
      displayName: 'Kongo',
    },
    {
      countryCode: 'ki',
      id:80,
      displayName: 'Kikuyu; Gikuyu',
    },
    {
      countryCode: 'kj',
      id:81,
      displayName: 'Kuanyama; Kwanyama',
    },
    {
      countryCode: 'kk',
      id:82,
      displayName: 'Kazakh',
    },
    {
      countryCode: 'kl',
      id:83,
      displayName: 'Kalaallisut; Greenlandic',
    },
    {
      countryCode: 'km',
      id:84,
      displayName: 'Central Khmer',
    },
    {
      countryCode: 'kn',
      id:85,
      displayName: 'Kannada',
    },
    {
      countryCode: 'ko',
      id:86,
      displayName: 'Korean',
    },
    {
      countryCode: 'kr',
      id:87,
      displayName: 'Kanuri',
    },
    {
      countryCode: 'ks',
      id:88,
      displayName: 'Kashmiri',
    },
    {
      countryCode: 'ku',
      id:89,
      displayName: 'Kurdish',
    },
    {
      countryCode: 'kv',
      id:90,
      displayName: 'Komi',
    },
    {
      countryCode: 'kw',
      id:91,
      displayName: 'Cornish',
    },
    {
      countryCode: 'ky',
      id:92,
      displayName: 'Kirghiz; Kyrgyz',
    },
    {
      countryCode: 'la',
      id:93,
      displayName: 'Latin',
    },
    {
      countryCode: 'lb',
      id:94,
      displayName: 'Luxembourgish; Letzeburgesch',
    },
    {
      countryCode: 'lg',
      id:95,
      displayName: 'Ganda',
    },
    {
      countryCode: 'li',
      id:96,
      displayName: 'Limburgan; Limburger; Limburgish',
    },
    {
      countryCode: 'ln',
      id:97,
      displayName: 'Lingala',
    },
    {
      countryCode: 'lo',
      id:98,
      displayName: 'Lao',
    },
    {
      countryCode: 'lt',
      id:99,
      displayName: 'Lithuanian',
    },
    {
      countryCode: 'lu',
      id:100,
      displayName: 'Luba-Katanga',
    },
    {
      countryCode: 'lv',
      id:101,
      displayName: 'Latvian',
    },
    {
      countryCode: 'mg',
      id:102,
      displayName: 'Malagasy',
    },
    {
      countryCode: 'mh',
      id:103,
      displayName: 'Marshallese',
    },
    {
      countryCode: 'mi',
      id:104,
      displayName: 'Maori',
    },
    {
      countryCode: 'mk',
      id:105,
      displayName: 'Macedonian',
    },
    {
      countryCode: 'ml',
      id:106,
      displayName: 'Malayalam',
    },
    {
      countryCode: 'mn',
      id:107,
      displayName: 'Mongolian',
    },
    {
      countryCode: 'mr',
      id:108,
      displayName: 'Marathi',
    },
    {
      countryCode: 'ms',
      id:109,
      displayName: 'Malay',
    },
    {
      countryCode: 'mt',
      id:110,
      displayName: 'Maltese',
    },
    {
      countryCode: 'my',
      id:111,
      displayName: 'Burmese',
    },
    {
      countryCode: 'na',
      id:112,
      displayName: 'Nauru',
    },
    {
      countryCode: 'nb',
      id:113,
      displayName: 'Bokmål, Norwegian; Norwegian Bokmål',
    },
    {
      countryCode: 'nd',
      id:114,
      displayName: 'Ndebele, North; North Ndebele',
    },
    {
      countryCode: 'ne',
      id:115,
      displayName: 'Nepali',
    },
    {
      countryCode: 'ng',
      id:116,
      displayName: 'Ndonga',
    },
    {
      countryCode: 'nl',
      id:117,
      displayName: 'Dutch; Flemish',
    },
    {
      countryCode: 'nn',
      id:118,
      displayName: 'Norwegian Nynorsk; Nynorsk, Norwegian',
    },
    {
      countryCode: 'no',
      id:119,
      displayName: 'Norwegian',
    },
    {
      countryCode: 'nr',
      id:120,
      displayName: 'Ndebele, South; South Ndebele',
    },
    {
      countryCode: 'nv',
      id:121,
      displayName: 'Navajo; Navaho',
    },
    {
      countryCode: 'ny',
      id:122,
      displayName: 'Chichewa; Chewa; Nyanja',
    },
    {
      countryCode: 'oc',
      id:123,
      displayName: 'Occitan',
    },
    {
      countryCode: 'oj',
      id:124,
      displayName: 'Ojibwa',
    },
    {
      countryCode: 'om',
      id:125,
      displayName: 'Oromo',
    },
    {
      countryCode: 'or',
      id:126,
      displayName: 'Oriya',
    },
    {
      countryCode: 'os',
      id:127,
      displayName: 'Ossetian; Ossetic',
    },
    {
      countryCode: 'pa',
      id:128,
      displayName: 'Panjabi; Punjabi',
    },
    {
      countryCode: 'pi',
      id:129,
      displayName: 'Pali',
    },
    {
      countryCode: 'pl',
      id:130,
      displayName: 'Polish',
    },
    {
      countryCode: 'ps',
      id:131,
      displayName: 'Pushto; Pashto',
    },
    {
      countryCode: 'pt',
      id:132,
      displayName: 'Portuguese',
    },
    {
      countryCode: 'qu',
      id:133,
      displayName: 'Quechua',
    },
    {
      countryCode: 'rm',
      id:134,
      displayName: 'Romansh',
    },
    {
      countryCode: 'rn',
      id:135,
      displayName: 'Rundi',
    },
    {
      countryCode: 'ro',
      id:136,
      displayName: 'Romanian; Moldavian; Moldovan',
    },
    {
      countryCode: 'ru',
      id:137,
      displayName: 'Russian',
    },
    {
      countryCode: 'rw',
      id:138,
      displayName: 'Kinyarwanda',
    },
    {
      countryCode: 'sa',
      id:139,
      displayName: 'Sanskrit',
    },
    {
      countryCode: 'sc',
      id:140,
      displayName: 'Sardinian',
    },
    {
      countryCode: 'sd',
      id:141,
      displayName: 'Sindhi',
    },
    {
      countryCode: 'se',
      id:142,
      displayName: 'Northern Sami',
    },
    {
      countryCode: 'sg',
      id:143,
      displayName: 'Sango',
    },
    {
      countryCode: 'si',
      id:144,
      displayName: 'Sinhala; Sinhalese',
    },
    {
      countryCode: 'sk',
      id:145,
      displayName: 'Slovak',
    },
    {
      countryCode: 'sl',
      id:146,
      displayName: 'Slovenian',
    },
    {
      countryCode: 'sm',
      id:147,
      displayName: 'Samoan',
    },
    {
      countryCode: 'sn',
      id:148,
      displayName: 'Shona',
    },
    {
      countryCode: 'so',
      id:149,
      displayName: 'Somali',
    },
    {
      countryCode: 'sq',
      id:150,
      displayName: 'Albanian',
    },
    {
      countryCode: 'sr',
      id:151,
      displayName: 'Serbian',
    },
    {
      countryCode: 'ss',
      id:152,
      displayName: 'Swati',
    },
    {
      countryCode: 'st',
      id:153,
      displayName: 'Sotho, Southern',
    },
    {
      countryCode: 'su',
      id:154,
      displayName: 'Sundanese',
    },
    {
      countryCode: 'sv',
      id:155,
      displayName: 'Swedish',
    },
    {
      countryCode: 'sw',
      id:156,
      displayName: 'Swahili',
    },
    {
      countryCode: 'ta',
      id:157,
      displayName: 'Tamil',
    },
    {
      countryCode: 'te',
      id:158,
      displayName: 'Telugu',
    },
    {
      countryCode: 'tg',
      id:159,
      displayName: 'Tajik',
    },
    {
      countryCode: 'th',
      id:160,
      displayName: 'Thai',
    },
    {
      countryCode: 'ti',
      id:161,
      displayName: 'Tigrinya',
    },
    {
      countryCode: 'tk',
      id:162,
      displayName: 'Turkmen',
    },
    {
      countryCode: 'tl',
      id:163,
      displayName: 'Tagalog',
    },
    {
      countryCode: 'tn',
      id:164,
      displayName: 'Tswana',
    },
    {
      countryCode: 'to',
      id:165,
      displayName: 'Tonga (Tonga Islands)',
    },
    {
      countryCode: 'tr',
      id:166,
      displayName: 'Turkish',
    },
    {
      countryCode: 'ts',
      id:167,
      displayName: 'Tsonga',
    },
    {
      countryCode: 'tt',
      id:168,
      displayName: 'Tatar',
    },
    {
      countryCode: 'tw',
      id:169,
      displayName: 'Twi',
    },
    {
      countryCode: 'ty',
      id:170,
      displayName: 'Tahitian',
    },
    {
      countryCode: 'ug',
      id:171,
      displayName: 'Uighur; Uyghur',
    },
    {
      countryCode: 'uk',
      id:172,
      displayName: 'Ukrainian',
    },
    {
      countryCode: 'ur',
      id:173,
      displayName: 'Urdu',
    },
    {
      countryCode: 'uz',
      id:174,
      displayName: 'Uzbek',
    },
    {
      countryCode: 've',
      id:175,
      displayName: 'Venda',
    },
    {
      countryCode: 'vi',
      id:176,
      displayName: 'VietdisplayNamese',
    },
    {
      countryCode: 'vo',
      id:177,
      displayName: 'Volapük',
    },
    {
      countryCode: 'wa',
      id:178,
      displayName: 'Walloon',
    },
    {
      countryCode: 'wo',
      id:179,
      displayName: 'Wolof',
    },
    {
      countryCode: 'xh',
      id:180,
      displayName: 'Xhosa',
    },
    {
      countryCode: 'yi',
      id:181,
      displayName: 'Yiddish',
    },
    {
      countryCode: 'yo',
      id:182,
      displayName: 'Yoruba',
    },
    {
      countryCode: 'za',
      id:183,
      displayName: 'Zhuang; Chuang',
    },
    {
      countryCode: 'zh',
      id:184,
      displayName: 'Chinese',
    },
    {
      countryCode: 'zu',
      id:1,
      displayName: 'Zulu',
    },
  ],
}
