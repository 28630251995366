import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LandingPageComponent } from './landingPage/landingPage.component';
import { RegistrationComponent } from './signup/registration/registration.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { DesignSystemComponent } from './design-system/design-system.component';
import { ClaimingComponent } from './claiming/claiming.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent, data: { page: '' } },
  {
    path: 'design-system',
    component: DesignSystemComponent,
    data: { page: 'design-system' },
  },
  {
    path: 'applyhere/:postslug',
    component: LandingPageComponent,
    data: { page: 'applyhere' },
  },
  {
    path: 'opportunity/:id',
    component: ClaimingComponent,
    data: { page: 'jobpportunity' },
  },
  {
    path: 'profile',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'community',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },

  {
    path: 'profile-flow',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  // {
  //   path: 'profile/:postslug',
  //   component: ProfileComponent,
  //   data: { page: 'Profile' },
  //   canActivate: [MsalGuard],
  // },
  { path: 'signup', component: SignupComponent, data: { page: 'Sign up' } },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: { page: 'Sign up' },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // or 'top'
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64], // [x, y] - adjust scroll offset
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
