import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() back: boolean = true;
  constructor(private router: Router) { }
  clearStorage(){
    if(this.back){
      this.router.navigate(['/'],{ queryParamsHandling: 'preserve' })
      sessionStorage.removeItem('selectedNav');
    }
  }
  ngOnInit(): void {
  }

}
