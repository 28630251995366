import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { RegistrationComponent } from './signup/registration/registration.component';
import { ProProfileComponent } from './signup/registration/proProfile/proProfile.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalInterceptorConfiguration,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';

import { msalConfig } from './auth-config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingPageComponent } from './landingPage/landingPage.component';
import { LogoComponent } from './logo/logo.component';
import { ButtonsComponent } from './core-components/buttons/buttons.component';
import { NavigationComponent } from './dashboard/navigation/navigation.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { GameassessmentsComponent } from './dashboard/profile/steps/game-assessments/game-assessments.component';
import { RolesComponent } from './dashboard/profile/steps/roles/roles.component';
import { SweetSpotComponent } from './dashboard/profile/steps/sweet-spot/sweet-spot.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ExperienceComponent } from './dashboard/profile/steps/experience/experience.component';
import { TopSkillsComponent } from './dashboard/profile/steps/top-skills/top-skills.component';
import { AllSkillsComponent } from './dashboard/profile/steps/all-skills/all-skills.component';
import { MatSliderModule } from '@angular/material/slider';
import { TechnologiesComponent } from './dashboard/profile/steps/technologies/technologies.component';
import { LanguagesComponent } from './dashboard/profile/steps/languages/languages.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxSelectComponent } from './checkboxSelect/checkboxSelect.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { LocationComponent } from './dashboard/profile/steps/location/location.component';
import { SelectComponent } from './core-components/select/select.component';
import { IncomeComponent } from './dashboard/profile/steps/income/income.component';
import { HomeComponent } from './dashboard/home/home.component';
import { ProfessionalProfileComponent } from './dashboard/profile/steps/professional-profile/professional-profile.component';
import { SubmitComponent } from './dashboard/profile/steps/submit/submit.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CtaComponent } from './cta/cta.component';
import { WelcomeComponent } from './dashboard/profile/steps/welcome/welcome.component';
import { SafePipe } from './safe.pipe';
import { DialogComponent } from './dialog/dialog.component';
import { HeadingComponent } from './heading/heading.component';
import { MyProfileComponent } from './dashboard/my-profile/my-profile.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { environment } from 'src/environments/environment';
import { MatomoModule } from 'ngx-matomo';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ApplyHereComponent } from './apply-here/apply-here.component';
import { CommunityComponent } from './dashboard/community/community.component';
import { LoadingComponent } from './loading/loading.component';
import { ActivityCardsComponent } from './activity-cards/activity-cards.component';
import { JobHistoryComponent } from './dashboard/profile/steps/experience/job-history/job-history.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MonthYearSelectComponent } from './core-components/month-year-select/month-year-select.component';
import { IconsModule } from './icons/icons.module';
import { ButtonComponent } from './core-components/button/button.component';
import { InputComponent } from './core-components/input/input.component';
import { DesignSystemComponent } from './design-system/design-system.component';
import { StepsComponent } from './core-components/steps/steps.component';
import { ProgressBarsComponent } from './core-components/progress-bars/progress-bars.component';
import { NavbarComponent } from './core-components/navbar/navbar.component';
import { SliderComponent } from './core-components/slider/slider.component';
import { WidgetsComponent } from './core-components/widgets/widgets.component';
import { InfoBoxComponent } from './core-components/info-box/info-box.component';
import { SelectItemsComponent } from './core-components/select-items/select-items.component';
import { DropdownComponent } from './core-components/dropdown/dropdown.component';
import { CountryDropdowComponent } from './core-components/country-dropdow/country-dropdow.component';
import { HeaderComponent } from './dashboard/profile/steps/header/header.component';
import { ClaimingComponent } from './claiming/claiming.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
    'https://7n20staging.b2clogin.com/user.read',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['https://7n20staging.b2clogin.com/user.read'],
    },
    loginFailedRoute: '/',
  };
}

Sentry.init({
  dsn: environment.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: environment.tracePropagationTargets,
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    RegistrationComponent,
    ProProfileComponent,
    LandingPageComponent,
    LogoComponent,
    ButtonsComponent,
    DashboardComponent,
    NavigationComponent,
    ProfileComponent,
    GameassessmentsComponent,
    RolesComponent,
    SweetSpotComponent,
    ExperienceComponent,
    TopSkillsComponent,
    AllSkillsComponent,
    TechnologiesComponent,
    LanguagesComponent,
    CheckboxSelectComponent,
    ClickOutsideDirective,
    LocationComponent,
    SelectComponent,
    IncomeComponent,
    HomeComponent,
    ProfessionalProfileComponent,
    SubmitComponent,
    CtaComponent,
    WelcomeComponent,
    SafePipe,
    DialogComponent,
    HeadingComponent,
    MyProfileComponent,
    ApplyHereComponent,
    CommunityComponent,
    LoadingComponent,
    ActivityCardsComponent,
    JobHistoryComponent,
    ButtonComponent,
    InputComponent,
    DesignSystemComponent,
    StepsComponent,
    ProgressBarsComponent,
    NavbarComponent,
    SliderComponent,
    WidgetsComponent,
    InfoBoxComponent,
    SelectItemsComponent,
    DropdownComponent,
    MonthYearSelectComponent,
    CountryDropdowComponent,
    HeaderComponent,
    ClaimingComponent,
  ],
  imports: [
    IconsModule,
    NgImageSliderModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgxSliderModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatomoModule.forRoot({
      scriptUrl: '//cdn.matomo.cloud/syvntyve.matomo.cloud/matomo.js',
      trackers: [
        {
          trackerUrl: 'https://syvntyve.matomo.cloud/matomo.php',
          siteId: environment.matomo,
        },
      ],
      routeTracking: {
        enable: true,
      },
    }),
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -15,
      outerStrokeWidth: 15,
      outerStrokeLinecap: 'butt',
      innerStrokeWidth: 15,
      animateTitle: false,
      responsive: true,
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
