<div class="wrap-selection">

  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages.headline}}
      </h1>
      <p class="body2"> {{languages.description}}</p>
    </div>  -->
    <app-header [languages]="languages"></app-header>
    <div>
      <p class="label2"> {{languages.title}}<span class="caption">{{selectedProProfile.topSkills.length}}</span> </p>
      <div [class]=" {'nonSelected': selectedProProfile.topSkills.length===0, 'skills' : true}">
        <div *ngFor="let sel of devider" [class]="{'row': devider.length === 1}">
          <app-buttons *ngFor="let level of sel; let i = index" [single]="false" [maxSelection]="5"
            [selectedArray]="selectedProProfile.topSkills" [selected]="selectedProProfile.topSkills.includes(level.id)"
            [callbackFunction]="selectedPro" type="topSkills" [loop]="i" [data]="level">
          </app-buttons>
        </div>
      </div>
    </div>
  </div>
</div>
