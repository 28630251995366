/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser'
import { environment } from '../environments/environment'
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signup: 'B2C_1_signup',
    signin: 'B2C_1_signin',
    editProfile: 'B2C_1_edit_profile',
  },
  authorities: {
    SignUpSignIn: {
      authority: environment.b2c + environment.tenant + '/B2C_1_SignUpSignIn',
    },
    signup: {
      authority: environment.b2c + environment.tenant + '/B2C_1_signup',
    },
    signin: {
      authority: environment.b2c + environment.tenant + '/B2C_1_signin',
    },
    editProfile: {
      authority: environment.b2c + environment.tenant + '/B2C_1_edit_profile',
    },
    forgotPassword: {
      authority: environment.b2c + environment.tenant + '/B2C_1_reset',
    },
  },
  authorityDomain: environment.b2c,
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId, // This is the ONLY mandatory field that you need to supply. //38c8f5ed-521d-4093-b0f9-3bc78c98cb7f
    authority: sessionStorage.getItem('signup')
      ? b2cPolicies.authorities.signup.authority
      : b2cPolicies.authorities.signin.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {},
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    loadFrameTimeout: 90000,
  },
}

export const authScopes = ['openid', 'offline_access']
export const appAccessScopes: any = environment.scopes
