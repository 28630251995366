<div class="wrap-selection">
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="">
        {{languages.available.headline}}
      </h1>
      <p class="body2"> {{languages.available.description}}</p>
    </div> -->
    <app-header [languages]="languages.income.available"></app-header>
    <!-- <app-month-year-select *ngIf="list.type === 'available'" [value]="list.selected[0]" [type]="'start'"
      (callbackFunction)="selectedValue($event)" [placeholder]="'Available on'"></app-month-year-select> -->

    <app-month-year-select label="When would you be able to start?" trailingIcon="calendar"
      [value]="availabilty.availabilityDate" [placeholder]="'Select starting month'" [type]="'availabilityDate'"
      (callbackFunction)="setMonthYear($event)"></app-month-year-select>

    <div class="workTime">
      <app-buttons
        *ngFor="let workTime of [{id:1, displayName: 'Full-time'},{id:2, displayName: 'Part-time'}]; let i = index"
        [selectedArray]="selectedProProfile.workTime" [selected]="selectedProProfile.workTime.includes(workTime.id)"
        [callbackFunction]="selectedPro" type="workTime" [loop]="i" [data]="workTime">
      </app-buttons>
    </div>
    <!-- <div class="when-start">
      <p class="body2"> {{languages.available.startdate}}</p>

      <div [class]="{'nonSelected':  !selectedStartDate.DD || !selectedStartDate.MM || !selectedStartDate.YY}">

        <app-select [options]="[]" [placeholder]="'D'" [loading]="true" [data]="startDate.dd"
          (selected)="selectStartDate($event, 'DD')" [value]="selectedStartDate.DD">
        </app-select>
        <app-select [options]="[]" [placeholder]="'MM'" [loading]="true" [data]="startDate.mm"
          (selected)="selectStartDate($event, 'MM')" [value]="selectedStartDate.MM">
        </app-select>
        <app-select [options]="[]" [placeholder]="'YY'" [loading]="true" [data]="startDate.years"
          (selected)="selectStartDate($event, 'YY')" [value]="selectedStartDate.YY">
        </app-select>
      </div>
    </div> -->
  </div>
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title3">
        {{languages.income.salary.headline}}
      </h1>
      <p class="body2"> {{languages.income.salary.description}}</p>
    </div> -->
    <app-header [languages]="languages.income.salary"></app-header>

    <div [class]="{'nonSelected': selectedProProfile.rate.length === 0, 'rate': true}">
      <p class="label1"> {{languages.income.salary.title}}</p>
      <div class="rate">
        <app-buttons *ngFor="let level of proProfile.rate; let i = index" [single]="true" [maxSelection]="2"
          [selectedArray]="selectedProProfile.rate" [selected]="selectedProProfile.rate.includes(level.id)"
          [callbackFunction]="selectedPro" type="rate" [loop]="i" [data]="level">
        </app-buttons>
      </div>
    </div>
    <div class="salary">
      <app-input [options]="currency" [option]="{displayName: selectedSalery.currency}"
        [value]="selectedSalery.salary ? selectedSalery.salary : ''" [label]="'Your expected salary'"
        [placeholder]="'What is your expected salary?'" dropdown="trailing" combined="trailing" (input)="salary($event)"
        (selectedCallback)="selectCurrency($event)">
      </app-input>
      <!-- <div [class]="{'nonSelected':  selectedSalery.salary === 0, 'expected': true}">
        <p> {{languages.income.salary.expected}}</p>
        <input [value]="selectedSalery.salary ? selectedSalery.salary : ''" (input)="salary($event)"
          (keydown)="onlyNumber($event)" />
      </div>
      <div [class]="{'nonSelected':  !selectedSalery.currency, 'currency': true}">
        <p> {{languages.income.salary.currency}}</p>
        <app-select [options]="['currency_code']" [split]="3" [placeholder]="'Select currency'" [loading]="true"
          [data]="currency" (selected)="selectCurrency($event)" [value]="selectedSalery.currency">
        </app-select>
      </div> -->
    </div>
  </div>
</div>
