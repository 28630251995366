<div class="wrap-selection">
  <div class="selection">
    <app-header [languages]="languages.location"></app-header>
    <!-- <div class="headline">
      <h1 class="">
        {{languages.location.headline}}
      </h1>
      <p class="body2">{{languages.location.description}}</p>
    </div> -->
    <app-country-dropdow [searchable]="true" (selectedCallback)="selected($event)" leadingIcon="map-pin"
      trailingIcon="chevron-down" [selected]="city"></app-country-dropdow>

    <!-- <app-select [type]="'cities'" [class]="{'nonSelected': !city }" [options]="['name', 'state','country']" [searchChar]="2"
      [placeholder]="'Search for your city...'" [data]="cities" (selected)="selectCity($event)" [value]="city">
    </app-select> -->
  </div>
  <div class="selection">
    <app-header [languages]="languages.work"></app-header>
    <!-- <div class="headline">
      <h1 class="">
        {{languages.work.headline}}
      </h1>
      <p class="body2">{{languages.work.headline}}</p>
    </div> -->
    <div [class]="{'nonSelected':  selectedProProfile.workFrom.length === 0, 'work-from': true}">
      <app-buttons *ngFor="let work of proProfile.workFrom; let i = index"
        [selected]="selectedProProfile.workFrom.includes(work.id)" [callbackFunction]="selectedPro" type="workFrom"
        [loop]="i" [single]="false" [data]="work">
      </app-buttons>


    </div>
  </div>
  <!-- <div class="selection">
    <div class="headline">
      <h1 class="">
        {{languages.available.headline}}
      </h1>
      <p class="body2"> {{languages.available.description}}</p>
    </div>

    <div class="when-start">
      <p class="body2"> {{languages.available.startdate}}</p>

      <div [class]="{'nonSelected':  !selectedStartDate.DD || !selectedStartDate.MM || !selectedStartDate.YY}">

        <app-select [options]="[]" [placeholder]="'D'" [loading]="true" [data]="startDate.dd"
          (selected)="selectStartDate($event, 'DD')" [value]="selectedStartDate.DD">
        </app-select>
        <app-select [options]="[]" [placeholder]="'MM'" [loading]="true" [data]="startDate.mm"
          (selected)="selectStartDate($event, 'MM')" [value]="selectedStartDate.MM">
        </app-select>
        <app-select [options]="[]" [placeholder]="'YY'" [loading]="true" [data]="startDate.years"
          (selected)="selectStartDate($event, 'YY')" [value]="selectedStartDate.YY">
        </app-select>
      </div>
    </div>
  </div> -->
</div>
