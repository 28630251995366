import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRightFromLine } from '@fortawesome/pro-regular-svg-icons';
import { ContextService } from 'src/services/platform/context.service';
import { navigationMenu } from 'src/ts/interfaces/profile.interface';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements OnInit {
  faArrowRightFromLine = faArrowRightFromLine as IconProp;
  @Input() openSidePanel = false;
  @Input() selectedNav = '';
  @Input() showPageName: boolean;
  @Output() callbackFunction = new EventEmitter<any>();
  @Input() counter = {
    length: 0,
    current: 0,
  };
  languages: any;
  agent: any;

  constructor(private contextService: ContextService) {}

  ngOnInit(): void {
    this.languages = this.contextService.languages.leftPanelSignup;
    this.agent = this.contextService.languages.agent;
  }
  toggleSide() {
    this.callbackFunction.emit();
  }
}
