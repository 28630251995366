<div (window:resize)="onResized($event)" *ngIf="!loading  && !edit"
  [class]="'profile content '+( openSidePanel ? 'open' : 'close')">
  <div *ngIf="selectedNav !== 'submit' && !edit"
    [class]="{'left-content':true, 'open': openSidePanel, 'close':!openSidePanel, 'hide':isSubmited}">
    <div>
      <app-heading [counter]="counter" [openSidePanel]="openSidePanel" (callbackFunction)="toggleSide()"></app-heading>
      <app-navigation [selectedNav]="selectedNav" [navs]="navs" [type]="'profile-flow'"></app-navigation>
      <div class="bottom">

      </div>
    </div>
  </div>
  <div #rightContent [class]="'right-content' ">
    <div *ngIf="selectedNav !== 'submit'" class="top">
      <div [class]="{'active': !hideMessage}" class="message">
        <p [class]="message" class="baseline_small">{{messages[message]}}</p>
      </div>
      <div class="later-step">
        <p (click)="home()" class="finish link">{{contextService.later}}</p>
        <!-- <div class="steps">{{stepNumber}} / <span>{{navs.length}}</span></div> -->
      </div>
    </div>
    <div class="wrap-middle-btn">
      <div class="middle">
        <div *ngIf="!edit" class="steps">
          <div *ngIf="selectedNav === stepsMenuSlug.welcome && !isSubmited" class="start-profile">
            <app-welcome (callbackFunction)="next()"></app-welcome>
          </div>
          <app-game-assessments (callbackFunction)="click($event)" *ngIf="selectedNav === stepsMenuSlug.game">
          </app-game-assessments>
          <app-roles *ngIf="selectedNav === stepsMenuSlug.roles"></app-roles>
          <app-all-skills *ngIf="selectedNav === stepsMenuSlug.allSkills"></app-all-skills>
          <app-top-skills *ngIf="selectedNav === stepsMenuSlug.topSkills"></app-top-skills>
          <app-sweet-spot *ngIf="selectedNav === stepsMenuSlug.sweetspot"></app-sweet-spot>
          <app-experience *ngIf="selectedNav === stepsMenuSlug.experience"></app-experience>
          <app-technologies *ngIf="selectedNav === stepsMenuSlug.technologies"></app-technologies>
          <app-languages [lang]="lang" *ngIf="selectedNav === stepsMenuSlug.languages"></app-languages>
          <app-location *ngIf="selectedNav === stepsMenuSlug.location"></app-location>
          <app-income *ngIf="selectedNav === stepsMenuSlug.income"></app-income>
          <app-professional-profile *ngIf="selectedNav === stepsMenuSlug.profPro"></app-professional-profile>
          <div *ngIf="isSubmited" class="submitted">
            <div>
              <div class="agent">
                <img src="../../../assets/images/agent.png" />
              </div>
              <h1 class="title3">{{languages.headline}}</h1>
              <p class="body1">{{languages.description}} </p>
            </div>
            <app-button [style]="'min-width: 168;'" (click)="home()" [class]="['primary']" [label]="languages.cta">
            </app-button>


          </div>
          <div *ngIf="selectedNav === stepsMenuSlug.finish && !isSubmited" class="start-profile ">
            <app-submit *ngIf="selectedNav === stepsMenuSlug.finish"></app-submit>
            <app-cta *ngIf="selectedNav === stepsMenuSlug.finish" (callbackFunction)="click($event)"
              [buttons]="buttons">
            </app-cta>
          </div>
          <div class="steps-button" *ngIf="selectedNav !== '' && selectedNav !== 'submit' && selectedNav !== stepsMenuSlug.game &&
          selectedNav !==stepsMenuSlug.welcome && selectedNav !== stepsMenuSlug.finish">
            <app-cta (callbackFunction)="click($event)" [buttons]="buttons">
            </app-cta>
            <app-cta (callbackFunction)="click($event)" [buttons]="skip">
            </app-cta>
          </div>

          <div class="spinner" *ngIf="selectedNav === 'submit' && !isSubmited">
            <fa-icon [spin]="true" [icon]="faSpinner"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="edit" class="edit">
  <div class="top-bar">
    <div [class]="{'active': !hideMessage}" class="message">
      <p [class]="message" class="baseline_small">{{messages[message]}}</p>
    </div>
    <app-cta (callbackFunction)="click($event, toEdit)" [buttons]="editButtons">
    </app-cta>
  </div>
  <div *ngIf="edit === stepsMenuSlug.roles">
    <app-roles></app-roles>
    <app-all-skills></app-all-skills>
    <app-top-skills></app-top-skills>
  </div>

  <div *ngIf="edit === stepsMenuSlug.sweetspot">
    <app-sweet-spot></app-sweet-spot>
  </div>
  <div *ngIf="edit === stepsMenuSlug.experience">
    <app-experience></app-experience>
  </div>
  <div *ngIf="edit === stepsMenuSlug.technologies">
    <app-technologies></app-technologies>
  </div>
  <div *ngIf="edit === stepsMenuSlug.languages">
    <app-languages [lang]="lang"></app-languages>
  </div>
  <div *ngIf="edit === stepsMenuSlug.location">
    <app-location></app-location>
  </div>
  <div *ngIf="edit === stepsMenuSlug.income">
    <app-income></app-income>
  </div>
  <div *ngIf="edit === stepsMenuSlug.profPro">
    <app-professional-profile></app-professional-profile>
  </div>
</div>
