import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { jobHistory } from 'src/ts/interfaces/profile.interface';

@Component({
  selector: 'app-month-year-select',
  templateUrl: './month-year-select.component.html',
  styleUrls: ['./month-year-select.component.scss'],
})
export class MonthYearSelectComponent implements OnChanges {
  @Input() value?: string;
  @Input() type: string = '';
  @Input() label: string = '';
  @Input() index: number = -1;
  @Input() placeholder: string = '';
  @Input() trailingIcon: string = '';
  @Input() disabled: boolean;

  @Output() callbackFunction = new EventEmitter<{
    type: string;
    date: string;
    index: number;
  }>();

  months: any[] = this.helperFunction.listOfOptions('stringMonth');
  year: number = new Date().getFullYear();
  selectedDate = new Date();
  selectedPicker = {
    type: '',
    index: -1,
  };
  monthYear: string = '';

  constructor(private helperFunction: HelperFunctionsService) {}

  ngOnChanges(): void {
    this.setSelectedDate();
  }

  changeYear(value: number) {
    this.year = this.year + value;
    const monthIndex = this.selectedDate.getMonth() + 1;
    this.value =
      this.year +
      '-' +
      (monthIndex < 10 ? '0' + monthIndex : monthIndex) +
      '-' +
      '01';
    this.setSelectedDate(true);
  }
  lastday(y: number, m: number) {
    return new Date(y, m, 0).getDate();
  }
  selectMonth(monthIndex: number = this.selectedDate.getMonth()) {
    monthIndex = monthIndex + 1;
    this.value =
      this.year +
      '-' +
      (monthIndex < 10 ? '0' + monthIndex : monthIndex) +
      '-' +
      (this.type === 'end' ? this.lastday(this.year, monthIndex) : '01');
    this.setSelectedDate(true);
  }

  setSelectedDate(change?: boolean, event?: Event) {
    if (event) {
      const element = event.currentTarget as HTMLInputElement;
      if (element.value !== this.monthYear) {
        this.value = '';
      }
    }
    if (this.value) {
      this.selectedDate = new Date(this.value ?? '');
      this.monthYear =
        this.months[this.selectedDate.getMonth()] +
        ', ' +
        this.selectedDate.getFullYear();
    }
    if (!this.value) {
      this.monthYear = '';
    }

    if (change) {
      this.callbackFunction.emit({
        type: this.type,
        date: this.value!,
        index: this.index,
      });
    }
  }

  openPicker() {
    if (!this.disabled) {
      this.selectedPicker = {
        type: this.type,
        index: this.index,
      };
    }
  }

  closePicker() {
    this.selectedPicker = {
      type: '',
      index: -1,
    };
  }
}
