import { ChangeDetectorRef, Component } from '@angular/core';
import { PlatformService } from 'src/services/platform/platform.service';

import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import axios from 'axios';
import {
  ruleLength,
  SelectedIndustries,
  SelectedPro,
} from 'src/ts/interfaces/profile.interface';
import { ContextService } from 'src/services/platform/context.service';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  cookiesSet = false;
  constructor(
    private platformService: PlatformService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private contextService: ContextService,
    private route: ActivatedRoute,
    private matomoTracker: MatomoTracker
  ) {
    const event = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url.split('?');
        const queryParams = url[1];
        if (queryParams) {
          queryParams.split('&').forEach((elm) => {
            const split = elm.split('=');
            if (this.contextService.validMatomo.includes(split[0])) {
              sessionStorage.setItem('queryParams', queryParams);
              sessionStorage.setItem('campaign', split[1]);
            }
          });
        } else {
          const session = sessionStorage.getItem('queryParams');

          if (session) {
            if (queryParams !== session) {
              if (sessionStorage.getItem('signupOrSignin')) {
                const eachQuery = session.split('&');
                let queryParams: any = {};
                for (let i = 0; i < eachQuery.length; i++) {
                  queryParams[eachQuery[i].split('=')[0]] =
                    eachQuery[i].split('=')[1];
                }

                this.router.navigate([url[0]], {
                  queryParams,
                });
                sessionStorage.removeItem('queryParams');
              }
            }
          }
        }
        event.unsubscribe();
        sessionStorage.removeItem('signupOrSignin');
      }
    });
    event.add();
  }

  title = this.contextService.title();

  handleCookies(value: string) {
    localStorage.setItem('cookies', value);
    this.cookiesSet = true;
    if (value === '0') {
      this.matomoTracker.deleteCookies();
    }
  }

  ngOnInit() {
    if (
      ['opportunity.7n20.cloud', 'opportunity.7n.com'].includes(
        window.location.hostname
      ) &&
      window.location.pathname.slice(1).split('/')
    ) {
      if (window.location.pathname.slice(1).split('/')[0] !== 'opportunity') {
        document.location.href = 'https://my.7n.com/';
      }
    }
    if (localStorage.getItem('cookies')) {
      this.handleCookies(localStorage.getItem('cookies') ?? '0');
    }
    this.authenticationService.loginDisplay.subscribe((val) => {
      this.route.queryParams.subscribe((params) => {
        if (params['signin'] !== undefined) {
          this.authenticationService.signin();
        }
        if (params['step'] !== undefined) {
          this.platformService.changeStep(JSON.parse(params['step']));
        }
      });
      this.router.events.subscribe((value) => {
        if (value instanceof NavigationEnd) {
          if (value.url === '/' && val) {
            this.router.navigate(['/home'], {
              queryParamsHandling: 'preserve',
            });
          }
        }
      });
    });
    this.authenticationService.initialize();
  }
}
