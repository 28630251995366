import { Component, OnInit } from '@angular/core'
import { navigationMenu } from 'src/ts/interfaces/profile.interface'
import { PlatformService } from 'src/services/platform/platform.service'
import { ContextService } from 'src/services/platform/context.service'

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss'],
})
export class SubmitComponent implements OnInit {
  missing: navigationMenu[] = []
  text = {
    submit: {
      h1: 'You are done',
      paragraph:
        'I really appreciate you taking the time to complete your profile. Last step is to submit it, and I will review it and get back to you within a couple of days.',
    },
    notReady: {
      h1: 'You are almost ready to submit..',
      paragraph:
        'I need you to fill out the missing steps before you can submit:',
    },
  }
  languages = this.contextService.languages.submit
  sevenNConsulatant = this.contextService.sevenNConsulatant;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService,
  ) {}

  ngOnInit(): void {
    this.contextService.stepsMenu.map((element, index) => {
      element.id = index + 1
      if (element.slug === 'submit-for-approval') {
        setTimeout(() => {
          element.state = this.missing.length === 0
        })
      }
      if (
        !element.state &&
        element.slug !== 'submit-for-approval' &&
        element.slug !== 'finish-profile' &&
        element.rule === 'required'
      ) {
        this.missing.push(element)
      }
      return element
    })
  }
}
