<div class="options">
    <div *ngFor="let l of data">
        <div [class]="{'selected': selected.includes(l.value)}" #option (click)="callbackFunction(l.value, type); validateChoices(l.id, type)" class="option">
            <div *ngIf="multiselect">
                <input [checked]="selected.includes(l.value)" type="checkbox" />
                <span class="checkmark"></span>
            </div>
            <p class="body2">{{l.label}}</p>
        </div>
    </div>
</div>
