import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss'],
})
export class SelectItemsComponent {
  @Input() isChecked: boolean;
  @Input() type: selectItem;
  @Output() change = new EventEmitter<MatSlideToggleChange>();
}
type selectItem = 'toggle' | 'checkbox' | 'radio' | 'select';
