import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import applyhere from '../../assets/applyhere'

@Component({
  selector: 'app-apply-here',
  templateUrl: './apply-here.component.html',
  styleUrls: ['./apply-here.component.scss'],
})
export class ApplyHereComponent implements OnInit {
  @Input() postslug: string = ''
  applyText: any[] = []
  constructor(private router: Router) {

  }
  getKey(object: any) {
    return Object.keys(object)[0]
  }
  signup() {
    this.router.navigate(['/signup'], { queryParamsHandling: 'preserve' })
  }
  ngOnInit(): void {
    let findex = applyhere.findIndex((x) => x.postslug.toLocaleLowerCase() === this.postslug.toLocaleLowerCase())
    if (findex > -1) {
      this.applyText = applyhere[findex].text
    } else {
      this.router.navigate([''], { queryParamsHandling: 'preserve' })
    }
  }
}
