import { Component, Input } from '@angular/core';
import { classes } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() leadingIcon: string = '';
  @Input() trailingIcon: string = '';
  @Input() number: string = '';
  @Input() label: string = '';
  @Input() amount: string = '';
  @Input() class: classes[] = [];
  @Input() disabled: boolean;
}
