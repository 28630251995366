import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import {
  ProProfile,
  ruleLength,
  selectedCurrency,
  SelectedPro,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import { ContextService } from 'src/services/platform/context.service';
import { environment } from 'src/environments/environment';

const currency = require('src/assets/currency.json');
@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
})
export class IncomeComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  selectedProProfile = this.contextService.selectedProProfile;
  selectedSalery = this.contextService.selectedSalery;
  currency = currency;
  selectedCurrency: selectedCurrency = {
    displayName: '',
  };
  ruleLength: ruleLength = {
    rate: 1,
    workTime: 1,
    salary: 1,
  };
  languages = this.contextService.languages;

  availabilty = this.contextService.selectedStartDate;

  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
  }
  setMonthYear(event: { type: string; date: string; index: number }) {
    this.availabilty.availabilityDate = event.date;
    this.contextService.selectedStartDate.availabilityDate =
      this.availabilty.availabilityDate;
    if (!isNaN(Date.parse(this.availabilty.availabilityDate))) {
      this.contextService.validSelection['startDate'] = true;
    }
    this.validateChoices();
  }

  onlyNumber(e: KeyboardEvent) {
    const charCode = e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  salary(e: Event) {
    const element = e.target as HTMLInputElement;
    if (element.value === '') {
      element.value = '0';
    }
    this.selectedSalery.salary = JSON.parse(element.value);
    this.contextService.selectedSalery = this.selectedSalery;

    if (this.selectedSalery.salary > 0) {
      this.contextService.validSelection['salary'] = true;
    } else {
      this.contextService.validSelection['salary'] = false;
    }
    this.validateChoices();
  }
  selectCurrency(e: selectedCurrency) {
    this.selectedCurrency = e;
    this.selectedSalery.currency = e ? e.displayName : '';
    if (this.selectedSalery.currency.length > 0) {
      this.contextService.validSelection['currency'] = true;
    } else {
      this.contextService.validSelection['currency'] = false;
    }
    this.validateChoices();
  }

  validateChoices(type?: string) {
    if (type) {
      this.contextService.validSelection[type] = false;
      if (
        this.contextService.selectedProProfile[type as keyof SelectedPro]
          .length >= this.ruleLength[type]
      ) {
        this.contextService.validSelection[type] = true;
      }
    }
    this.platformService.reRender.next('income');
  }

  selectedPro = (id: number, type: keyof type, single?: boolean): void => {
    let findex = this.selectedProProfile[type].findIndex((x) => x === id);

    if (single) {
      this.selectedProProfile[type] = [];
    }

    if (findex === -1) {
      this.selectedProProfile[type].push(id);
    } else {
      this.selectedProProfile[type].splice(findex, 1);
    }
    this.validateChoices(type);
  };
}
