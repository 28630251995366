<div class="wrap-selection">

  <div class="selection">
    <!-- <div class="headline">
      <div>
        <h1 class="title3">
          {{languages.jobHistory.headline}}
        </h1>
        <p class="body2">{{languages.jobHistory.description}}</p>
      </div>
    </div> -->
    <app-header [languages]="languages.jobHistory"></app-header>
    <div class="wrap-job-history">
      <!-- <p class="note baseline_small">{{languages.jobHistory.note.noNeed}}</p> -->
      <app-job-history (callbackFunction)="validateChoices($event)"></app-job-history>
    </div>
  </div>


  <!-- <div class="selection">
    <div class="headline">
      <h1 class="">
        {{languages.industries.headline}}
      </h1>
      <p class="body2">{{languages.industries.description}}</p>
    </div>

    <div [class]="{'nonSelected':  selectedIndustries.industries.length === 0, 'yearExp': true}">
      <app-buttons *ngFor="let indus of industries; let i = index"
        [selected]="selectedIndustries.industries.includes(indus) || getIndustry(indus)"
        [callbackFunction]="selectedPro" type="industries" [loop]="i" [single]="false" [data]="indus"></app-buttons>

    </div>
  </div>

  <div class="selection">
    <div class="headline">
      <h1 class="">
        {{languages.rankIndustries.headline}}
      </h1>
      <p class="body2">{{languages.rankIndustries.description}}</p>
    </div>

    <div class="rank">
      <div (drop)="rankDrop($event, i)" (dragover)="allowDrop($event)"
        *ngFor="let indus of selectedIndustries.industries; let i = index;">
        <span>{{i+1}}.</span>
        <div [draggable]="true" (dragstart)="rankDragStart($event, i)" draggable="true" class="drag">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div>
          {{indus.displayName}}
          <span class="selectArrow">
            <fa-icon [icon]="faChevronDown"></fa-icon>
          </span>
          <select [class]="indus.selected?.experience === '' ? 'disabled nonSelected': ''" id="years"
            (change)="selectYearExperience($event, indus)">
            <option [value]="-1" disabled [selected]="indus.selected?.experience === ''">How many years?</option>
            <option *ngFor="let year of proProfile.yearExperience" [value]="year.displayName"
              [selected]="indus.selected?.experience === year.displayName">{{ year.displayName }}
            </option>
          </select>
        </div>
      </div>

    </div>
  </div> -->

</div>
