<div class="select-items">
  <div *ngIf="type === 'toggle'">
    <mat-slide-toggle (change)="change.emit($event)" [(ngModel)]="isChecked"></mat-slide-toggle>
  </div>
  <div class="radio" *ngIf="type === 'radio'">
    <label class="container">
      <input [checked]="isChecked" type="checkbox">
      <span class="checkmark"></span>
    </label>
  </div>
  <div class="checkbox" *ngIf="type === 'checkbox'">
    <label class="container">
      <input [checked]="isChecked" type="checkbox">
      <span class="checkmark">
        <i-tabler name="check"></i-tabler>
      </span>
    </label>
  </div>
</div>
