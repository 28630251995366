<div class="slider" [class]="{'disabled': disabled}">
  <p class="body2">{{title}}</p>
  <div>
    <mat-slider (click)="change.emit(value)" (change)="onChange($event)" class="default-slider" min="1" max="3"
      [value]="value" step="1">
      <input matSliderThumb>
    </mat-slider>
    <div>
      <p class="caption">{{minLabel}}</p>
      <p class="caption">{{maxLabel}}</p>
    </div>
  </div>
</div>
