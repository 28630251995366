import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';

@Component({
  selector: 'app-professional-profile',
  templateUrl: './professional-profile.component.html',
  styleUrls: ['./professional-profile.component.scss'],
})
export class ProfessionalProfileComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  selectedProfProfile = this.contextService.selectedProfProfile;
  languages = this.contextService.languages.professional;
  rule: string;
  constructor(
    private platformService: PlatformService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.contextService.validSelection['profProfile'] = true;
    const findStep = this.contextService.stepsMenu.find(
      (x) => x.slug === 'professional-profiles'
    );
    if (findStep) {
      this.rule = findStep.rule;
    }
  }

  input(event: Event, id: number) {
    const element = event.target as HTMLInputElement;
    let findex = this.proProfile.profile.findIndex((x) => x.id === id);
    if (findex !== -1) {
      this.proProfile.profile[findex].value = element.value;
    }
  }

  validate(id: number) {
    let findex = this.proProfile.profile.findIndex((x) => x.id === id);
    let findexSelect = this.selectedProfProfile.findIndex((x) => x.id === id);
    if (findexSelect !== -1) {
      this.selectedProfProfile[findexSelect].name =
        this.proProfile.profile[findex].value!;
    } else {
      this.selectedProfProfile.push({
        id,
        name: this.proProfile.profile[findex].value!,
      });
    }
  }

  getValue(id: number) {
    let findex = this.selectedProfProfile.findIndex((x) => x.id === id);
    if (findex !== -1) {
      return this.selectedProfProfile[findex].name;
    }
    return '';
  }
}
