import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import {
  ruleLength,
  selectCities,
  SelectedPro,
  selectStartDate,
  type,
} from 'src/ts/interfaces/profile.interface';

import { PlatformService } from 'src/services/platform/platform.service';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';

// const cities = require('src/assets/cities.json')
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  cities = [];

  showSearch: boolean = false;
  proProfile = this.contextService.proProfile();
  selectedProProfile = this.contextService.selectedProProfile;
  ruleLength: ruleLength = {
    workFrom: 1,
  };
  foundCities: selectCities[];
  keySelected = 0;
  city: { displayName: string; state?: string; country?: string };

  selectedStartDate = this.contextService.selectedStartDate.availabilityDate;

  startDate = {
    dd: this.helperFunctions.listOfOptions('', 31),
    mm: this.helperFunctions.listOfOptions('month'),
    years: this.helperFunctions.listOfOptions('years', 10),
  };
  languages = this.contextService.languages.location;
  constructor(
    private platformService: PlatformService,
    private helperFunctions: HelperFunctionsService,
    private contextService: ContextService
  ) {
    if (this.contextService.selectedCity) {
      this.selected(this.contextService.selectedCity);
    }
  }

  ngOnInit(): void {}
  selected(value: any) {
    this.city = {
      displayName: value.displayName,
      state: value.state,
      country: value.country,
    };
    // if (!value) {
    //   this.city = value;
    //   return;
    // }
    // if (value) {
    //   this.city.displayName =
    //     value.name + ' - ' + value.state + ' - ' + value.country;
    //   this.cities = [{ displayName: this.city }];
    // }

    this.contextService.selectedCity = {
      displayName: value.displayName,
      state: value.state,
      country: value.country,
      lat: value.lat,
      lng: value.lng,
    };
  }

  // selectCity(value: selectCities) {
  //   if (value) {
  //     this.city = value.name + ' - ' + value.state + ' - ' + value.country;
  //   }
  //   this.contextService.selectedCity = value;
  //   this.contextService.validSelection['city'] = this.city ? true : false;
  //   this.platformService.reRender.next('location');
  // }

  // selectStartDate(value: string) {
  //   this.selectedStartDate = value;
  //   this.contextService.validSelection['startDate'] = false;
  //   if (this.selectedStartDate) {
  //     this.contextService.validSelection['startDate'] = true;
  //   }
  //   this.platformService.reRender.next('location');
  // }

  validateChoices(type: string) {
    this.contextService.validSelection[type] = false;
    if (
      this.contextService.selectedProProfile[type as keyof SelectedPro]
        .length >= this.ruleLength[type]
    ) {
      this.contextService.validSelection[type] = true;
    }
  }

  selectedPro = (id: number, type: keyof type, single?: boolean): void => {
    if (single) {
      this.contextService.selectedProProfile[type] = [];
    }
    let findex = this.contextService.selectedProProfile[type].findIndex(
      (x) => x === id
    );
    if (findex === -1) {
      this.contextService.selectedProProfile[type].push(id);
    } else {
      this.contextService.selectedProProfile[type].splice(findex, 1);
    }
    this.validateChoices(type);
    this.platformService.reRender.next('location');
  };
}
