import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})

export class WelcomeComponent implements OnInit {
  @Output() callbackFunction = new EventEmitter<any>()
  languages = this.contextService.languages.welcome
  constructor(private contextService: ContextService) {

   }

  ngOnInit(): void {
  }

  next(){
    this.callbackFunction.emit()
  }

}
