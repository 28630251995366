<div class="wrap-selection">
  <div class="selection">
    <!-- <div class="headline">
      <h1 class="title2">
        {{languages.headline}}
      </h1>
      <p class="body2"> {{languages.description}}</p>
    </div> -->
    <app-header [languages]="languages"></app-header>
    <div>
      <div class="headline">
        <p class="label2"> {{languages.title}}<span class="caption">{{selectedProProfile.skills.length}}</span></p>
      </div>
      <div [class]="{'nonSelected':  selectedProProfile.skills.length === 0, 'skills': true}">
        <div *ngFor="let dev of this.devider" [class]="{'row': devider.length === 1}">
          <app-buttons *ngFor="let level of dev; let i = index" [single]="false"
            [selected]="selectedProProfile.skills.includes(level.id)" [callbackFunction]="selectedPro" type="skills"
            [loop]="i" [data]="level">
          </app-buttons>
        </div>
      </div>
    </div>
  </div>
</div>
