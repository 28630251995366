<div class="navigation-btn">

  <app-button *ngFor="let button of buttons" (click)="click(button)"
    [class]="[button.class ? button.class  : 'primary']" [disable]="button.disable ?? false" [label]="button.title"
    [style]="'min-width:168px;'"></app-button>
  <!-- <div *ngFor="let button of buttons"
    [class]="'btn '+(button.class ? button.class  : '') +(button.disable ? ' disable': '')"
    (click)="click(button.type)">
    <p>{{button.title}}</p>
  </div> -->
  <!-- <p *ngIf="isSafari" class="baseline_small">{{text}}</p> -->

</div>
