import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import {
  ruleLength,
  select,
  SelectedPro,
} from 'src/ts/interfaces/profile.interface'
import { PlatformService } from 'src/services/platform/platform.service'
import { ContextService } from 'src/services/platform/context.service'

@Component({
  selector: 'app-checkboxSelect',
  templateUrl: './checkboxSelect.component.html',
  styleUrls: ['./checkboxSelect.component.scss'],
})
export class CheckboxSelectComponent implements OnInit {
  @Input() data: select[] = [{ label: '', value: '', id: -1 }]
  @Input() multiselect: boolean = false
  @Input() type: keyof SelectedPro
  @Input() selected: string[] = []
  @Input() callbackFunction: (
    value: string,
    type: keyof SelectedPro,
  ) => void = () => {}
  ruleLength: ruleLength = {
    languages: 1,
  }

  constructor(private platformService: PlatformService,
    private contextService: ContextService
    ) {}

  validateChoices(id: number, type: keyof SelectedPro) {
    this.contextService.validSelection[type] = false
    let findex = this.contextService.selectedProProfile[type].findIndex(
      (x) => x === id,
    )
    if (findex === -1) {
      this.contextService.selectedProProfile[type].push(id)
    } else {
      this.contextService.selectedProProfile[type].splice(findex, 1)
    }
    if (
      this.contextService.selectedProProfile[type].length >=
      this.ruleLength[type]
    ) {
      this.contextService.validSelection[type] = true
    }
  }
  ngOnInit(): void {}
}
