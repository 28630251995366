export const environment = {
  production: false,
  apiUrl: 'https://api.staging.7N20.io',
  tenant: '7n20staging.onmicrosoft.com',
  b2c: 'https://7n20staging.b2clogin.com/',
  clientId: '0d98e563-98d4-4e36-8571-f4ba31becc28',
  hj: '3312434',
  matomo: 2,
  scopes: [
    {
      api: 'skills',
      scope:
        'https://7n20staging.onmicrosoft.com/ebbcfba2-0987-4dd7-b848-2115d08eb782/App.Access',
    },
    {
      api: 'industries',
      scope:
        'https://7n20staging.onmicrosoft.com/3fa689b7-5b20-4368-971e-7c39018bb926/App.Access',
    },
    {
      api: 'genome',
      scope:
        'https://7n20staging.onmicrosoft.com/105ae1a0-6db6-416b-905c-39864ef55f50/App.Access',
    },
    {
      api: 'assessments',
      scope:
        'https://7n20staging.onmicrosoft.com/6548e185-459d-438c-84ec-b860cca26817/App.Access',
    },
    {
      api: 'community',
      scope:
        'https://7n20staging.onmicrosoft.com/dcd332ae-3c2c-4843-a400-f53d3ea22c95/App.Access',
    },
  ],
  container: 'https://consultantportalnjla.blob.core.windows.net',
  tracePropagationTargets: ['https://app.7n20.io/', 'localhost:4200'],
  dsn:
    'https://8959b0da1bf0456a8129c0701addb4ab@o4504593336631296.ingest.sentry.io/4504594004770816',
  communityUrl:"https://chat.7n.community",
}
