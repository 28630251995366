<div *ngIf="type ==='field' && !combined" class="field" [class]="{'disabled':disabled}">
  <label *ngIf="label" class="label2">{{label}}</label>
  <div>
    <input (blur)="blur.emit($event)" [placeholder]="placeholder" [class]="{'trailingIcon': trailingIcon}" class="body1"
      [value]="value" [disabled]="disabled" />
    <i-tabler *ngIf="trailingIcon" [name]="trailingIcon"></i-tabler>
  </div>
</div>
<div class="box">
  <div *ngFor="let box of inputBox; let i = index">
    <input (blur)="blur.emit($event)" #box [name]="i" (keydown)="validate($event)" (input)="input($event)"
      (paste)="paste($event)" class="title1" [placeholder]="placeholder" [value]="box > 0 ? box:''"
      [disabled]="disabled" />
  </div>
</div>
<div *ngIf="type === 'calendar'" [class]="type">
  <div>
    <input [class]="{'trailingIcon': trailingIcon}" matInput (dateChange)="selectedCallback.emit($event)"
      [matDatepicker]="picker" (click)="picker.open()">
    <i-tabler *ngIf="trailingIcon" [name]="trailingIcon"></i-tabler>
    <mat-datepicker #picker></mat-datepicker>
  </div>
</div>
<div *ngIf="type === 'textarea'" [class]="type">
  <textarea (input)="selectedCallback.emit($event)" [placeholder]="placeholder" class="body1"></textarea>
</div>
<div *ngIf="combined && options" class="field combined" [class]="combined">
  <label [class]="{'disabled':disabled}" class="label2">{{label}}</label>
  <div>
    <app-dropdown trailingIcon="chevron-down" *ngIf="combined === 'leading'" [selected]="option"
      [options]="options"></app-dropdown>
    <input (blur)="blur.emit($event)" [placeholder]="placeholder" class="body1" [value]="value" [disabled]="disabled" />
    <app-dropdown (selectedCallback)="selectedCallback.emit($event)" trailingIcon="chevron-down"
      *ngIf="combined === 'trailing'" [selected]="option" [options]="options"></app-dropdown>
  </div>
</div>
<div *ngIf="combined && !options" class="field combined" [class]="combined">
  <label [class]="{'disabled':disabled}" *ngIf="label" class="label2">{{label}}</label>
  <div>
    <i-tabler *ngIf="combined === 'leading'" [name]="icon"></i-tabler>
    <input (blur)="blur.emit($event)" [placeholder]="placeholder" class="body1" [value]="value" [disabled]="disabled" />
    <i-tabler *ngIf="combined === 'trailing'" [name]="icon"></i-tabler>
  </div>
</div>
