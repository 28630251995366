<!-- 'btn':true,
'btn-select':true,
'btn-dark':selected,

'disabled': maxSelection <= selectedArray.length && maxSelection !== -1,
'radio' : single,
'noIcon' : maxSelection === 2 -->
<div class="buttons" (click)="callbackFunction(data.id, type, single)" [class]="{
  'active': selected,
  'description': data.description,
  'disabled': maxSelection <= selectedArray.length && maxSelection !== -1,
  }">

  <app-select-items *ngIf="!noIcon" [type]="single ?'radio' :'checkbox'" [isChecked]="selected"></app-select-items>
  <div>
    <p class="label2">{{ data.displayName }}</p>
    <p class="body2" *ngIf="data.description">{{data.description}}</p>
  </div>
</div>
