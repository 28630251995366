import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api/api.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-claiming',
  templateUrl: './claiming.component.html',
  styleUrls: ['./claiming.component.scss'],
})
export class ClaimingComponent {
  respond: {
    id: number;
    moreInfo?: { description: string; availability: string };
    response: '';
  } = {
    id: -1,
    moreInfo: {
      description: '',
      availability: '',
    },
    response: '',
  };
  loading: boolean;
  actions: {
    id: number;
    displayName: string;
    moreInfo?: any[];
    response: string;
  }[] = [
    {
      id: 1,
      displayName: 'Yes, it’s a good match & I’m available',
      response: 'GoodMatchAndAvailable',
    },
    {
      id: 2,
      displayName: 'Yes, it’s a good match but I’m not available',
      moreInfo: [
        { headline: 'When would you be available?', type: 'calendar' },
      ],
      response: 'GoodMatchButNotAvailable',
    },
    {
      id: 3,
      displayName: 'I am available, but it’s not a good match',
      moreInfo: [
        {
          headline: 'Help us understand why it’s not a good match',
          subtitle: 'So we can match you better in the future',
          type: 'input',
        },
      ],
      response: 'NotGoodMatchAndAvailable',
    },
    {
      id: 4,
      displayName: 'I am not available & it’s not a good match',
      moreInfo: [
        {
          headline: 'Help us understand why it’s not a good match',
          subtitle: 'So we can match you better in the future',
          type: 'input',
        },
        {
          headline: 'When would you be available for another match?',
          type: 'calendar',
        },
      ],
      response: 'NotGoodMatchAndNotAvailable',
    },
  ];
  readyForSubmit = false;
  title = '';
  description = '';
  firstName = '';
  submitted: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((val) => {
      val = val as { id: string };
      if (val['id']) {
        if (this.tokenExpired(val['id'])) {
          this.submitted = -1;
          return;
        }
        this.loading = true;
        this.apiService
          .getAxios(
            environment.apiUrl +
              '/matchingapp/v2/declarationofinterest?token=' +
              val['id']
          )
          .then((response) => {
            if (response) {
              this.title = response.title;
              this.description = response.description;
              this.firstName = response.fullName
                ? response.fullName.split(' ')[0]
                : '';
              this.loading = false;
              return;
            }
            window.location.href = 'https://my.7n.com';
          });
      }
    });
  }
  tokenExpired(token: string) {
    const splitToken = token.split('.');
    if (splitToken.length > 1) {
      const expiry = JSON.parse(atob(splitToken[1])).exp;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    }
    return;
  }

  input(event: Event, type: 'description' | 'availability') {
    const target = event.target as HTMLInputElement;
    if (type === 'availability') {
      target.value = new Date(target.value).toISOString();
    }
    this.respond.moreInfo![type] = target.value;
    if (this.respond.id === 2 && this.respond.moreInfo?.availability) {
      this.readyForSubmit = true;
    }
    if (this.respond.id === 3 && this.respond.moreInfo?.description) {
      this.readyForSubmit = true;
    }
    if (
      this.respond.id === 4 &&
      this.respond.moreInfo?.availability
    ) {
      this.readyForSubmit = true;
    }
  }
  selectInterest(id: number) {
    this.respond.id = id;
    this.readyForSubmit = false;
    this.respond.moreInfo = {
      description: '',
      availability: '',
    };
    if ([1, 3].includes(id)) {
      this.readyForSubmit = true;
    }
  }
  submit() {
    const find = this.actions.find((x) => x.id === this.respond.id);
    if (!find) {
      return;
    }
    const declarationofinterest = {
      response: find.response,
      responseText: this.respond.moreInfo?.description ?? '',
      declaredAvailabilty: this.respond.moreInfo?.availability ?? '',
    };
    this.submitted = find.id;
    Date.prototype.toJSON = function () {
      return moment(this).format();
    };
    this.activatedRoute.params.subscribe((val) => {
      val = val as { id: string };
      if (val['id']) {
        this.apiService
          .postAxios(
            environment.apiUrl +
              '/matchingapp/v2/declarationofinterest?token=' +
              val['id'],
            declarationofinterest
          )
          .then((response: any) => {
            if (response.status === 202) {
              this.submitted = find.id;
            }
          });
      }
    });
  }
}
