<div class="steps">
  <!-- <div class="step">
    <div class="body2">
      <span>Step {{ steps }}</span>
      <span class="steps-length">/5</span>
    </div>
  </div> -->
  <div *ngIf="steps === 1" class="wrap-selection">
    <div class="headline">
      <h1 class="subtitle1">{{ proProfileTitle.section[0].title }}</h1>
      <p class="body2">{{ proProfileTitle.section[0].description }}</p>
    </div>
    <div class="selection">
      <div *ngFor="let job of jobFamily; let i = index">
        <app-buttons [selected]="selectedProProfile.jobFamily.includes(job.id)" [callbackFunction]="selectedPro"
          type="jobFamily" [loop]="i" [single]="true" [data]="job"></app-buttons>
      </div>
    </div>
    <div class="headline">
      <h1 class="subtitle1">{{ proProfileTitle.section[1].title }}</h1>
      <p class="body2">{{ proProfileTitle.section[1].description }}</p>
    </div>
    <div class="selection">
      <app-buttons *ngFor="let level of proProfile.levelWork; let i = index" [single]="true"
        [selected]="selectedProProfile.levelWork.includes(level.id)" [callbackFunction]="selectedPro" type="levelWork"
        [loop]="i" [data]="level">
      </app-buttons>
    </div>
  </div>
  <div *ngIf="steps === 2" class="wrap-selection">
    <div class="headline">
      <h1 class="subtitle1">{{ proProfileTitle.section[0].title }}</h1>
      <p class="body2">{{ proProfileTitle.section[0].description }}</p>
    </div>
    <div class="selection">
      <div *ngFor="let yearExp of proProfile.yearExperience; let i = index">
        <app-buttons [selected]="selectedProProfile.yearExperience.includes(yearExp.id)"
          [callbackFunction]="selectedPro" type="yearExperience" [loop]="i" [single]="true" [data]="yearExp">
        </app-buttons>
      </div>
    </div>
    <div class="headline">
      <h1 class="subtitle1">{{ proProfileTitle.section[1].title }}</h1>
      <p class="body2">{{ proProfileTitle.section[1].description }}</p>
    </div>
    <div class="selection">
      <div *ngFor="let asConsult of proProfile.asConsultant; let i = index">
        <app-buttons [selected]="selectedProProfile.asConsultant.includes(asConsult.id)" [maxSelection]="2"
          [callbackFunction]="selectedPro" type="asConsultant" [loop]="i" [single]="true" [data]="asConsult">
        </app-buttons>

      </div>
    </div>
  </div>
  <div *ngIf="steps === 3" class="wrap-selection">
    <div class="headline">
      <h1 class="subtitle1">{{ proProfileTitle.section[0].title }}</h1>
      <p class="body2">{{ proProfileTitle.section[0].description }}</p>
    </div>
    <div class="selection">
      <app-country-dropdow [searchable]="true" placeholder="Search your city" (selectedCallback)="selected($event)" trailingIcon="chevron-down" leadingIcon="map-pin"
        trailingIcon="chevron-down" [selected]="city"></app-country-dropdow>

      <!-- <app-select [type]="'cities'" [class]="{'nonSelected': !city }" [options]="['name', 'state','country']"
        [searchChar]="2" [placeholder]="'Search for your city...'" [data]="cities" (selected)="selectCity($event)"
       >
      </app-select> -->
    </div>
  </div>
</div>
