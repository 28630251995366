<div class="job-history">
  <div class="positions">
    <div class="position" *ngFor="let job of jobHistory; let i = index">

      <div class="top">
        <p class="body2">Position</p>

        <div class="flip">
          <p class="baseline_small">Current position</p>
          <app-select-items type="toggle" [isChecked]="job.current" (change)="flip($event, i)"
            (click)="prevent($event, i)"></app-select-items>
        </div>

      </div>
      <div class="wrap-sides">
        <div class="left">
          <div>
            <div>
              <app-input label="Role" placeholder="Role title"
                [class]="{'nonSelected': job.error?.includes('role') ?? false}" (blur)="validate($event, 'role', i)"
                (input)="input($event, i, 'role')" [value]="job.role"></app-input>
            </div>

          </div>
          <div>
            <div>

              <app-input label="Company" placeholder="Company name"
                [class]="{'nonSelected': job.error?.includes('company') ?? false}"
                (blur)="validate($event, 'company', i)" (input)="input($event, i, 'company')"
                [value]="job.company"></app-input>
            </div>
            <div>
              <!-- <p class="baseline_small">Industry</p> -->
              <app-dropdown placeholder="Select an industry"
                [class]="{'nonSelected': job?.error?.includes('industryId') ?? false}" trailingIcon="chevron-down"
                label="Industry" [selected]="getSelectedIndustry(job.industryId, i)" [options]="industries"
                (selectedCallback)="selectIndustry($event, i)"></app-dropdown>

              <!-- <app-select [class]="{'nonSelected': job?.error?.includes('industryId') ?? false}"
                [options]="['displayName']" [placeholder]="'Industry'" [loading]="true" [data]="industries"
                (selected)="selectIndustry($event, i)" [value]="getSelectedIndustry(job.industryId)">
              </app-select> -->
            </div>
          </div>
        </div>
        <div class="right">


          <div class="start">
            <app-month-year-select trailingIcon="calendar" placeholder="Select the month you started" label="Start"
              [class]="{'nonSelected':job.error?.includes('start') ?? false}" [value]="job.start" [type]="'start'"
              [index]="i" (blur)="validate($event, 'start', i)"
              (callbackFunction)="setMonthYear($event)"></app-month-year-select>
          </div>
          <div class="end">
            <app-month-year-select trailingIcon="calendar" [disabled]="job.current"
              placeholder="Select the month you finished" label="End" [value]="job.end" [type]="'end'" [index]="i"
              (blur)="validate($event, 'end', i)" (callbackFunction)="setMonthYear($event)"></app-month-year-select>
          </div>



        </div>
      </div>
      <div class="remove">
        <div (click)="removeJob(i)">
          <i-tabler name="trash"></i-tabler>
          <!-- <fa-icon [icon]="faTrashCan"></fa-icon> -->
          <p class="baseline_small">Remove position</p>
        </div>
      </div>
    </div>
    <div class="addPosition">
      <app-button (click)="addPosition()" [class]="['inverted']" leadingIcon="plus"
        [label]="'Add position'"></app-button>
    </div>
  </div>
</div>
