import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { nav } from 'src/ts/interfaces/profile.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { classes } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input() navs: nav[] = [];
  @Output() step = new EventEmitter<string>();
  @Input() selectedNav: string;
  @Input() type: string;

  mobile = false;
  constructor(private router: Router, private route: ActivatedRoute) {
    window.onresize = () => (this.mobile = window.innerWidth <= 991);
  }
  getClasses(nav: nav) {
    let classes: classes[] = [nav.state ? 'completed' : 'default'];
    if (nav.slug === this.selectedNav) {
      classes.push('active');
    }
    if (nav.disabled) {
      classes.push('disabled');
    }
    if(this.type === 'profile-flow'){
      classes.push('no-hover')
    }
    return classes;
  }
  selectNav(slug: string, type?: string) {
    if (this.navs.some((x) => x.disabled && x.slug === slug)) {
      return;
    }
    if (this.type !== 'profile-flow') {
      this.step.emit(slug);
      this.selectedNav = slug;
      window.scrollTo(0, 0);
      sessionStorage.setItem(this.type, slug);
      if (this.type === 'dashboard') {
        this.router.navigate([slug], { queryParamsHandling: 'preserve' });
      }
    }
  }

  ngOnInit(): void {
    if (this.type === 'dashboard') {
      this.selectedNav = sessionStorage.getItem(this.type) ?? 'home';
    } else {
      this.selectedNav = sessionStorage.getItem(this.type) ?? 'welcome';
    }
  }
}
