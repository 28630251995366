<div *ngIf="!loading" class="registration-component">
  <div class="left-content">
    <div class="top">
      <app-logo></app-logo>
      <div class="wrap-greeting">
        <div class="agent">
          <div [class]="{'animate': steps === 1}">
            <div class="balloon-name balloon">{{agent.name}}</div>
            <img src="../../../assets/images/agent.png" />
          </div>
        </div>
        <div class="greeting">
          <h3 class="title3">{{languages.headline}}</h3>
          <p class="body2">
            {{languages.description}}
          </p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <app-progress-bars [steps]="progress"></app-progress-bars>
    </div>
  </div>
  <div class="right-content">
    <app-proProfile [steps]="steps" *ngIf="!loading"></app-proProfile>
    <div class="navigation-btn">
      <app-button [style]="'min-width: 168px;'" (click)="step(steps - 1, 'previous')" [class]="['secondary']"
        [label]="'Previous'"></app-button>
      <app-button [style]="'min-width: 168px;'" (click)="step(steps + 1)" [class]="['primary']"
        [label]="'Next'"></app-button>
    </div>
  </div>
</div>
