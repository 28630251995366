<div class="activity-cards">
  <div class="headline">
    <p class="body2">Notification for you - <span>{{getDate()}}</span></p>
  </div>
  <div class="cards"
    [class]="{'grid-one':filterDisabledCard(cards).length === 1, 'grid-two':filterDisabledCard(cards).length === 2, 'grid-three':filterDisabledCard(cards).length === 3, 'grid-four':filterDisabledCard(cards).length === 4, 'grid-five':filterDisabledCard(cards).length === 5}">
    <ng-container *ngFor="let card of filterDisabledCard(cards)">
      <div *ngIf="!card.disable" [class]="card.backgroundColor">
        <div class="image">
          <div class="shadow"></div>
          <img *ngIf="card.backgroundImage" [src]="card.backgroundImage" />
        </div>
        <div class="head">
          <div class="tag">
            <p class="baseline_small">{{card.tag}}</p>
          </div>
        </div>
        <div class="bottom">
          <div class="text">
            <h2 class="subtitle2">{{card.headline}}</h2>
            <p class="body2">{{card.description}}</p>
          </div>
          <app-button (click)="click(card.btnSlug)" [class]="['inverted-white']" [label]="card.btnText"></app-button>
        </div>

      </div>
    </ng-container>
  </div>

</div>
