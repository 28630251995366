import { Component, OnInit } from '@angular/core';
import {
  ProProfile,
  ruleLength,
  SelectedPro,
  type,
} from 'src/ts/interfaces/profile.interface';
import { PlatformService } from 'src/services/platform/platform.service';
import { ContextService } from 'src/services/platform/context.service';

@Component({
  selector: 'app-top-skills',
  templateUrl: './top-skills.component.html',
  styleUrls: ['./top-skills.component.scss'],
})
export class TopSkillsComponent implements OnInit {
  proProfile = this.contextService.proProfile();
  skills = this.contextService.profileData.skills;
  jobFamily = this.contextService.profileData.jobFamily;
  selectedProProfile = this.contextService.selectedProProfile;
  devider: ProProfile[][] = [];
  selectedSkills: ProProfile[] = [];
  ruleLength: ruleLength = {
    topSkills: 1,
  };

  languages = this.contextService.languages.topSkills;
  constructor(
    private contextService: ContextService,
    private platformService: PlatformService
  ) {
    this.filterSkills();
    this.platformService.reRender.subscribe((val) => {
      if (val === 'skills') {
        this.filterSkills();
      }
    });
  }
  filterSkills() {
    this.selectedProProfile.jobFamily.forEach((id) => {
      let family = this.jobFamily.find((x) => x.id === id);
      this.skills = this.contextService.profileData.skills.filter(
        (x) => x.family === family?.displayName
      );
    });
    this.skills.sort((a: any, b: any) => {
      if (a.displayName > b.displayName) {
        return 1;
      }
      if (a.displayName < b.displayName) {
        return -1;
      }
      return 0;
    });
    this.selectedSkills = this.skills.filter((x) =>
      this.selectedProProfile.skills.includes(x.id)
    );
    this.devider = [];
    let chunkSize = 10;
    if (this.selectedSkills.length > 10) {
      chunkSize = Math.ceil(this.selectedSkills.length / 3);
    }
    for (let i = 0; i < this.selectedSkills.length; i += chunkSize) {
      const chunk = this.selectedSkills.slice(i, i + chunkSize);
      this.devider.push(chunk);
    }
  }

  ngOnInit(): void {}

  validateChoices(type: string) {
    this.contextService.validSelection[type] = false;
    if (
      this.contextService.selectedProProfile[type as keyof SelectedPro]
        .length >= this.ruleLength[type]
    ) {
      this.contextService.validSelection[type] = true;
    }
  }

  selectedPro = (id: number, type: keyof type, single?: boolean): void => {
    if (single) {
      this.selectedProProfile[type] = [];
    }
    let findex = this.selectedProProfile[type].findIndex((x) => x === id);
    if (findex === -1) {
      this.selectedProProfile[type].push(id);
    } else {
      this.selectedProProfile[type].splice(findex, 1);
    }
    this.selectedProProfile[type] = this.selectedProProfile[type].filter((x) =>
      this.selectedSkills.some((z) => z.id === x)
    );

    this.validateChoices(type);
    this.platformService.reRender.next('top-skills');
  };
}
