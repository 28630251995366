import { Injectable } from '@angular/core';
import axios from 'axios';
import { env } from 'src/ts/interfaces/env.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;
  constructor() {}
  async getAxios(url: string) {
    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async postAxios(url: string, payload: any) {
    return axios
      .post(url, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async getCommunity(type: string, accessToken?: string) {
    const url = this.apiUrl + '/community/v1/' + type;

    if (accessToken) {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }
  async getSkills(type: string, accessToken?: string) {
    const url = this.apiUrl + '/skills/v1/' + type;

    if (accessToken) {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }
  async getIndustries(type: string, accessToken?: string) {
    const url = this.apiUrl + '/industries/v1/' + type;
    if (accessToken) {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }
  async getAssessment(type: string, accessToken?: string) {
    const url = this.apiUrl + '/assessments/v1/' + type;
    if (accessToken) {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }
  async getGenome(type: string, accessToken?: string) {
    const url = this.apiUrl + '/genome/v1/' + type;
    if (accessToken) {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  }

  async saveSkills(type: string, selected: any, accessToken?: string) {
    const url = this.apiUrl + '/skills/v1/' + type;
    return axios
      .post(url, selected, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        return err;
      });
  }

  async saveGenome(type: string, selected: any, accessToken?: string) {
    const url = this.apiUrl + '/genome/v1/' + type;
    return axios
      .post(url, selected, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  async saveIndustries(type: string, selected: any, accessToken?: string) {
    const url = this.apiUrl + '/industries/v1/' + type;
    return axios
      .post(url, selected, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
